import React, { useContext, useEffect, useCallback, useRef } from "react";
import * as nearAPI from 'near-api-js';
import { ChildrenProps } from "utils/interfaces";
import { EQueryKey, TTokenFormat, TUSDPriceToken } from "types/token";
import { getUsdtOfToken } from "utils/connect/oracle";
import useFunctionContract from "hooks/useFunctionContract";
import { useQueryClient } from "react-query";
import {
  landingViewWithoutGettingPrice,
  SUPPORTED_NFT,
  SUPPORTED_TOKENS,
  tokenFormat,
  ENV_ID_NETWORK,
  ENV_NODE_URL,
  ENV_WALLET_URL,
  ENV_HELPER_URL,
  ENV_EXPLORER_URL,
  ENV_ID_MAIN_CONTRACT
} from "utils/constant";
import { TPoolTokenList } from "utils/types";
import { AppContext } from "./AppContext";
import { useWalletSelector } from './WalletContext';
import { useLocation } from "react-router-dom";
import { ConfigProps } from "define/interface";

const { connect, WalletConnection, keyStores, Contract } = nearAPI;

const { GET_FORMAT_TOKEN, GET_TOKEN_PRICE_WITH_NAME } = EQueryKey;

const AppInit = ({ children }: ChildrenProps) => {
  const {
    setAuthParams,
    account,
    contract,
    setUserProfile,
    setContract,
    setWallet,
    setPoolTokenList,
    setPoolNftList,
  } = useContext(AppContext);
  const { accountId } = useWalletSelector();
  const { getAccount } = useFunctionContract();

  const intervalRef = useRef<any>();
  const { pathname } = useLocation();

  const queryClient = useQueryClient();
  const _setQueryData = useCallback(
    (queryKey: string, data: any) => queryClient.setQueryData(queryKey, data),
    [queryClient]
  );
  let rpc = null;
  try {
    rpc = localStorage.getItem('selected_rpc');
  } catch (e) {
  }
  const nearConfig: ConfigProps = {
    networkId: ENV_ID_NETWORK as unknown as string,
    nodeUrl: (rpc || ENV_NODE_URL) as unknown as string,
    walletUrl: ENV_WALLET_URL as unknown as string,
    helperUrl: ENV_HELPER_URL as unknown as string,
    explorerUrl: ENV_EXPLORER_URL as unknown as string,
    headers: '' || {},
  };

  const initConnect = useCallback(async () => {
    try {
      if (typeof window !== 'undefined') {
        const keyStore = new keyStores.BrowserLocalStorageKeyStore();

        const _near = await connect({
          ...nearConfig,
          keyStore: keyStore,
        });

        if (_near) {
          const wallet = new WalletConnection(_near, 'nearlend');
          const _account = await _near.account(accountId as string);
          const initContract = new Contract(_account, ENV_ID_MAIN_CONTRACT as unknown as string, {
            viewMethods: [
              "get_assets_paged",
              "get_assets_paged_detailed",
              "get_asset",
              "ft_metadata",
              "get_account",
              "get_accounts_paged",
              "nft_metadata",
              "get_assets_apr",
              "get_nft_assets_paged",
              "get_asset_farms_paged",
              "get_asset_farms_all",
            ],
            changeMethods: [
              "storage_deposit",
              "ft_transfer",
              "ft_transfer_call",
              "nft_transfer_call",
            ],
            useLocalViewExecution: true,
          })
          setContract(initContract);
          setWallet(wallet);
  
          if (accountId) {
            setAuthParams((prev: any) => ({
              ...prev,
              isLoggedIn: true,
              account: {
                ...prev.account,
                accountId,
              },
            }));
          } else {
            setAuthParams((prev: any) => ({
              ...prev,
              isLoggedIn: false,
            }));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [setAuthParams, setContract, setWallet]);

  const _initPool = useCallback(async () => {
    if (!contract || !contract.get_assets_paged_detailed) return;
    await contract
      ?.get_assets_paged_detailed({ from_index: 0, limit: 20 })
      .then((res: any) => {
        const format = res.map((item: TPoolTokenList) => {
          return {
            tokenId: item.token_id,
            ...item,
          };
        });
        setPoolTokenList(
          format.filter((item: TPoolTokenList) =>
            SUPPORTED_TOKENS.find((t) => t === item.token_id)
          )
        );

        setPoolNftList(
          format.filter((item: TPoolTokenList) =>
            SUPPORTED_NFT.find((t) => t === item.token_id)
          )
        );
      })
      .catch((err: any) => console.log(err));
  }, [contract, setPoolNftList, setPoolTokenList]);

  const _initApy = useCallback(async () => {
    if (!contract || !contract.get_assets_paged_detailed) return;
    await contract
      ?.get_assets_apr({ from_index: 0, limit: 20 })
      .then((res: any) => {
        _setQueryData("GET_TOKEN_APY", res);
      })
      .catch((err: any) => console.log(err));
  }, [_setQueryData, contract]);

  const _initAssetFarm = useCallback(async () => {
    if (!contract || !contract.get_asset_farms_paged) return;
    await contract
      ?.get_asset_farms_paged({ from_index: 0, limit: 20 })
      .then((res: any) => {
        // console.log("get_asset_farms_paged", res);
      })
      .catch((err: any) => console.log(err));
  }, [contract]);

  const initPrice = useCallback(async () => {
    const addPriceToTokenFormat = tokenFormat as TTokenFormat;
    const usdTokenPrice: TUSDPriceToken = await getUsdtOfToken();
    if (!usdTokenPrice) return;
    const newTokenFormat: TTokenFormat = Object.entries(usdTokenPrice)?.reduce(
      (_, curr) => {
        const tokenName = curr[0];
        for (const key in addPriceToTokenFormat) {
          const { nameUsd } = addPriceToTokenFormat[key];
          if (tokenName === nameUsd)
            addPriceToTokenFormat[key].usd = usdTokenPrice[tokenName].usd;
        }
        return addPriceToTokenFormat;
      },
      {}
    );

    _setQueryData(GET_TOKEN_PRICE_WITH_NAME, usdTokenPrice);
    _setQueryData(GET_FORMAT_TOKEN, newTokenFormat);
    _initApy();
  }, [_setQueryData, _initApy]);

  const initGetUSDPrice = useCallback(async () => {
    intervalRef.current = setInterval(initPrice, 60000);
  }, [initPrice]);

  const _getUserBalance = useCallback(async () => {
    try {
      const user = await getAccount();

      if (!user) return;

      const {
        account_id,
        borrowed,
        has_non_farmed_assets,
        nft_supplied,
        supplied,
      } = user || {};

      const farmSupplyAssets = user.farms
        .filter((item) => item.farm_id.Supplied)
        .map((item) => ({ ...item, farm_id: item.farm_id.Supplied }));

      const farmBorrowAssets = user.farms
        .filter((item) => item.farm_id.Borrowed)
        .map((item) => ({ ...item, farm_id: item.farm_id.Borrowed }));

      setUserProfile((prev) => ({
        ...prev,
        profile: {
          ...prev.profile,
          userBalance: {
            account_id,
            borrowed,
            has_non_farmed_assets,
            nft_supplied,
            supplied,
          },
          userAssetFarms: {
            ...prev.profile.userAssetFarms,
            supplied: farmSupplyAssets,
            borrowed: farmBorrowAssets,
          },
        },
      }));
    } catch (error) {
      console.log(error);
    }
  }, [account, contract, setUserProfile, accountId]);

  useEffect(() => {
    initConnect();
  }, [initConnect]);

  useEffect(() => {
    _initAssetFarm();
  }, [_initAssetFarm]);

  useEffect(() => {
    _getUserBalance();
  }, [_getUserBalance, accountId]);

  useEffect(() => {
    if (contract?.account?.accountId) {
      setAuthParams((prev: any) => ({
        ...prev,
        isLoggedIn: true,
        account: {
          ...prev.account,
          accountName: contract?.account.accountId,
        },
      }));
      return;
    }
    setAuthParams((prev: any) => ({
      ...prev,
      isLoggedIn: false,
      account: {
        ...prev.account,
        accountName: "",
      },
    }));
  }, [contract?.account?.accountId, setAuthParams]);

  useEffect(() => {
    if (!landingViewWithoutGettingPrice.includes(pathname)) {
      initGetUSDPrice();
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [initGetUSDPrice, initPrice, pathname]);

  useEffect(() => {
    _initPool();
  }, [_initPool]);

  useEffect(() => {
    _initApy();
  }, [_initApy]);

  useEffect(() => {
    initPrice();
  }, [initPrice]);

  return <>{children}</>;
};

export default AppInit;
