import { formatBalanceWithDecimal, formatCashToView } from "utils/common";
import nel_reward from "images/new_nel.png";
import useCurrentToken from "hooks/useCurrentToken";
import { ENV_ID_TOKEN_NEL } from "utils/constant";
import Big from "big.js";

type TBalanceTokenItem = {
  tokenIcon: string;
  tokenSymbol: string;
  tokenDecimals: number;
  tokenUsd?: number;
  apy: any;
  balance: number;
  suppliedBalance?: string;
  borrowedBalance?: string;
  actions: { func: () => void; name: string }[];
  reward?: number;
};

export default function BalanceTokenItem({
  tokenIcon,
  tokenSymbol,
  tokenDecimals,
  tokenUsd,
  apy,
  balance,
  actions,
  reward,
}: TBalanceTokenItem) {
  const { tokenUsdPrice } = useCurrentToken({
    token_id: ENV_ID_TOKEN_NEL,
  });

  const balance_format =
    Number(
      formatBalanceWithDecimal(balance?.toString() || "0", tokenDecimals)
    ) || 0;

  const balance_usd = Big(balance_format).mul(Big(tokenUsd)).toNumber();

  return (
    <div className="label label__token">
      <div className="label__token-mini token__logo">
        <p className={`img ${(tokenSymbol == "USDT.e" ? 'no-bg' : '')}`}>
          <img
            className="icon"
            src={tokenIcon}
            width={48}
            height={48}
            alt={tokenIcon}
          />
        </p>
        <div className="token__price">
          <p className="token_name color-space-gray">{tokenSymbol}</p>
          <p className="color-whitey">${formatCashToView(tokenUsd, 8)}</p>
        </div>
      </div>
      <div className="token__price label__token-mini">
        <p className="token_name fwb">{ (balance_usd < 1) ? '< $1' : (balance_format < 0.001 ? balance_format.toFixed(4) : formatCashToView(balance_format, 8, true)) }</p>
        <p className="color-whitey">${formatCashToView(balance_usd, 8)}</p>
      </div>

      <div className="label__token-mini">
        <p className="color-whitey fwb">{apy}%</p>
      </div>
      {/* <div className="label__token-mini">
        <p className="color-whitey fwb">...</p>
      </div> */}
      {actions.map((action: any) => (
        <div
          key={action.name}
          className="label__token-mini label__token-mini__actions"
        >
          <button
            onClick={action.func}
            className={`button-basic button-basic-deposit button-basic-${
              action.name === "Withdraw" ? "borrow" : ""
            }`}
          >
            {action.name}
          </button>
        </div>
      ))}
    </div>
  );
}
