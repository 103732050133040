import { IToken } from "types/token";
import TokenItem from "components/marketApp/marketToken/TokenItem";
import { ENV_ID_TOKEN_NEL, SUPPORTED_TOKENS_ORDER } from "utils/constant";

function TokenList({
  tokenList,
  actions: {
    _openPopupDeposit,
    _openPopupBorrow,
    _openPopupBorrowStable,
    _handleTogglePopupRequire,
  },
}: any) {
  const filtered =
    tokenList && tokenList.filter((item) => item.token_id !== ENV_ID_TOKEN_NEL);
  let sorted = [];
  if (filtered) {
    for (let i = 0; i < filtered.length; i++) {
      sorted[SUPPORTED_TOKENS_ORDER[filtered[i].tokenId]] = filtered[i];
    }
  }
  return (
    <>
      {sorted?.length > 0 &&
        sorted.map((item: IToken, idx: number) => {
          if (!item) return null;
          return (
            <TokenItem
              key={item.tokenId}
              item={item}
              actions={{
                openPopupDeposit: _openPopupDeposit,
                openPopupBorrow: _openPopupBorrow,
                openPopupBorrowStable: _openPopupBorrowStable,
                handleTogglePopupRequire: _handleTogglePopupRequire,
              }}
            />
          );
        })}
    </>
  );
}

export default TokenList;
