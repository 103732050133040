import React, { useState, useEffect } from "react";
import { Icos, WrappSvg } from "components/footer";
import reportbug_img from "images/reportbug-btn.png";
import testnet_img from "images/testnet.png";
import beta_img from "images/betanet.png";
import feedback_img from "images/feedback-btn.png";
import { useLocation } from "react-router-dom";
import { Tooltip } from "antd";
import { rpcs, RPC } from "utils/constant";

const SocialFooter = () => {
  const location = useLocation();
  const randomId = Math.random();
  const key = 'selected_rpc';
  const [selectedRpc, setSelectedRPC] = useState<RPC>(rpcs[0]);
  function changeRpc (rpc: string) {
    if (rpc == localStorage.getItem(key)) {
      return
    }
    localStorage.setItem(key, rpc)
    window.location.reload();
  }

  useEffect(() => {
    if (localStorage && localStorage.getItem(key)) {
      for (let i = 0; i < rpcs.length; i++) {
        if (rpcs[i].url == localStorage.getItem(key)) {
          setSelectedRPC(rpcs[i])
          break
        }
      }
    } else {
      setSelectedRPC(rpcs[0])
    }
  }, [localStorage]);
  return (
    <div className="icons-bottom">
      {location.pathname !== "/" ? (
        <>
          <div className="test-net-button">
            {/*<a
              target={"_blank"}
              href="https://forms.gle/3NgqLe4to88xcY4h7"
              rel="noreferrer"
            >
              <img
                alt="Feedback to Nearlend DAO"
                src={feedback_img}
                width={133}
                height={40}
              />
            </a>*/}
            <a
              href="#"
            >
              <Tooltip
                zIndex={109999}
                arrowPointAtCenter
                color="#0000006b"
                title="We are in Beta Mode. It means that platform is live and we are constantly fixing minor bugs, improving the performance and adding new features. We really appreciate your support and feedback. Thank you!"
              >
                <img
                  alt="Beta"
                  src={beta_img}
                  width={133}
                  height={40}
                />
              </Tooltip>
            </a>
            <a
              target={"_blank"}
              href="https://docs.google.com/forms/d/e/1FAIpQLSe0FXd1GHjEaP0-KkIrGE5iVYvIhS9itprCiyHzuks1Nwx1GQ/viewform"
              rel="noreferrer"
            >
              <img
                alt="Report bug to Nearlend DAO"
                src={reportbug_img}
                width={133}
                height={40}
              />
            </a>
          </div>
          <div className="test-net-button" style={{left: "20px"}}>
            <div className="lst-rpcs">
              <div className="rpcs">
                <div className="extend">
                  {rpcs.map((rpc, index) => (
                    <div key={`rpc-${index}`} className={`${rpc.name == selectedRpc.name ? 'active' : ''}`} onClick={() => changeRpc(rpc.url)}>
                      <label>{rpc.name}</label>
                      <div>
                        <span>
                          <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="2.5" cy="2.5" r="2.5" fill="#25ac2f"></circle>
                          </svg>
                        </span>
                        <label>{rpc.network}ms</label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="selected-rpc">
                <div>
                  <label>{selectedRpc.name}</label>
                </div>
                <div>
                  <span>
                    <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="2.5" cy="2.5" r="2.5" fill="#25ac2f"></circle>
                    </svg>
                  </span>
                  <label>{selectedRpc.network}ms</label>
                  <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </>
        
        
      ) : null}
      <div className="icons-wrapper">
        {Icos.map((item, idx) => (
          <WrappSvg
            key={idx}
            href={item.path}
            target="_blank"
            rel="noreferrer"
            className="icon-item"
          >
            {item.srcSocial ? 
            <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.03125 8.5625C1.03125 4.4894 4.33315 1.1875 8.40625 1.1875C12.4794 1.1875 15.7812 4.4894 15.7812 8.5625V20.75C15.7812 20.7845 15.7533 20.8125 15.7188 20.8125H1.09375C1.05923 20.8125 1.03125 20.7845 1.03125 20.75V8.5625Z" fill={`url(#gar${randomId})`} stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
            <defs>
            <linearGradient id={`gar${randomId}`} x1="0.28125" y1="0.4375" x2="5.54191" y2="20.2707" gradientUnits="userSpaceOnUse">
            <stop offset="0.226006" stop-color="white"/>
            <stop offset="1" stop-color="white" stop-opacity="0"/>
            </linearGradient>
            </defs>
            </svg>
            
             : item.src}
          </WrappSvg>
        ))}
      </div>
    </div>
  );
};

export default SocialFooter;
