import { Table, Tabs, Tooltip } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import type { PaginationProps } from "antd";
import { useQueryClient } from "react-query";
import dayjs from "dayjs";
import { AppContext } from "Contexts/AppContext";

import {
  get_nft_assets_paged,
  get_num_nfts,
  handleGetMetadataNft,
} from "utils/connect/contract";
import {
  ENV_ID_TOKEN_wNEAR,
  LLL,
  QUERY_KEY,
  SUPPORTED_NFT,
  ENV_ID_TOKEN_NEL,
  ENV_ID_NFT_LANGBIANG

} from "utils/constant";
import { TTokenFormat } from "utils/types";

import { Container } from "components/common/Container";
import { RelativeWrapper } from "components/homepage/styles";
import SocialFooter from "components/common/SocialFooter";
import TopBox from "./TopBox";
import TabsHead from "./TabsHead";
import NearPagination from "components/common/Pagination";
import { CollectionComponent } from "components/vaults/common";

import styled from "styled-components";
import "./style.scss";
import useCurrentToken from "hooks/useCurrentToken";
import { formatAccountName, formatCashToView } from "utils/common";
import { get_asset_farms_all } from "utils/connect/contract";
import Image from "components/common/Images";
import logo from "images/new_nel.png";

import Big from "big.js";
const { TabPane } = Tabs;

const TabWrapper = styled.div`
  margin-bottom: 35px;
`;

type TArrayReward = { [key: string]: number }[];
function MyVault() {
  const queryClient = useQueryClient();

  const { poolNftList, contract } = useContext(AppContext);
  const { tokenUsdPrice } = useCurrentToken({
    token_id: ENV_ID_TOKEN_wNEAR,
  });

  const [data, setData] = useState<any>([]);
  const [currentTabId, setCurrentTabId] = useState(SUPPORTED_NFT[0]);
  const [paginSetting, setPaginSetting] = useState({
    total: 0,
    current: 1,
    from_index: 0,
  });

  const _getCurrentNftsVault = useCallback(
    async (currentPoolNft, total) => {
      await _initPoolTotal();
      const found =
        poolNftList &&
        poolNftList.find((item) => item.token_id === currentTabId);

      if (!found || !currentPoolNft) return;
      const ratio = found.config.volatility_ratio;
      const rs = await get_asset_farms_all(contract);

      const reward_nft = rs?.filter(
        (item) => item[0].SuppliedNFT === ENV_ID_NFT_LANGBIANG
      );

      let total_rewards = 0;
      if (reward_nft[0] && reward_nft[0][1] && reward_nft[0][1].rewards) {
        const reward = reward_nft[0][1].rewards[ENV_ID_TOKEN_NEL];

        if (reward) {
          total_rewards = reward.reward_per_day;
        }
      }
      const collects: any = await Promise.all(
        currentPoolNft?.map(async (nft_item: any) => {
          const nft_token = await handleGetMetadataNft(
            contract,
            found.token_id,
            nft_item.token_id
          );

          const getNewFormatToken = queryClient.getQueryData(
            QUERY_KEY.GET_FORMAT_TOKEN
          ) as unknown as TTokenFormat;

          const totalAfterDiv = Big(total_rewards)
          .div(
            Big(10).pow(
              getNewFormatToken[ENV_ID_TOKEN_NEL].contract_decimals +
              getNewFormatToken[ENV_ID_TOKEN_NEL].extra_decimals
            )
          )
          .toNumber();

          const price_floor = getNewFormatToken[found.token_id].usd;
          let timestamp = nft_item?.deposit_timestamp;
          const timestamp_converted = dayjs(timestamp / 10 ** 6);
          let deposit_timestamp = `${timestamp_converted.format(LLL)}`;

          const reward = totalAfterDiv / (paginSetting.total || total);

          return {
            metadata: {
              title: nft_token?.metadata?.title,
              health_stt: "NaN",
              id: nft_token?.token_id,
              nft_url: nft_token?.metadata.media,
              pair_value: { first: "47.32 NEAR", second: "$84,635.13" },
            },
            last_trade: {
              price_floor,
              price_floor_near: Number(price_floor) / tokenUsdPrice,
            },
            pair_credit: nft_item?.pair_credit,
            deposit_timestamp: deposit_timestamp,
            ltv: nft_item?.ltv,
            owner_id: nft_item?.owner_id,
            action: nft_item,
            key: nft_item?.token_id,
            reward_per_day: reward
          };
        }) || []
      );
      console.log({ collects });

      collects && setData(collects);
    },
    [contract, currentTabId, poolNftList, queryClient, tokenUsdPrice]
  );

  const _initPoolByPage = useCallback(async () => {
    const found =
      poolNftList &&
      poolNftList.find((item) => item?.token_id === currentTabId);
    if (!contract && !found) return;
    return await get_nft_assets_paged(contract, paginSetting.from_index)
      .then((res: any) => {
        if (!res) return;
        _getCurrentNftsVault(res, paginSetting.total);
      })
      .catch((err: any) => console.log(err));
  }, [
    _getCurrentNftsVault,
    contract,
    currentTabId,
    paginSetting.total,
    paginSetting.from_index,
    poolNftList,
  ]);

  const _initPoolTotal = useCallback(async () => {
    if (!contract) return;
    return await get_num_nfts(contract)
      .then((res: any) => {
        setPaginSetting((prev) => ({ ...prev, total: res }));
      })
      .catch((err: any) => console.log(err));
  }, [contract]);

  const handleTabsOnchange = (key: string) => {
    setCurrentTabId(key);
  };

  const handlePaginOnchange: PaginationProps["onChange"] = (
    page,
    size = 10
  ) => {
    const index = page === 1 ? 0 : (page - 1) * size;
    setPaginSetting((prev) => ({ ...prev, current: page, from_index: index }));
  };

  useEffect(() => {
    _initPoolByPage();
  }, [_initPoolByPage, paginSetting.from_index]);

  useEffect(() => {
    _initPoolTotal();
  }, [_initPoolTotal]);

  return (
    <RelativeWrapper className="vaults-comp bg-main">
      <TopBox />
      <Container>
        <TabWrapper>
          <Tabs defaultActiveKey="1" onChange={handleTabsOnchange}>
            {SUPPORTED_NFT.map((contract_name) => (
              <TabPane tab={"Lang Biang"} key={contract_name}>
                <TabsHead contractNftId={currentTabId} />
              </TabPane>
            ))}
          </Tabs>
        </TabWrapper>

        <Table
          pagination={false}
          dataSource={data}
          columns={mockColumn("Lang Biang")}
          rowClassName={(_, index) => (index % 2 === 0 ? `odd` : `even`)}
          scroll={{ x: 350 }}
          rowKey={(row: any) => {
            return row?.key;
          }}
        />
        <NearPagination
          current={paginSetting?.current}
          total={paginSetting?.total}
          paginOnchange={handlePaginOnchange}
        />
      </Container>
      <Container style={{ marginTop: 100 }}>
        <SocialFooter />
      </Container>
    </RelativeWrapper>
  );
}

export default MyVault;

const mockColumn = (type = "", accountName = "") => [
  {
    title: "Collection",
    dataIndex: "metadata",
    key: "metadata",
    render: (item: any) => {
      return (
        <CollectionComponent
          imgUrl={item?.nft_url}
          label={item?.title || type}
          id={item?.id}
        />
      );
    },
  },
  {
    title: "Last Trade",
    dataIndex: "last_trade",
    key: "last_trade",
    render: (item: any) => (
      <div>
        <div className="main-value">
          {formatCashToView(item.price_floor_near, 8)} NEAR
        </div>
        <div className="sub-value">
          ${formatCashToView(item.price_floor, 8)}
        </div>
      </div>
    ),
  },
  // {
  //   title: "Rewards",
  //   dataIndex: "reward_per_day",
  //   key: "reward_per_day",
  //   render: (item: any) => (
  //     <div className="vault-rewards-per-day">
  //       <div className="flex-reward">
  //         <Image
  //           style={{
  //             width: 30,
  //             height: 30,
  //             justifyContent: "center",
  //             alignItems: "center",
  //             padding: 3,
  //             borderRadius: "100%",
  //           }}
  //           src={logo}
  //         />
  //         <div className="wrap-reward">
  //           <div className="reward">
  //               <div className="reward-name">
  //                 <img
  //                   className="icon"
  //                   src={logo}
  //                   width={24}
  //                   height={24}
  //                   alt="Logo"
  //                 />
  //                 <p>NELD</p>
  //               </div>
  //               <div className="reward-per-day">
  //                 <p>{item} / day</p>
  //               </div>
  //             </div>
  //         </div>
  //       </div>
  //     </div>
  //   ),
  // },
  {
    title: "Deposited Time",
    dataIndex: "deposit_timestamp",
    key: "deposit_timestamp",
    render: (item: any) => <div className="main-value">{item}</div>,
  },
  {
    title: "Owner",
    dataIndex: "owner_id",
    key: "owner_id",
    render: (item: any) => (
      <div className="main-value font-weight-600">
        <Tooltip title={item}>{formatAccountName(item)}</Tooltip>
      </div>
    ),
  },
];
