import "components/liquidity/style.scss";
import { Slider } from "antd";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppContext } from "Contexts/AppContext";
import useCurrentToken from "hooks/useCurrentToken";
import { formatCashToView } from "utils/common";
import { ENV_ID_TOKEN_NEL } from "utils/constant";

export const Rewards = () => {
  // reward_per_day_account = (asset_farm_reward.reward_per_day / asset_farm_reward.boosted_shares) * boosted_shares
  // available_rewards = unclaimed_amount
  // locked_rewards = (asset_farm_reward.remaining_rewards  / asset_farm_reward.reward_per_day) *  reward_per_day_account
  // total_rewards = locked_rewards + available_rewards

  const {
    profile: {
      userAssetFarms: { supplied, borrowed },
    },
  } = useContext(AppContext);

  const { tokenUsdPrice, tokenExtraDecimals, tokenContractDecimals } =
    useCurrentToken({
      token_id: ENV_ID_TOKEN_NEL,
    });

  const [data, setData] = useState({
    total: 0,
    available: 0,
    locked: 0,
  });
  const [percent, setPercent] = useState(0);

  const _init = useCallback(() => {
    const all_supplied = [...supplied, ...borrowed];

    // console.log("all_supplied", all_supplied);
    const nel_rewards = all_supplied
      .map((item) => {
        return [...item.rewards];
      })
      .flat()
      .filter((reward) => reward.reward_token_id === ENV_ID_TOKEN_NEL);

    const pow_decimals = Math.pow(
      10,
      tokenExtraDecimals + tokenContractDecimals
    );

    const nel_unclaimed_amount =
      nel_rewards.reduce((acc, curr) => {
        return acc + Number(curr.unclaimed_amount);
      }, 0) / pow_decimals;

    const nel_boosted_shares =
      nel_rewards.reduce((acc, curr) => {
        return acc + Number(curr.boosted_shares);
      }, 0) / pow_decimals;

    const nel_remaining_rewards =
      nel_rewards.reduce((acc, curr) => {
        return acc + Number(curr.asset_farm_reward.remaining_rewards);
      }, 0) / pow_decimals;

    const nel_reward_per_day =
      nel_rewards.reduce((acc, curr) => {
        return acc + Number(curr.asset_farm_reward.reward_per_day);
      }, 0) / pow_decimals;

    const nel_asset_farm_boosted_shares =
      nel_rewards.reduce((acc, curr) => {
        return acc + Number(curr.asset_farm_reward.boosted_shares);
      }, 0) / pow_decimals;

    const reward_per_day_account =
      (nel_reward_per_day / nel_asset_farm_boosted_shares) * nel_boosted_shares;

    const locked_rewards =
      (nel_remaining_rewards / nel_reward_per_day) * reward_per_day_account;

    const total_rewards = locked_rewards + nel_unclaimed_amount;

    setData({
      total: total_rewards,
      available: nel_unclaimed_amount,
      locked: locked_rewards,
    });
  }, [borrowed, supplied, tokenContractDecimals, tokenExtraDecimals]);

  const _initPercent = useCallback(() => {
    if (!data.available || !data.total) return;
    let percent = (data.available / data.total) * 100;
    let checkPercent = percent <= 1 ? 1 : percent;
    setPercent(checkPercent);
  }, [data.available, data.total]);

  useEffect(() => {
    _init();
  }, [_init]);

  useEffect(() => {
    _initPercent();
  }, [_initPercent]);

  return (
    <Fragment>
      <div className="info-value info-value-top">
        <h3 className="info-value-title">$NELD Total:</h3>
        <div>
          <p className="info-value-amount">
            {formatCashToView(data.total, 8) || 0}
          </p>
          {/* <p className="info-value-amount dollar">
            ${formatCashToView(data.total * tokenUsdPrice, 8) || 0}
          </p> */}
        </div>
      </div>
      <div className="info-line">
        <div className="info-slider">
          <Slider
            defaultValue={percent}
            value={percent}
            dots={false}
            tooltipVisible={false}
            disabled={true}
          />
        </div>
      </div>
      <div className="info-detail">
        <div className="info-value">
          <h3 className="info-value-title">$NELD Available:</h3>
          <div>
            <p className="info-value-amount">
              {data.available * tokenUsdPrice < 1 ? '< $1' : (formatCashToView(data.available, 8) || 0)}
            </p>
            {/* <p className="info-value-amount dollar">
              ${formatCashToView(data.available * tokenUsdPrice, 8) || 0}
            </p> */}
          </div>
        </div>
        <div className="info-value info-value-right-section">
          <h3 className="info-value-title">$NELD Locked (10 days : 10h : 29 mins)</h3>
          <div>
            <p className="info-value-amount">
              {formatCashToView(data.locked, 8) || 0}
            </p>
            {/* <p className="info-value-amount dollar">
              ${formatCashToView(data.locked * tokenUsdPrice, 8) || 0}
            </p> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
