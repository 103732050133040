import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import Big from "big.js";
import { AppContext } from "Contexts/AppContext";
import { TTokenFormat } from "types/token";
import { countMinimumDollarOfTokenList, floorNumber } from "utils/common";
import { QUERY_KEY } from "utils/constant";

import BalanceDepositItem from "components/myBalance/balance-deposit/BalanceDepositItem";

export default function BalanceDeposit() {
  const initInterval = useRef<any>();
  const queryClient = useQueryClient();
  const { profile, poolTokenList, setIsLoading } = useContext(AppContext);

  const [isShowDepositList, setIsShowDepositList] = useState(true);
  const [data, setData] = useState([]);

  // const [paginSetting, setPaginSetting] = useState({
  //   total: 50,
  //   current: 1,
  // });

  // const handlePaginOnchange: PaginationProps["onChange"] = (page) => {
  //   setPaginSetting((prev) => ({ ...prev, current: page }));
  // };

  const handleShowDepositList = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      QUERY_KEY.GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;
    if (!getNewFormatToken) return;
  
    const lengthLowerMinimumDollar = countMinimumDollarOfTokenList(
      "collateral",
      profile?.userBalance?.supplied,
      getNewFormatToken,
      poolTokenList
    );
    setData([]);
    if (!profile?.userBalance?.supplied || profile?.userBalance?.supplied.length === lengthLowerMinimumDollar) {
      setIsShowDepositList(false);
    } else {
      const supplied = profile?.userBalance?.supplied;
      let data = [];
      for (let i = 0; i < supplied.length; i++) {
        let item = supplied[i];
        if (!getNewFormatToken[item.token_id]) {
          continue;
        }
        let balance = Big(item.balance)
          .div(
            Big(10).pow(
              getNewFormatToken[item.token_id].contract_decimals +
              getNewFormatToken[item.token_id].extra_decimals
            )
          )
          .toNumber();
        if (floorNumber(balance, item.token_id) > 0) {
          data[data.length] = item;
        }
      }
      setData(data);
      if (!data || !data.length) {
        setIsShowDepositList(false);
      } else {
        setIsShowDepositList(true);
      }
    }
    setIsLoading(false);
    clearInterval(initInterval.current);
  }, [poolTokenList, profile?.userBalance?.supplied, queryClient, queryClient.getQueryData(QUERY_KEY.GET_FORMAT_TOKEN)])

  useEffect(() => {
    setIsLoading(true);
    initInterval.current = setInterval(handleShowDepositList, 400);
    return () => {
      clearInterval(initInterval.current);
    };
  }, [handleShowDepositList]);

  return (
    <div className="deposit-tab" style={{ width: "49%" }}>
      <h3 className="big-title">
        <span style={{ color: "#49E454" }}>Deposited</span> assets
      </h3>
      <div className="token-list">
        <div className="side">
          <div className="detail">
            <div className="label label__title">
              <p className="title">Asset</p>
              {/* <p className="title">NELD Emission</p> */}
              <p className="title" style={{textAlign: "left"}}>Deposited</p>
              <p className="title" style={{textAlign: "center", width: "100px"}}>APY</p>
              {/* <p className="title">Deposited Interest</p> */}
              <p className="title" style={{textAlign: "center"}}>Actions</p>
            </div>
            {isShowDepositList ? (
              data?.map((item: any, idx: number) => {
                return (
                  item.token_id && floorNumber(item.balance, item.token_id) > 0 && (
                    <BalanceDepositItem key={idx} currentToken={item} />
                  )
                );
              })
            ) : (
              <div className="empty-account-line">
                <p>
                Not Available. Please head to <Link to="/app">Market</Link>!
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <NearPagination
    current={paginSetting?.current}
    total={paginSetting?.total}
    paginOnchange={handlePaginOnchange}
  /> */}
    </div>
  );
}
