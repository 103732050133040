'use client'
import { useState } from "react";
import iconClose from "images/icon-close.png";
type Props = {
  togglePopup: () => void;
  setShowPolicy: () => void
};

const Disclaimer = ({ togglePopup, setShowPolicy }: Props) => {
  const [isAgree, setIsAgree] = useState(false)
  const agree = () => {
    if (!isAgree) {
      return
    }
    localStorage.setItem("disclaimer", "shown")
    togglePopup()
  }
  const changeIsAgree = (e: any) => {
    setIsAgree(e.target.checked)
  }

  return (
    <>
      <div className="wrap-popup yellow">
        <div className="popup disclaimer">
          <div className="popup-header">
            <h4 className="title green" style={{textTransform: "inherit"}}>
              Welcome to Nearlend DAO
            </h4>
            <p className="icon-close" onClick={togglePopup}>
              <img alt="icon-close" src={iconClose} width={12} height={12} />
            </p>
          </div>
          <div className="content-disclaimer">
            <p>Nearlend DAO is a open-source Capital Market on Near Protocol.</p>

            <p>This website is a community deployed and maintained instance. By clicking Agree you accept the <a href="#" onClick={setShowPolicy}>Terms of use</a>.</p>
            <div className="flex items-center mb-4">
                <input id="default-checkbox" type="checkbox" value="" onChange={changeIsAgree} className="text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label className="ms-2 font-bold">I confirm that I have read and agree to this message. Do not show again for 30 days.</label>
            </div>
          </div>
          <div className="bg-white position-relative wrap-white">
            <button className="position-relative btn" onClick={agree} disabled={!isAgree}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Disclaimer;
