import { useCallback, useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import "./style.scss";
import TokenList from "./leaderboardToken/TokenList";
import SocialFooter from "components/common/SocialFooter";
import { formatCashToView, formatAccountName, strimInt, getTotalBalanceTokenPriceUSD } from "utils/common";
import { AppContext } from "Contexts/AppContext";
import { useWalletSelector } from 'Contexts/WalletContext';
import { useQueryClient } from "react-query";
import { QUERY_KEY } from "utils/constant";
import { TTokenFormat } from "types/token";

function Leaderboard() {
  const [deposited, setDeposited] = useState(0);
  const [ranks, setRanks] = useState([])
  const [myRank, setMyRank] = useState<any>(null)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isGettingMyRank, setGotMyRank] = useState(false)
  const { isLogin, profile, poolTokenList } = useContext(AppContext);
  const { accountId } = useWalletSelector() || {};
  const intervalRef = useRef<any>();
  const pageSize = 10;
  const queryClient = useQueryClient();

  const getRanks = () => {
    axios.get(`${process.env.REACT_APP_API_LEADERBOARD}/points/`, {params: {page, pageSize}}).then((rs) => {
      if (rs && rs.data && rs.data.data) {
        setRanks(rs.data.data)
        setTotalPage(Math.ceil(rs.data.pagination.total / pageSize))
      }
    }).catch((e) => {
      console.log('Error', e)
    })
  }

  const getMyRank = (accountId) => {
    axios.get(`${process.env.REACT_APP_API_LEADERBOARD}/points/my-stats/${accountId}`).then((rs) => {
      if (rs && rs.data) {
        let rank = rs.data
        let ti = Math.floor(rank.rank / 100)
        if (rank.rank % 100) {
          ti += 1
        }
        rank.tier = ti
        setMyRank(rank)
      }
    }).catch((e) => {
    })
  }

  const getData = () => {
    setIsProcessing(false)
    if (accountId) {
      setGotMyRank(false)
    }
  }

  const pageShow = () => {
    if (!ranks || !ranks.length) {
      return []
    }
    if (totalPage <= 7) {
      const pages = []
      for (let i = 0; i < totalPage; i++) {
        pages[pages.length] = i + 1;
      }
      return pages;
    }
    const pages = []
    if (page < 3) {
      for (let i = 1; i <= 3; i++) {
        pages[pages.length] = i
      }
      pages[pages.length] = '...'
      for (let i = (totalPage - 2); i <= totalPage; i++) {
        pages[pages.length] = i;
      }
    } else if (page < (totalPage / 2)) {
      for (let i = (page - 1); i <= (page + 1); i++) {
        pages[pages.length] = i
      }
      pages[pages.length] = '...'
      for (let i = (totalPage - 2); i <= totalPage; i++) {
        pages[pages.length] = i;
      }
    } else if (page < totalPage) {
      for (let i = 1; i <= 3; i++) {
        pages[pages.length] = i
      }
      pages[pages.length] = '...'
      for (let i = (page - 1); i <= (totalPage + 1); i++) {
        pages[pages.length] = i
      }
    } else {
      for (let i = 1; i <= 3; i++) {
        pages[pages.length] = i
      }
      pages[pages.length] = '...'
      for (let i = (totalPage - 2); i <= totalPage; i++) {
        pages[pages.length] = i;
      }
    }
    return pages
  }

  const goToPage = (pageGo: number) => {
    if (pageGo == page) {
      return
    }
    setRanks([])
    setPage(pageGo)
    setIsProcessing(false)
  }

  const goToPrev = () => {
    if (page > 1 && page <= totalPage) {
      goToPage(page - 1)
    }
  }

  const goToNext = () => {
    if (page > 0 && page < totalPage) {
      goToPage(page + 1)
    }
  }

  const _getDeposited = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      QUERY_KEY.GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;
    const { supplied } = profile?.userBalance || {
      supplied: 0,
      borrowed: 0,
    };
    const collateral_to_usd = getTotalBalanceTokenPriceUSD(
      "collateral",
      supplied,
      getNewFormatToken,
      poolTokenList,
      false
    );
    setDeposited(collateral_to_usd);
  }, [
    queryClient,
    profile
  ])

  useEffect(() => {
    if (!isProcessing) {
      setIsProcessing(true)
      getRanks()
      // intervalRef.current = setInterval(() => {
      //   getData()
      // }, 10000);
    }
  }, [isProcessing, page])

  useEffect(() => {
    if (!isGettingMyRank && accountId) {
      setGotMyRank(true)
      getMyRank(accountId)
    }
  }, [isGettingMyRank, accountId])

  useEffect(() => {
    _getDeposited();
  }, [_getDeposited]);
  return (
    <div className="leaderboard-container">
      <div className="title">
        <div>
          <span>INTRODUCING</span>
          <span>NELD SHARDS</span>
        </div>
        <div>
        NELD Shards represent the protocol's first steps towards decentralizing protocol decision making, and serve as a new incentive mechanism to reward the community.
        </div>
      </div>
      <div className="content">
        <div className="sub-title">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9072_7954)">
            <path d="M15.2433 28.0351H3V38.9851H15.2433V28.0351Z" fill="white"/>
            <path d="M41 32.681H28.7567V39H41V32.681Z" fill="white"/>
            <path d="M28.1293 21.0738H15.886V39H28.1293V21.0738Z" fill="white"/>
            <path d="M22.0077 5L24.2267 9.39192L29.2006 10.094L25.6041 13.5299L26.4458 18.355L22.0077 16.0694L17.5542 18.355L18.4112 13.5299L14.8147 10.094L19.7733 9.39192L22.0077 5Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_9072_7954">
            <rect width="40" height="40" fill="white"/>
            </clipPath>
            </defs>
          </svg>

          <span>Leaderboard</span>
        </div>
        <div className="container">
          <div className="ranking">
            <TokenList ranks={ranks} page={page} pageSize={pageSize} />
            {/* <div className="pagination">
              <div className={page > 1 ? '' : 'disable'} onClick={goToPrev}>&laquo;</div>
              {pageShow().map((pageItem: string|number) => (
                (typeof pageItem == 'string') ? <div className="dot">{pageItem}</div> : <div className={page == pageItem ? 'active' : ''} onClick={() => goToPage(pageItem)}>{pageItem}</div>
              ))}
              <div className={page < totalPage ? '' : 'disable'} onClick={goToNext}>&raquo;</div>
            </div> */}
          </div>
          <div className="my-rank">
            <div className="title">
              <div className="top">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.0002 18.9749C18.6257 18.9749 17.4485 18.4849 16.4685 17.5049C15.4885 16.5249 14.9991 15.3483 15.0002 13.9749C15.0013 12.6016 15.4907 11.4238 16.4685 10.4416C17.4463 9.45939 18.6235 8.97105 20.0002 8.97661C21.3768 8.98216 22.5541 9.47105 23.5318 10.4433C24.5096 11.4155 24.9991 12.5933 25.0002 13.9766C25.0013 15.3599 24.5118 16.5366 23.5318 17.5066C22.5518 18.4766 21.3746 18.9649 20.0002 18.9749ZM8.3335 31.0266V28.2833C8.3335 27.5955 8.5335 26.9522 8.9335 26.3533C9.3335 25.7544 9.87239 25.2888 10.5502 24.9566C12.1235 24.2022 13.6974 23.6366 15.2718 23.2599C16.8463 22.8833 18.4224 22.6944 20.0002 22.6933C21.5779 22.6922 23.1541 22.8811 24.7285 23.2599C26.3029 23.6388 27.8763 24.2044 29.4485 24.9566C30.1263 25.2877 30.6652 25.7533 31.0652 26.3533C31.4652 26.9533 31.6657 27.5961 31.6668 28.2816V31.0249L8.3335 31.0266Z" fill="white"/>
                </svg>
                <span>My stats</span>
              </div>
              <div className="bottom">{isLogin ? <a href={`${process.env.REACT_APP_EXPLORER_URL}/address/${accountId}`} target="_blank">{formatAccountName(accountId)}</a> : 'Not available'}</div>
            </div>
            <div className="rank">
              <button>
                <span>{isLogin ? strimInt(formatCashToView(myRank?.rewardPoints, 8, true).toString()) || 0 : 'NaN'}</span>
                <span>Total shards</span>
              </button>
              <button>
                <span>{isLogin && myRank?.rank ? myRank?.rank : 'NaN'}</span>
                <span>Rank</span>
              </button>
            </div>
            <div className="rank">
              <button>
                <span>{isLogin ? `Tier ${myRank?.tier}` : 'NaN'}</span>
                <span>Tier</span>
              </button>
              <button>
                <span>{isLogin ? formatCashToView(deposited as unknown as number, 8) : 'NaN'}</span>
                <span>Deposited Value</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <SocialFooter />
    </div>
  )
}

export default Leaderboard