import * as nearAPI from "near-api-js";
import {
  tokenFormat,
  SUPPORTED_NFT,
  SUPPORTED_TOKENS,
  ENV_ID_ORACLE_CONTRACT,
  ENV_NODE_URL,
  ENV_WALLET_URL,
  ENV_HELPER_URL,
  ENV_ID_NETWORK,
} from "utils/constant";
const { connect, WalletConnection, keyStores } = nearAPI;
let keyStore: any = new keyStores.BrowserLocalStorageKeyStore();
const nearConfig = {
  networkId: ENV_ID_NETWORK,
  nodeUrl: ENV_NODE_URL,
  ENV_ID_ORACLE_CONTRACT,
  walletUrl: ENV_WALLET_URL,
  helperUrl: ENV_HELPER_URL,
  headers: {
    "Content-Type": "application/json",
  },
};
export const _price = async function () {
  return await connect({
    ...nearConfig,
    keyStore
  });
};

export const _priceConnection = function (_price: any) {
  return new WalletConnection(_price, 'price-oracle');
};

export const _contractPrice = function (wallet: any) {
  return new nearAPI.Contract(wallet.account(), ENV_ID_ORACLE_CONTRACT, {
    viewMethods: ["get_price_data"],
    changeMethods: ["oracle_call"],
    useLocalViewExecution: true
  });
};

export const getUsdtOfToken = async function () {
  try {
    const initPrice = await _price();
    const initPriceConnect = _priceConnection(initPrice);
    const initPriceContract: any = _contractPrice(initPriceConnect);
    let asset_ids = [...SUPPORTED_NFT, ...SUPPORTED_TOKENS].map(function (key) {
      return key || "";
    });

    let prices = await initPriceContract.get_price_data({
      asset_ids: asset_ids,
    });
    // console.log("prices=====", prices);
    prices = prices.prices;
    let results: any = {};
    for (let i = 0; i < prices.length; i++) {
      let element = prices[i];
      let token = tokenFormat[element.asset_id];
      let exponent = (element.price ? element.price.decimals : token?.contract_decimals) - token?.contract_decimals;
      let price = (element.price && exponent)
        ? element.price.multiplier / Math.pow(10, exponent)
        : 1;
      results[token?.name] = { usd: price };
    }
    return results;
  } catch (e) {
    console.log(e);
  }
};
