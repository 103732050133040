import { Container } from "components/common/Container";
import { Icos } from "components/footer";
import LangBiangClub from "components/langbiang";
import { Liquidity } from "components/liquidity";
import MarketApp from "components/marketApp";
import Leaderboard from "components/leaderboard";
// import Launchpad from "components/launchpad";
import Marketplace from "components/marketplace";
import Asset from "components/asset";
import MyBalance from "components/myBalance";
import NftDetail from "components/nft-detail";
import MyVault from "components/vaults";
import WhitePaper from "components/white-paper";
import Intro from "./intro";
import marketIcon from "images/market.svg";
import marketIconActive from "images/market_active.svg";
import vaultIcon from "images/vault.svg";
import vaultIconActive from "images/vault_active.svg";
import balanceIcon from "images/balance.svg";
import balanceIconActive from "images/balance_active.svg";
import leaderboardIcon from "images/leaderboard.svg";
import leaderboardIconActive from "images/leaderboard_active.svg";
import rewardIcon from "images/rewards.svg";
import rewardIconActive from "images/reward_active.svg";

const NotFound = () => (
  <div
    style={{
      flex: "1",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Container>
      <div style={{ textAlign: "center" }}>
        <h1 style={{ fontSize: "5rem", fontWeight: 700 }}>Oops!</h1>
        <p
          style={{
            fontSize: "1rem",
            textTransform: "uppercase",
            fontWeight: 700,
          }}
        >
          404 - Page Not found
        </p>
      </div>
    </Container>
  </div>
);

export const routeManager = {
  loggedIn: {
    components: [
      {
        path: "/",
        element: <Intro />,
      },
      {
        path: "/app",
        element: <MarketApp />,
      },
      {
        path: "/vaults",
        element: <MyVault />,
      },
      {
        path: "/balance",
        element: <MyBalance />,
      },
      {
        path: "/nft-detail",
        element: <NftDetail />,
      },
      {
        path: "/white-paper",
        element: <WhitePaper />,
      },
      // {
      //   path: "/pitch-deck",
      //   element: <PitchDeck />,
      // },
      {
        path: "/marketplace",
        element: <Marketplace />,
      },
      {
        path: "/asset",
        element: <Asset />,
      },
      // {
      //   path: "/asset",
      //   element: <Asset />,
      // },
      // {
      //   path: "/config",
      //   element: <Config />,
      // },
      {
        path: "/liquidity",
        element: <Liquidity />,
      },
      {
        path: "/leaderboard",
        element: <Leaderboard />
      },
      // {
      //   path: "/launchpad",
      //   element: <Launchpad />
      // },
      {
        path: "*",
        element: <NotFound />,
      },
      {
        path: "/lang-biang-club",
        element: <LangBiangClub />,
      },
    ],
    urls: [
      {
        pathName: "/app",
        label: "Market",
        icon: marketIcon,
        iconAcitve: marketIconActive,
        blank: false,
        ref: true
      },
      // {
      //   pathName: "/vaults",
      //   label: "NFT Vaults",
      //   icon: vaultIcon,
      //   iconAcitve: vaultIconActive,
      //   blank: false,
      //   ref: true,
      // },
      {
        pathName: "/balance",
        label: "my balance",
        icon: balanceIcon,
        iconAcitve: balanceIconActive,
        blank: false,
        ref: true,
      },
      // {
      //   pathName: "/liquidity",
      //   label: "Rewards",
      //   icon: rewardIcon,
      //   iconAcitve: rewardIconActive,
      //   blank: false,
      // },
      // {
      //   pathName: "/leaderboard",
      //   label: "Leaderboard",
      //   icon: leaderboardIcon,
      //   iconAcitve: leaderboardIconActive,
      //   blank: false,
      // },
      // {
      //   label: "Auction",
      //   pathName: AuctionUrl,
      //   blank: true,
      // },
      {
        pathName: "#",
        label: "docs",
        ref: false,
      },
      {
        pathName: "#",
        label: "...",
        ref: false,
        dropdown: true
      },
    ],
  },
  noneLoggedIn: {
    components: [
      {
        path: "/",
        element: <Intro />,
      },
      {
        path: "/app",
        element: <MarketApp />,
      },
      // {
      //   path: "/vaults",
      //   element: <MyVault />,
      // },
      {
        path: "/marketplace",
        element: <Marketplace />,
      },
      {
        path: "/balance",
        element: <MyBalance />,
      },

      {
        path: "/lang-biang-club",
        element: <LangBiangClub />,
      },
      {
        path: "/white-paper",
        element: <WhitePaper />,
      },
      // {
      //   path: "/pitch-deck",
      //   element: <PitchDeck />,
      // },
      {
        path: "*",
        element: <NotFound />,
      },
      // {
      //   path: "/liquidity",
      //   element: <Liquidity />,
      // },
      // {
      //   path: "/leaderboard",
      //   element: <Leaderboard />,
      // },
    ],
    urls: [
      {
        pathName: "/app",
        label: "Market"
      },
      // {
      //   pathName: "/auction-house",
      //   label: "Auction House",
      // },
      // {
      //   pathName: "/liquidity",
      //   label: "Rewards",
      // },
    ],
  },
};

export const docsUrl = [
  {
    label: "whitepaper",
    path: "/white-paper",
    local: true,
  },
  // {
  //   label: "Pitch Deck",
  //   path: "/pitch-deck",
  //   local: true,
  // },
  {
    label: "Gitbook",
    path: "https://nearlend-dao.gitbook.io/nearlend-dao/",
    local: false,
  },
];

type TTHomeMenu = {
  pathName: string;
  name: string;
  children: any;
  pathUrl?: string;
  newTab?: boolean;
};

export const homeMenus: TTHomeMenu[] = [
  {
    pathName: "/",
    name: "Docs",
    children: docsUrl,
    newTab: false,
  },
  {
    pathName: "/",
    name: "Community",
    children: Icos,
    newTab: false,
  },
  {
    pathName: "https://app.astrodao.com/dao/nearlend-dao.sputnik-dao.near",
    name: "DAO",
    children: null,
    newTab: true,
  },
  {
    pathName: "/",
    pathUrl: "/lang-biang-club",
    name: "Lang Biang Club",
    children: null,
    newTab: false,
  },
];
