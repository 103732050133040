import Big from "big.js";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import {
  getTotalBalanceTokenPriceUSD,
  getBorrowMaxAmount,
  getAvailableLiquidityToken,
  getTotalBalanceNftPriceUSD,
  errorPipeline,
  validatePopup,
  formatBalanceWithDecimal
} from "utils/common";
import useCurrentToken from "hooks/useCurrentToken";
import ActionPopupView from "components/popup/component/ViewActionPopup";
import { EQueryKey, TTokenFormat } from "types/token";
import { AppContext } from "Contexts/AppContext";
import useFunctionContract from "hooks/useFunctionContract";
import useHealthFactor from "hooks/useHealthFactor";
import { ACTION, ERROR, MINIMUM_DOLLAR_IS_ALLOW, QUERY_KEY } from "utils/constant";
import useError from "hooks/useError";

const { GET_FORMAT_TOKEN } = EQueryKey;

type Props = {
  togglePopup: () => void;
  currentToken: any;
  totalBorrow?: any;
};
const Borrow = ({ togglePopup, currentToken, totalBorrow }: Props) => {
  let initInterval: any = useRef(null);
  const { handleBorrow } = useFunctionContract();
  const { currentHealthFactor } = useHealthFactor();
  const { errorApproveTransaction } = useError();
  const {
    tokenId,
    tokenName,
    tokenDecimals = 0,
    tokenIcon,
    tokenSymbol,
    tokenConfig,
    borrow_apr,
    tokenRatio,
  } = useCurrentToken(currentToken);

  const { contract, profile, poolTokenList, poolNftList } =
    useContext(AppContext);

  const [borrowed, setBorrowed] = useState(0);
  const [amountToken, setAmountToken] = useState(0);
  const [available, setAvailable] = useState<any>(0);
  const [maxBorrowAmount, setMaxBorrowAmount] = useState(0);
  const [availableLiquidity, setAvailableLiquidity] = useState(0);
  const [tokenUsdPrice, setTokenUsdPrice] = useState(0);
  const [error, setError] = useState("");
  const [borrowedState, setBorrowedState] = useState(0);
  const [collateralState, setCollateralState] = useState(0);

  // React query
  const queryClient = useQueryClient();

  // Other
  const _validateError = useCallback(() => {
    let _error = null;
    if (amountToken > 0 && (amountToken * tokenUsdPrice) < MINIMUM_DOLLAR_IS_ALLOW) {
      _error = errorPipeline(
        errorPipeline(() => {
          return ERROR.AMOUNT_MINIMUM_BORROW
        })
      )
    } else {
      _error = errorPipeline(
        validatePopup(ACTION.BORROW.toLowerCase(), amountToken, available)
      )
    }
    setError(_error);
  }, [amountToken, available]);

  const _handleBorrow = useCallback(async () => {
    if (currentHealthFactor < 100) return;
    _validateError();
    const errorApprove = await errorApproveTransaction();
    if (errorApprove) return setError(errorApprove);
    return handleBorrow(currentToken, amountToken, contract);
  }, [
    _validateError,
    amountToken,
    contract,
    currentHealthFactor,
    currentToken,
    errorApproveTransaction,
  ]);

  const _onChange = useCallback((e: any) => {
    setAmountToken(e);
  }, []);

  const _onChangeSlider = useCallback((e: any) => {
    setAmountToken(e);
  }, []);

  const initInfoHealthFactor = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;

    const collateral_to_usd = getTotalBalanceTokenPriceUSD(
      "collateral",
      profile?.userBalance?.supplied,
      getNewFormatToken,
      poolTokenList
    );

    const borrow_to_usd = getTotalBalanceTokenPriceUSD(
      "borrowed",
      profile?.userBalance?.borrowed,
      getNewFormatToken,
      poolTokenList
    );

    const nft_to_usd = getTotalBalanceNftPriceUSD(
      profile?.userBalance?.nft_supplied,
      getNewFormatToken,
      poolNftList
    );

    setBorrowedState(borrow_to_usd);
    setCollateralState(collateral_to_usd + nft_to_usd);
  }, [poolNftList, poolTokenList, queryClient, profile]);

  const _calculate = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;

    const collateral_to_usd = getTotalBalanceTokenPriceUSD(
      "collateral",
      profile?.userBalance?.supplied,
      getNewFormatToken,
      poolTokenList
    );

    const borrow_to_usd = getTotalBalanceTokenPriceUSD(
      "borrowed",
      profile?.userBalance?.borrowed,
      getNewFormatToken,
      poolTokenList
    );

    const nft_to_usd = getTotalBalanceNftPriceUSD(
      profile?.userBalance?.nft_supplied,
      getNewFormatToken,
      poolNftList
    );

    const { usd } = tokenConfig;

    const maxBorrowAmount = getBorrowMaxAmount(
      collateral_to_usd,
      borrow_to_usd,
      nft_to_usd,
      tokenRatio / 10000,
      usd
    );

    let availableLiquidity = getAvailableLiquidityToken(
      currentToken,
      getNewFormatToken
    );

    setTokenUsdPrice(usd);
    setMaxBorrowAmount(maxBorrowAmount);
    setAvailableLiquidity(availableLiquidity);
  }, [
    currentToken,
    poolNftList,
    poolTokenList,
    queryClient,
    tokenConfig,
    tokenRatio,
    profile,
  ]);

  const _getBorrowed = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      QUERY_KEY.GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;
    if (!getNewFormatToken || !tokenUsdPrice || !profile) return;
    const data = profile?.userBalance?.borrowed?.find(
      (item: any) => item.token_id === tokenId
    );
    if (!data || !data.balance) {
      return
    }
    const balance = Big(data.balance)
    .div(
      Big(10).pow(
        getNewFormatToken[tokenId].contract_decimals +
        getNewFormatToken[tokenId].extra_decimals
      )
    )
    .toNumber();
    if (data) {
      const balance_format =
        Number(
          formatBalanceWithDecimal(balance?.toString() || "0", tokenDecimals)
        ) || 0;

      setBorrowed(balance_format)
    }
  }, [
    queryClient,
    tokenUsdPrice,
    profile
  ])

  const _initAvailable = useCallback(() => {
    const available = Math.min(
      Math.max(0, maxBorrowAmount),
      availableLiquidity
    );
    setAvailable(available);
  }, [maxBorrowAmount, availableLiquidity]);

  useEffect(() => {
    _initAvailable();
  }, [_initAvailable]);

  const _initCalculate = useCallback(() => {
    if (!initInterval.current) {
      initInterval.current = setInterval(_calculate, 400);
    }
  }, [_calculate]);

  useEffect(() => {
    _initCalculate();
    return () => {
      clearInterval(initInterval.current);
    };
  }, [_initCalculate]);

  useEffect(() => {
    initInfoHealthFactor();
  }, [initInfoHealthFactor]);

  useEffect(() => {
    _validateError();
  }, [_validateError]);

  useEffect(() => {
    _getBorrowed();
  }, [_getBorrowed]);

  return (
    <ActionPopupView
      isYellow={true}
      textTitle={ACTION.BORROW}
      togglePopup={togglePopup}
      onChange={_onChange}
      onChangeSlider={_onChangeSlider}
      confirmPopUp={_handleBorrow}
      error={error}
      valAPY={borrow_apr}
      currentHealthFactor={currentHealthFactor}
      borrowed={borrowedState}
      balanceBorrowed={borrowed}
      collateral={collateralState}
      poolAvailable={availableLiquidity}
      currentToken={{
        available,
        tokenName,
        tokenSymbol,
        tokenUsdPrice,
        tokenIcon,
      }}
      tokenRatio={tokenRatio}
    />
  );
};

export default Borrow;
