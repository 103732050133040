import { useEffect, useState, useCallback, Fragment, useMemo } from "react";
import { InputNumber, Slider } from "antd";
import {
  formatSlider,
  markSlider,
  formatCash,
  formatCashToView,
  trimZero
} from "utils/common";
import iconClose from "images/icon-close.png";
import warning_icon from "images/warning_icon.png";
import { InfoCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";

import {
  ACTION,
  MAX_HEALTH_FACTOR_PERCENT,
  MINIMUM_DOLLAR_IS_ALLOW,
} from "utils/constant";

type Props = {
  isCollateral?: boolean;
  error: any;
  currentToken: any;
  valAPY?: any;
  borrowed?: number;
  balanceDeposited?: number;
  balanceBorrowed?: number;
  collateral?: number;
  tokenRatio?: number;
  tokenDecimals?: number;
  poolAvailable?: number;
  isYellow?: boolean;
  isRequireMaturity?: boolean;
  currentMaturity?: number;
  cbSetState?: (numb: any) => void;
  togglePopup: () => void;
  onChange?: (args: any) => any;
  onChangeSlider?: (args: any) => any;
  confirmPopUp?: () => void;
  textTitle?: string;
  currentHealthFactor?: number;
};

const ActionPopupView = ({
  isYellow = false,
  isRequireMaturity = false,
  currentMaturity = 30,
  cbSetState = (numb: any) => {},
  togglePopup,
  onChange,
  onChangeSlider,
  confirmPopUp,
  textTitle = ACTION.DEPOSIT,
  currentHealthFactor,
  error,
  valAPY,
  poolAvailable,
  borrowed = 0,
  balanceDeposited = 0,
  balanceBorrowed = 0,
  collateral = 0,
  tokenRatio = 0,
  currentToken: { available, tokenName, tokenSymbol, tokenUsdPrice, tokenIcon },
}: Props) => {
  const [amountToken, setAmountToken] = useState<number>(0);
  const [amountTokenPercent, setAmountTokenPercent] = useState(0);

  const rounded_tokenUsdPrice =
    Number(tokenUsdPrice?.toFixed(4).slice(0, -2)) || 0;
  const amountUsdToken = amountToken * rounded_tokenUsdPrice;
  const ratio_for_view = tokenRatio / 100;
  const ratio_for_calculate = tokenRatio / 10000;
  const _disableValueZero =
    Number(available * rounded_tokenUsdPrice) <= MINIMUM_DOLLAR_IS_ALLOW;

  // Borrow HealthFactor
  const _borrowHealthFactor = useMemo(() => {
    const amountUsdBorrow = amountUsdToken / ratio_for_calculate;
    const totalAmountUsdBorrow = borrowed + amountUsdBorrow;
    const result = Number((collateral / totalAmountUsdBorrow) * 100);
    const last_result =
      result <= currentHealthFactor ? result : currentHealthFactor;
    return last_result;
  }, [
    amountUsdToken,
    borrowed,
    collateral,
    currentHealthFactor,
    ratio_for_calculate,
  ]);

  const _withdrawHealthFactor = useMemo(() => {
    const amountUsdWithdraw = amountUsdToken * ratio_for_calculate;
    const totalAmountUsdBorrow = collateral - amountUsdWithdraw;
    const result = Number((totalAmountUsdBorrow / borrowed) * 100);
    const last_result =
      result <= currentHealthFactor ? result : currentHealthFactor;
    return last_result;
  }, [
    amountUsdToken,
    borrowed,
    collateral,
    currentHealthFactor,
    ratio_for_calculate,
  ]);

  // Deposit HealthFactor
  const _depositHealthFactor = useMemo(() => {
    const amountCollateral = amountUsdToken * ratio_for_calculate;
    const totalAmountCollateral = collateral + amountCollateral;
    return (totalAmountCollateral / borrowed) * 100 || 0;
  }, [amountUsdToken, borrowed, collateral, ratio_for_calculate]);

  const _onChange = useCallback(
    (e: any) => {
      setAmountToken(e);
      setAmountTokenPercent(Number(e / available) * 100);
      onChange && onChange(e);
    },
    [available, onChange]
  );

  const _onChangeSlider = useCallback(
    (e: any) => {
      setAmountToken((e / 100) * available);
      setAmountTokenPercent(e);
      onChangeSlider && onChangeSlider((e / 100) * available);
    },
    [available, onChangeSlider]
  );

  const _isButtonDisable = useMemo(() => {
    let isBorrow = textTitle === ACTION.BORROW;
    let isWithdraw = textTitle === ACTION.WITHDRAW;
    let check_healthFactor = _borrowHealthFactor;
    if (isWithdraw) check_healthFactor = _withdrawHealthFactor;

    check_healthFactor =
      isBorrow || isWithdraw
        ? Number(check_healthFactor?.toFixed(4).slice(0, -2))
        : 101;

    const invalid_healthFactor = check_healthFactor < 100 ? true : false;
    return (
      invalid_healthFactor ||
      !amountToken ||
      Number(formatCashToView(available)) === 0 ||
      error
    );
  }, [
    _borrowHealthFactor,
    _withdrawHealthFactor,
    amountToken,
    available,
    error,
    textTitle,
  ]);

  const _confirmPopUp = useCallback(() => {
    if (_isButtonDisable) return;
    confirmPopUp && confirmPopUp();
  }, [_isButtonDisable, confirmPopUp]);

  const _handleMaxAvailable = () => {
    if (_disableValueZero) return;
    setAmountToken(available);
    onChangeSlider && onChangeSlider(available);
    setAmountTokenPercent(100);
  };

  const _onFocus = (e) => {
    // clean 0 number fist-time onFocus
    if (e.target.value === "0") return setAmountToken(null);
  };

  const TooltipComp = ({
    isShow = false,
    title = "To ensure the safety of the balance, user may be required to withdraw the assets partialy.",
  }) => {
    if (!isShow) return null;
    return (
      <Tooltip
        className="title-tooltip"
        zIndex={109999}
        arrowPointAtCenter
        color="#0000006b"
        title={title}
      >
        <InfoCircleFilled />
      </Tooltip>
    );
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const htmlEle = window.document.getElementsByTagName("html")[0];
      const popupEle = window.document.getElementsByTagName("wrap-popup")[0];
      if (popupEle) {
        popupEle.addEventListener("click", () => {
          togglePopup && togglePopup();
        });
      }
      htmlEle.classList.add("popup-open");
    }
    return () => {
      const htmlEle = window.document.getElementsByTagName("html")[0];
      htmlEle.classList.remove("popup-open");
    };
  }, [togglePopup]);

  return (
    <div className={`wrap-popup ${isYellow && "yellow"}`}>
      <div className="popup">
        <p className="icon-close" onClick={togglePopup}>
          <img alt="icon-close" src={iconClose} width={12} height={12} />
        </p>
        <h4 className={`title ${isYellow && "yellow"}`}>
          {textTitle}{" "}
          <TooltipComp isShow={textTitle === ACTION.WITHDRAW ? true : false} />
        </h4>
        <div className="wrap-icon-name">
          <p className={`icon ${(tokenName == 'USDT.e' ? 'no-bg' : '')}`}>
            <img
              className="icon"
              src={tokenIcon}
              width={54}
              height={54}
              alt="Logo"
            />
          </p>
          <div className="wrap-price-apy">
            <div className="wrap-price">
              <p className="icon-name">{tokenName}</p>
              <p className="price">${formatCash(rounded_tokenUsdPrice, 8)}</p>
            </div>
            {<p className="apy-number">{valAPY || 0}% APY</p>}
          </div>
        </div>
        <h3 className="available">
          Available: {trimZero(formatCashToView(available))} {tokenSymbol}{" "}
          <span>
            ($
            {formatCashToView(available * rounded_tokenUsdPrice, 8)})
          </span>
        </h3>
        <div className="wrap-input-number">
          <InputNumber
            onFocus={_onFocus}
            type="number"
            onChange={_onChange}
            disabled={_disableValueZero}
            className={`input-number ${error && "input-number-error"}`}
            value={amountToken?.toString().replace(",", ".") || ""}
            decimalSeparator={"."}
            placeholder={"amount(s)"}
          />
          <div id="input-range-max" onClick={_handleMaxAvailable}>
            MAX
          </div>
        </div>
        <div
          id="slider-range"
          className={`position-relative slider-range ${isYellow && "yellow"}`}
        >
          <Slider
            disabled={_disableValueZero}
            marks={markSlider}
            step={1}
            tipFormatter={formatSlider}
            getTooltipPopupContainer={(): any =>
              document?.getElementById("slider-range")
            }
            value={amountTokenPercent || 0}
            onChange={_onChangeSlider}
          />
        </div>

        {isRequireMaturity && (
          <div className="wrap-maturity">
            <h3 className="maturity-title">Maturity</h3>
            <div className="maturity-dates">
              {maturityDates.map((dates) => (
                <button
                  key={dates + ""}
                  className={`${currentMaturity === dates ? "active" : null}`}
                  onClick={() => cbSetState(dates)}
                >
                  {dates} days
                </button>
              ))}
            </div>
          </div>
        )}

        {/* <p className="total-available">
          Total {textTitle} <span style={{ fontSize: 22 }}>&#8771;</span> $
          {formatCash(amountToken * tokenUsdPrice)}
        </p> */}

        <p className="position-relative rates-title fwb margin-horizontal-24">
          Details
        </p>

        {(textTitle === ACTION.DEPOSIT || textTitle === ACTION.REPAY) && (
          <Fragment>
            <div className="position-relative flex margin-horizontal-24">
              <div className="left">Health Factor</div>
              <div
                className={`right fwb color-white ${
                  _depositHealthFactor >= 200
                    ? "safe-health-factor"
                    : "danger-health-factor"
                }`}
              >
                {_depositHealthFactor > 10000
                  ? MAX_HEALTH_FACTOR_PERCENT
                  : `${_depositHealthFactor?.toFixed(4).slice(0, -2)}%`}
              </div>
            </div>
          </Fragment>
        )}

        {(textTitle === ACTION.BORROW) && (
          <Fragment>
            <div className="position-relative flex margin-horizontal-24">
              <div className="left">Health Factor</div>
              <div
                className={`right fwb color-white ${
                  _borrowHealthFactor >= 200
                    ? "safe-health-factor"
                    : "danger-health-factor"
                }`}
              >
                {_borrowHealthFactor > 10000 || _borrowHealthFactor === 0
                  ? MAX_HEALTH_FACTOR_PERCENT
                  : `${_borrowHealthFactor?.toFixed(4).slice(0, -2)}%`}
              </div>
            </div>
          </Fragment>
        )}

        {(textTitle === ACTION.WITHDRAW) && (
          <Fragment>
            <div className="position-relative flex margin-horizontal-24">
              <div className="left">Health Factor</div>
              <div
                className={`right fwb color-white ${
                  _withdrawHealthFactor >= 200
                    ? "safe-health-factor"
                    : "danger-health-factor"
                }`}
              >
                {_withdrawHealthFactor > 10000 || _withdrawHealthFactor === 0
                  ? MAX_HEALTH_FACTOR_PERCENT
                  : `${_withdrawHealthFactor?.toFixed(4).slice(0, -2)}%`}
              </div>
            </div>
          </Fragment>
        )}

        <div className="position-relative flex margin-horizontal-24">
          <div className="left">Total {textTitle === ACTION.DEPOSIT ? 'deposited' : (textTitle === ACTION.BORROW ? 'borrowed' : textTitle)}</div>
          <div className="right fwb color-white">
            {textTitle === ACTION.DEPOSIT ? 
              formatCashToView(balanceDeposited as unknown as number, 8) :
              textTitle === ACTION.BORROW ? 
                formatCashToView(balanceBorrowed as unknown as number, 8) :
                formatCashToView(amountToken * rounded_tokenUsdPrice, 8)
            }
          </div>
        </div>
        {textTitle === ACTION.BORROW || textTitle === ACTION.WITHDRAW ? (
          <></>
        ) : (
          <div className="position-relative flex margin-horizontal-24">
            <div className="left">Collateral Factor</div>
            <div className="right fwb color-white">{ratio_for_view}%</div>
          </div>
        )}

        {poolAvailable ? (
          <div className="position-relative flex margin-horizontal-24">
            <div className="left">Available Liquidity</div>
            <div className="right fwb color-white">
              {formatCashToView(poolAvailable, 8)} {tokenSymbol}
            </div>
          </div>
        ) : null}

        <div className="bg-white position-relative wrap-white">
          <div className={`wrap-error-popup ${error && "error"}`}>
            {error && (
              <Fragment>
                <p className="icon-error">
                  <img
                    src={warning_icon}
                    alt="warning_icon"
                    width={22}
                    height={22}
                  />
                </p>
                <p className="text-error">{error} </p>
              </Fragment>
            )}
          </div>
          <button
            className={`${
              _isButtonDisable && "disable"
            } position-relative btn ${isYellow && "yellow"}`}
            onClick={_confirmPopUp}
          >
            {textTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActionPopupView;

const maturityDates = [30, 60, 90];
