import styled from "styled-components";
import { RelativeWrapper, Title } from "./styles";
import { WrappAnimated } from "components/common";
import { Container } from "components/common/Container";
import Image from "components/common/Images";

import CogitentIco from "images/investors/cogitent.png";
import AntisocialIco from "images/investors/antisocial.png";
import BullishIco from "images/investors/bullish.png";
import Neartracker from "images/investors/neartracker.png";
import NearFi from "images/investors/nearfi.png";
import Anony_1 from "images/investors/@@.png";
import Anony_2 from "images/investors/aocha.png";
import Anony_3 from "images/investors/near-insider.png";
import Anony_4 from "images/investors/near.png";
import Anony_5 from "images/investors/nearity.png";
import Anony_6 from "images/investors/oo.png";
import Anony_7 from "images/investors/s.png";
import Anony_8 from "images/investors/unicorn.png";
import Anony_9 from "images/investors/v.png";
import Anony_10 from "images/investors/w3w.png";

import gridBanner from "images/bgInvester.svg";

function InvesterUI() {
  return (
    <CustomWrapper className="mobile-invester-only">
      <Container style={{ overflow: "hidden" }}>
        <InvesterSpace>
          <WrappAnimated style={{ marginTop: 75 }}>
            <Title
              style={{ marginBottom: 30 }}
              data-aos="fade-down"
              className="mobile-invester-title-only"
            >
              <span className="high-light">Investors</span> & Partners
            </Title>
            <FlexStyle style={{ gap: 20 }}>
              {investorList.map((item: any, idx: number) => (
                <InvestorItem key={idx}>
                  <InvestorCard
                    data-aos="fade-up"
                    data-aos-delay={idx * 100}
                    className="mobile-investor-list-only"
                    src={item.src}
                  />
                </InvestorItem>
              ))}
            </FlexStyle>
          </WrappAnimated>
        </InvesterSpace>
      </Container>
    </CustomWrapper>
  );
}

export default InvesterUI;

const InvestorItem = styled.div`
  max-width: 190px;
  width: 40%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 10px auto;
  @media screen and (max-width: 385px) {
    max-width: 145px;
    margin: auto;
  }
`;

const investorList = [
  // {
  //   src: CogitentIco,
  // },
  {
    src: Neartracker,
  },
  // {
  //   src: AntisocialIco,
  // },
  // {
  //   src: BullishIco,
  // },
  // {
  //   src: NearFi,
  // },
  {
    src: Anony_3,
  },
  // {
  //   src: Anony_5,
  // },
  {
    src: Anony_1,
  },
  {
    src: Anony_2,
  },
  {
    src: Anony_6,
  },
  {
    src: Anony_8,
  },
  // {
  //   src: Anony_4,
  // },
  {
    src: Anony_10,
  },
  {
    src: Anony_7,
  },
  {
    src: Anony_9,
  },
];

const FlexStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const InvestorCard = styled.div<{ src: string }>`
  position: relative;
  background: ${(props: any) => `url(${props.src})`};
  mix-blend-mode: luminosity;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
`;

const CustomWrapper = styled(RelativeWrapper)`
  // margin-top: 100px;
  &::after {
    content: "";
    position: absolute;
    background-image: url(${gridBanner});
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
  }
`;

const InvesterSpace = styled.div`
  height: 100%;
  padding: 2rem 0 5rem 0;
  width: 100%;
  text-align: center;
  position: relative;
  /* &::before {
    content: "";
    position: absolute;
    top: 7%;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background: rgb(3, 22, 21);
    background: radial-gradient(
      circle,
      rgba(3, 22, 21, 1) 0%,
      rgba(66, 157, 42, 0.25) 0%,
      rgba(13, 43, 3, 0) 44%
    );
  } */
`;
