import { useContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { BackTop } from "antd";

import Main from "components/main";
import AppContextProvider, { AppContext } from "Contexts/AppContext";
import { WalletSelectorContextProvider } from 'Contexts/WalletContext';
import { routeManager } from "routes";
import { AppInitWrapper } from "Contexts";

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <WalletSelectorContextProvider>
        <BrowserRouter>
          <AppContextProvider>
            <Main>
              <AppInitWrapper>
                <RouteManager />
              </AppInitWrapper>
              <BackTop />
            </Main>
          </AppContextProvider>
        </BrowserRouter>
      </WalletSelectorContextProvider>
    </QueryClientProvider>
  );
}

const RouteManager = () => {
  const { isLogin } = useContext(AppContext);
  const mappingRoute = isLogin
    ? routeManager.loggedIn.components
    : routeManager.noneLoggedIn.components;

  return (
    <Routes>
      {mappingRoute.map(({ path, element }, idx: number) => {
        return <Route key={idx} path={path} element={element} />;
      })}
    </Routes>
  );
};
