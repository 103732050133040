import React from "react";
import { Container } from "components/common/Container";
import { ReactComponent as TwiterIco } from "images/twiter.svg";
import { ReactComponent as DiscordIco } from "images/discord.svg";

const LangbiangFooter = () => {
  return (
    <div className="footer">
      <Container className="container-wrapper">
        <div className="icons-wrapper">
          <div>
            <a href="/" target="_blank" rel="noreferrer" className="">
              ©LangBiang - NearlendDAO
            </a>
          </div>
          <div className="langbiang-mobile-footer langbiang-footer">
            <a
              href="https://twitter.com/NearlendDao"
              target="_blank"
              rel="noreferrer"
              className="footer-ico-item"
            >
              <span className="desktop-only title-social">Twitter</span>
              <span className="mobile-only">
                <TwiterIco style={{ width: 30, height: 30 }} />
              </span>
            </a>
            <a
              href="https://discord.gg/pXvHhT9rwM"
              target="_blank"
              rel="noreferrer"
              className="footer-ico-item"
            >
              <span className="desktop-only title-social">Discord</span>
              <span className="mobile-only">
                <DiscordIco
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              </span>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LangbiangFooter;
