import { CloseOutlined } from "@ant-design/icons";
import { Logo } from "components/common";
import SocialFooter from "components/common/SocialFooter";
import { DebuggerContext } from "components/debug/FlagDebugger";
import { AppContext } from "Contexts/AppContext";
import { useWindowDimensions } from "hooks/useWindowSize";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import useFunctionContract from 'hooks/useFunctionContract';
import { NavLink, useLocation } from "react-router-dom";
import { routeManager } from "routes";
import styled from "styled-components";
import { COLORs } from "utils/colors";
import { formatAccountName } from "utils/common";
import { useWalletSelector } from 'Contexts/WalletContext';
import stargate from "images/stargate.png";
import rainbow from "images/rainbow.png";
import tokenBridge from "images/tokenBridge.png";
import electron from "images/electron.png";
import ref from "images/ref.png";
import veax from "images/veax.png";
import jumbo from "images/jumbo.png";
import btse from "images/btse.png";
import jump from "images/jump.png";
import deltatrade from "images/deltatrade.png";
import { ENV_EXPLORER_URL } from "utils/constant";
import { notification } from "antd";
import {
  AuctionUrl,
  mobileBreakPoint,
  pathCollection,
  ROUTE_COLLECTION,
} from "utils/constant";
import "./style.scss";

import { ReactComponent as MenuOutlined } from "images/MenuOutlined.svg";

export default function Header() {
  const { isUrlDebugger, queryString } = useContext(DebuggerContext);
  const { isLogin } = useContext(AppContext);
  const { accountId } = useWalletSelector() || {};

  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width <= mobileBreakPoint, [width]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuBridgeOpen, setIsMenuBridgeOpen] = useState(false);
  const [isMenuSwapOpen, setIsMenuSwapOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);

  const { pathname } = useLocation();
  const menuWithoutLandingPage = pathCollection.includes(pathname) || pathCollection.includes(`${pathname}/`);

  const { handleNearLogin, handleNearLogOut, handleNearSwitch } = useFunctionContract();

  const handleCopy = () => {
    navigator.clipboard.writeText(accountId);
    notification.success({
      style: { background: '#041205', color: '#ffffff', width: 'fit-content' },
      message: "Copied!"
    });
  }

  const handleOpenMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleScroll = () => {
    const elementTopMenu: Element | null =
      window.document.getElementById("wrap-header-menu");
    if (window.scrollY >= 10) {
      elementTopMenu && elementTopMenu.classList.add("active");
    } else {
      elementTopMenu && elementTopMenu.classList.remove("active");
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.document
        .getElementsByTagName("html")[0]
        .classList.remove("nav-open");
      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const ConnectWalletComp = () => (
    <div
      className={`menu-btn button-style menus  ${
        isUrlDebugger ? "" : "non-login"
      } `}
    >
      <span className="btn-connect" onClick={handleNearLogin}>
        <span className="btn-label">Connect Wallet</span>
      </span>
    </div>
  );

  const Bridge = () => {
    return (
      <>
        <BridgeLink href='https://stargate.finance/bridge' target='_blank'>
          <img src={stargate} width="20" height="20" />
          Stargate
        </BridgeLink>
        <BridgeLink href='https://rainbowbridge.app/' target='_blank'>
          <img src={rainbow} width="20" height="20" />
          Rainbow bridge
        </BridgeLink>        
        <BridgeLink href='https://tokenbridge.app' target='_blank'>
          <img src={tokenBridge} width="20" height="20" />
          Token bridge
        </BridgeLink>        
        <BridgeLink href='https://app.ref.finance/bridge' target='_blank'>
          <img src={ref} width="20" height="20" />
          Ref bridge
        </BridgeLink>
    </>
    )
  }

  const MoreDropdown = () => {
    return (
      <>
        <div className="more btn-header btn-group-bridge" style={{top: 'inherit'}}>
          <div className="wrap-toggle header-child more-dropdown">
            <BridgeLink href='https://github.com/nearlend-dao' target='_blank' className="no-active">
              Github
              <svg width="13" height="13" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 0V1H7.295L2.38 5.915L3.085 6.62L8 1.705V3.5H9V0M8 8H1V1H4.5V0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H8C8.26522 9 8.51957 8.89464 8.70711 8.70711C8.89464 8.51957 9 8.26522 9 8V4.5H8V8Z" fill="#fff"></path></svg>
            </BridgeLink>
            <BridgeLink href='https://www.nft.nearlenddao.com/' target='_blank' className="no-active">
              Lang Biang
              <svg width="13" height="13" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 0V1H7.295L2.38 5.915L3.085 6.62L8 1.705V3.5H9V0M8 8H1V1H4.5V0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H8C8.26522 9 8.51957 8.89464 8.70711 8.70711C8.89464 8.51957 9 8.26522 9 8V4.5H8V8Z" fill="#fff"></path></svg>
            </BridgeLink>
            {/* <BridgeLinkDisabled className="no-active">
              Launchpad
              <svg width="13" height="13" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 0V1H7.295L2.38 5.915L3.085 6.62L8 1.705V3.5H9V0M8 8H1V1H4.5V0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H8C8.26522 9 8.51957 8.89464 8.70711 8.70711C8.89464 8.51957 9 8.26522 9 8V4.5H8V8Z" fill="#fff"></path></svg>
            </BridgeLinkDisabled> */}
            <BridgeLink href='https://www.coingecko.com/en/coins/nearlend-dao' target='_blank' className="no-active">
              Coingecko
              <svg width="13" height="13" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 0V1H7.295L2.38 5.915L3.085 6.62L8 1.705V3.5H9V0M8 8H1V1H4.5V0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H8C8.26522 9 8.51957 8.89464 8.70711 8.70711C8.89464 8.51957 9 8.26522 9 8V4.5H8V8Z" fill="#fff"></path></svg>
            </BridgeLink>
            <BridgeLinkDisabled className="no-active">
              Buy
              <svg width="13" height="13" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 0V1H7.295L2.38 5.915L3.085 6.62L8 1.705V3.5H9V0M8 8H1V1H4.5V0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H8C8.26522 9 8.51957 8.89464 8.70711 8.70711C8.89464 8.51957 9 8.26522 9 8V4.5H8V8Z" fill="#fff"></path></svg>
            </BridgeLinkDisabled>
          </div>
        </div>
      </>
    )
  }

  const Swap = () => {
    return (
      <>
        <BridgeLink href='https://www.ref.finance/' target='_blank'>
          <img src={ref} width="20" height="20" />
          Ref Finance
        </BridgeLink>
        <BridgeLink href='https://app.veax.com/' target='_blank'>
          <img src={veax} width="20" height="20" />
          VEAX
        </BridgeLink>
        <BridgeLink href='https://jumbo.exchange/swap' target='_blank'>
          <img src={jumbo} width="20" height="20" />
          Jumbo
        </BridgeLink>
        <BridgeLink href='https://dex.btse.com/' target='_blank'>
          <img src={btse} width="20" height="20" />
          BTSE DEX
        </BridgeLink>
        <BridgeLink href='https://app.jumpdefi.xyz/swap' target='_blank'>
          <img src={jump} width="20" height="20" />
          Jump DeFi
        </BridgeLink>
        <BridgeLink href='https://www.deltatrade.ai/' target='_blank'>
          <img src={deltatrade} width="20" height="20" style={{ borderRadius: "50%" }} />
          Delta Trade
        </BridgeLink>
      </>
    )
  }

  const MobileSubItem = () => (
    <>
      {isMobile && (
        <>
          <>
            <span
              className="mobile-only-responsive menu child-menu logged-menu swap"
              onClick={() => setIsMoreOpen(false)}
            >
              <MoreDropdown />
            </span>
            <span
              className="mobile-only-responsive menu child-menu logged-menu swap"
              onClick={() => setIsMenuSwapOpen(false)}
            >
              <Swap />
            </span>
            <span
              className="mobile-only-responsive menu child-menu logged-menu bridge"
              onClick={() => setIsMenuBridgeOpen(false)}
            >
              <Bridge />
            </span>
          </>
          {isLogin ? (
            <>
              <span
                className="mobile-only-responsive menu child-menu logged-menu"
                onClick={() => setIsMenuOpen(false)}
              >
                <NavLink
                  to={{
                    pathname: ROUTE_COLLECTION.VIEW_ACCOUNT,
                    search: isUrlDebugger ? queryString : "",
                  }}
                >
                  View Account
                </NavLink>
              </span>
              <span
                onClick={handleNearSwitch}
                className="mobile-only-responsive sign-out menu child-menu logged-menu"
                style={{ fontWeight: 700 }}
              >
                Switch Wallet
              </span>
              <span
                onClick={handleNearLogOut}
                className="mobile-only-responsive sign-out menu child-menu logged-menu"
                style={{ fontWeight: 700 }}
              >
                Sign Out
              </span>
            </>
          ) : (
            <ConnectWalletComp />
          )}
        </>
      )}
    </>
  );

  return (
    <header id="wrap-header-menu" className="header">
      <div className="container container-header">
        <div className="wrapper-logo-header">
          <div className="mobile-responsive-logo">
            <Logo />
          </div>
          {isMobile && (
            <div className="hamburger" onClick={handleOpenMenu}>
              <MenuOutlined />
            </div>
          )}
        </div>
        {!menuWithoutLandingPage && !isMobile && (
          <div style={{ display: "flex", alignItems: "center", gap: 50 }}>
            {navigatorList.map((item, idx) => {
              return (
                <div key={idx} style={{display: "flex", "alignItems": "center"}}>
                  <LinkCover
                    href={item.to}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item?.label}
                  </LinkCover>
                  {idx > routeManager.loggedIn.urls.length - 4 ? null : (
                      <span style={{backgroundColor: "var(--greenlight)", width: "4px", height: "4px", marginLeft: "30px"}} className="point-mobile" />
                    )}
                </div>
              );
            })}
          </div>
        )}
        <nav
          className={`${isMenuOpen ? "active" : ""} mobile-responsive-navbar`}
        >
          <div className="mobile-only-responsive logo-mobile-responsive">
            <div className="wrapper-close-ico" onClick={handleOpenMenu}>
              <CloseOutlined style={{ fontSize: 25 }} />
            </div>
            <div onClick={() => setIsMenuOpen(false)}>
              <Logo />
            </div>
          </div>
          <div className="nav-menu">
            {!menuWithoutLandingPage && (
              <>
                <div className="menus mobile-sub-item">
                  <span
                    className="mobile-only-responsive menu child-menu logged-menu"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <NavLink
                      to={{
                        pathname: ROUTE_COLLECTION.APP,
                        search: isUrlDebugger ? queryString : "",
                      }}
                    >
                      Launch App
                    </NavLink>
                  </span>
                  {isMobile && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 30,
                        textAlign: "left",
                      }}
                    >
                      {navigatorList.map((item, idx) => {
                        return (
                          <div key={idx}>
                            <LinkCover
                              href={item.to}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item?.label}
                            </LinkCover>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>

                <div className="homepage-social">
                  <SocialFooter />
                </div>
              </>
            )}

            {menuWithoutLandingPage && (
              <div className="menus without-homepage">
                {routeManager.loggedIn.urls.map((item, idx) => (
                  <Fragment key={idx}>
                    <span
                      key={idx}
                      className="menu child-menu logged-menu child-item"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      {item?.blank ? (
                        <a
                          className="link-auction"
                          href={AuctionUrl}
                          rel="noreferrer"
                          target={"_blank"}
                        >
                          {item?.label}
                        </a>
                      ) : (
                        item?.ref ?
                        <NavLink
                          to={{
                            pathname: item?.pathName,
                            search: isUrlDebugger ? queryString : "",
                          }}
                          className={({ isActive }) =>
                            isActive ? "link-active" : ""
                          }
                        >
                          <div style={{ display: "flex", gap: "8px", alignItems: "center"}}>
                            {/* <img src={item?.icon} width={24} height={24} /> */}
                            {/* <img src={item?.iconAcitve} className="active" width={24} height={24} style={{display: "none"}} /> */}
                            {item?.label}
                          </div>
                        </NavLink> : 
                          item?.dropdown ?
                          (!isMobile &&
                            <div className={`menu-btn header-container button-style menus`}>
                              <span className="btn-connect btn-more-dropdown">
                                <span className="btn-label btn-bridge" style={{color: 'white'}}>
                                  {item?.label}
                                </span>
                                <MoreDropdown />
                              </span>
                            </div>
                          ) :
                          <a aria-current="page" href="https://nearlend-dao.gitbook.io/nearlend-dao/" target='_blank'>
                            <div style={{ display: "flex", gap: "8px", alignItems: "center"}}>
                              {item?.label}
                              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.646447 8.64645C0.451184 8.84171 0.451184 9.15829 0.646447 9.35355C0.841709 9.54882 1.15829 9.54882 1.35355 9.35355L0.646447 8.64645ZM9.98528 0.514718C9.98528 0.238576 9.76142 0.0147186 9.48528 0.0147185L4.98528 0.0147189C4.70914 0.0147187 4.48528 0.238577 4.48528 0.514719C4.48528 0.790861 4.70914 1.01472 4.98528 1.01472L8.98528 1.01472L8.98528 5.01472C8.98528 5.29086 9.20914 5.51472 9.48528 5.51472C9.76142 5.51472 9.98528 5.29086 9.98528 5.01472L9.98528 0.514718ZM1.35355 9.35355L9.83883 0.868272L9.13173 0.161165L0.646447 8.64645L1.35355 9.35355Z" fill="currentColor"></path></svg>
                            </div>
                          </a>
                      )}
                    </span>
                    {idx > routeManager.loggedIn.urls.length - 2 ? null : (
                      <span className="light child-menu child-item" />
                    )}
                  </Fragment>
                ))}
                <MobileSubItem />
              </div>
            )}
          </div>
          {menuWithoutLandingPage && (
            <div className="mobile-only-responsive mobile-menu-bottom">
              <SocialFooter />
            </div>
          )}
        </nav>
        {menuWithoutLandingPage && (
          <div
            className='mobile-responsive-navbar right-wallet-btn'
          >
            <div className={`${
              isMenuSwapOpen ? "active" : ""
            } menu-btn header-container button-style menus`}>
              <span className="btn-connect">
                <span className="btn-label btn-bridge">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 13V13.5H8H10H10.5V13V7.03211L12.2214 8.75355L12.5719 9.10399L12.9254 8.75667L14.3504 7.35667L14.7102 7.00314L14.3536 6.64645L9.35355 1.64645L9 1.29289L8.64645 1.64645L3.64645 6.64645L3.28975 7.00314L3.64959 7.35667L5.07459 8.75667L5.42812 9.10399L5.77855 8.75355L7.5 7.03211V13ZM14.6464 22.3536L15 22.7071L15.3536 22.3536L20.3536 17.3536L20.7102 16.9969L20.3504 16.6433L18.9254 15.2433L18.5719 14.896L18.2214 15.2464L16.5 16.9679V11V10.5H16H14H13.5V11V16.9679L11.7786 15.2464L11.4281 14.896L11.0746 15.2433L9.64959 16.6433L9.28975 16.9969L9.64645 17.3536L14.6464 22.3536Z" stroke="white"/>
                  </svg>
                  Swap
                </span>
                <div className="more btn-header btn-group-bridge">
                  <div className="wrap-toggle header-child">
                    <Swap />
                  </div>
                </div>
              </span>
            </div>
            <div className={`${
              isMenuBridgeOpen ? "active" : ""
            } menu-btn header-container button-style menus`}>
              <span className="btn-connect">
                <span className="btn-label btn-bridge">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 8.5H22V7.5V5.5H22.25V19.25H21.6511C19.8136 15.4565 15.8454 13.625 12 13.625C8.15458 13.625 4.18637 15.4565 2.34894 19.25H1.75V5.5H2V7.5V8.5H3H5.8125H6.8125V7.5V5.5H7.0625V7.5V8.5H8.0625H10.875H11.875V7.5V5.5H12.125V7.5V8.5H13.125H15.9375H16.9375V7.5V5.5H17.1875V7.5V8.5H18.1875H21Z" stroke="white" stroke-width="2"/>
                  </svg>
                  Bridge
                </span>
                <div className="more btn-header btn-group-bridge">
                  <div className="wrap-toggle header-child bridge-container">
                    <Bridge />
                  </div>
                </div>
              </span>
            </div>
            {isLogin ? (
              <>
                <div className={`menu-btn header-container button-style menus`}>
                  <span className="btn-connect">
                    <span className="btn-label">
                      {formatAccountName(accountId)}
                    </span>
                    <div className="more btn-header">
                      <div className="wrap-toggle header-child">
                        <span onClick={handleCopy}>
                          Copy address
                        </span>
                        <a
                          href={`${ENV_EXPLORER_URL}address/${accountId}`}
                          target='_blank'
                        >
                          View on scan
                        </a>

                        <span onClick={handleNearSwitch} className="sign-out">
                          Switch Wallet
                        </span>
                        <span onClick={handleNearLogOut} className="sign-out">
                          Sign Out
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </>
            ) : (
              <ConnectWalletComp />
            )}
          </div>
        )}
      </div>
    </header>
  );
}

const LinkCover = styled.a`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  &:hover {
    color: ${COLORs.GREEN_LIGHT};
  }
`;

const BridgeLink = styled.a`
  display: flex !important;
  gap: 8px;
  font-family: "Open Sans";
  font-weight: 400 !important;
  font-size: 14px;
`

const BridgeLinkDisabled = styled.div`
  display: flex !important;
  gap: 8px;
  font-family: "Open Sans";
  font-weight: 400 !important;
  font-size: 14px;
  padding: 8px 0;
  text-align: left;
  text-transform: capitalize;
  white-space: nowrap;
  color: var(--white);
  font-family: "Poppins", sans-serif;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid var(--sub-greenlight);
  }
  &:last-child {
    padding-bottom: 0;
  }
  &:hover {
    color: var(--greenlight);
    svg {
      path {
        fill: var(--greenlight);
      }
    }
  }
`

const navigatorList = [
  // {
  //   label: "Lang Biang",
  //   to: "https://nearlenddao.com/lang-biang-club",
  // },
  // {
  //   label: "Welott",
  //   to: "https://welott.nearlenddao.com/",
  // },
  // {
  //   label: "NFT Auction",
  //   to: "https://auction.nearlenddao.com/",
  // },
  // {
  //   label: "Investors",
  //   to: "https://investors.nearlenddao.com/",
  // },
];
