import * as nearAPI from "near-api-js";
import Big from "big.js";
import {
  SUPPORTED_NFT,
  SUPPORTED_TOKENS,
  tokenFormat,
  ENV_ID_TOKEN_NEL,
  ENV_ID_ORACLE_CONTRACT,
  ENV_ID_MAIN_CONTRACT,
  ENV_NODE_URL,
  ENV_WALLET_URL,
  ENV_HELPER_URL,
  ENV_ID_NETWORK,
  ENV_ID_TOKEN_wNEAR,
  MINIMUM_DOLLAR_IS_ALLOW,
  ENV_ID_NFT_LANGBIANG,
} from "utils/constant";
import {
  handleLastDecimalReplaceByZero,
  removeAfterPoint,
  generateZeroDecimal
} from "utils/common";

const { connect, WalletConnection, keyStores } = nearAPI;
const keyStore = new keyStores.BrowserLocalStorageKeyStore();
export const GAS = 200000000000000;
export const ONE_OCTO = 1;
export const ZERO_OCTO = 0;
export const ONE_OCTO_STRING = "100000000000000000000000";

const INDEXER_SERVICE_URL = "https://testnet-api.kitwallet.app";

export const nearConfig = {
  networkId: ENV_ID_NETWORK,
  nodeUrl: ENV_NODE_URL,
  contractName: ENV_ID_MAIN_CONTRACT,
  walletUrl: ENV_WALLET_URL,
  helperUrl: ENV_HELPER_URL,
  headers: {
    "Content-Type": "application/json",
  },
};
const asset_ids = [...SUPPORTED_TOKENS, ...SUPPORTED_NFT];

export const _near = async function () {
  return await connect({
    ...nearConfig,
    keyStore,
  });
};

export const _walletConnection = function (_near: any) {
  return new WalletConnection(_near, 'nearlend');
};

export const _contract = function (wallet: any) {
  return new nearAPI.Contract(wallet.account(), ENV_ID_MAIN_CONTRACT, {
    viewMethods: [
      "get_assets_paged",
      "get_assets_paged_detailed",
      "get_asset",
      "ft_metadata",
      "get_account",
      "get_accounts_paged",
      "nft_metadata",
      "get_assets_apr",
      "get_nft_assets_paged",
      "get_asset_farms_paged",
      "get_asset_farms_all",
    ],
    changeMethods: [
      "storage_deposit",
      "ft_transfer",
      "ft_transfer_call",
      "nft_transfer_call",
    ],
    useLocalViewExecution: true,
  });
};

export const _contractNFT = function (wallet: any) {
  return new nearAPI.Contract(wallet.account(), SUPPORTED_NFT[0], {
    viewMethods: ["get_nft_assets_paged"],
    changeMethods: ["ft_transfer_call", "nft_transfer_call"],
    useLocalViewExecution: true,
  });
};

export const get_asset_farms_all = async (contract) => {
  return await contract?.account
    ?.viewFunction(contract?.contractId, "get_asset_farms_all")
    .then((res) => res)
    .catch((e) => {
      console.log(e);
      return null;
    });
};

export const get_num_nfts = async (contract) => {
  return await contract?.account
    ?.viewFunction(contract?.contractId, "get_num_nfts", {
      nft_contract_id: SUPPORTED_NFT[0],
    })
    .then((res) => res)
    .catch((e) => e);
};

export const get_nft_assets_paged = async (
  contract,
  current_page_index,
  limit = 10
) => {
  return await contract?.account
    ?.viewFunction(contract?.contractId, "get_nft_assets_paged", {
      nft_contract_id: SUPPORTED_NFT[0],
      from_index: current_page_index,
      limit: limit,
    })
    .then((res) => res)
    .catch((e) => e);
};

export const isUserRegisterToken = async (
  contractMain: any,
  walletState: any,
  tokenId: string
) => {
  try {
    return await contractMain.account
      .viewFunction(
        tokenId,
        "storage_balance_of",
        {
          account_id: walletState.getAccountId(),
        },
        GAS,
        ONE_OCTO
      )
      .then((res: any) => res);
  } catch (err) {
    // console.log(err);
    return null;
  }
};

export const claimFreeToken = async (
  contractMain: any,
  walletState: any,
  amount: number,
  tokenId: string,
  tokenDecimals?: number
) => {
  try {
    const args = {
      account_id: walletState.getAccountId(),
      amount: amount.toLocaleString("fullwide", { useGrouping: false }),
    };

    if (tokenId === ENV_ID_TOKEN_wNEAR) {
      const near = await _near();
      const account = await near.account(walletState.getAccountId());
      const balance = await account.getAccountBalance();
      if (!balance) return;
      const f_available = Big(balance.available).sub(Big(20).pow(24));
      const f_fee = Big(Big(10).pow(23));
      const f_amount_swap = f_available.sub(f_fee).toNumber();
      await contractMain.account.functionCall(
        tokenId,
        "near_deposit", // contract method to deposit NEAR for wNEAR
        {},
        30000000000000, // attached gas
        f_amount_swap.toLocaleString("fullwide", { useGrouping: false }) // amount of NEAR to deposit and wrap
      );
    } else if (tokenId === ENV_ID_TOKEN_NEL) {
      await contractMain.account.functionCall(tokenId, "mint", {
        receiver_id: walletState.getAccountId(),
        amount: amount.toLocaleString("fullwide", { useGrouping: false }),
      });
    } else {
      await contractMain.account.functionCall(tokenId, "mint", args);
    }
  } catch (err) {
    console.error(err);
  }
};

export const handleRegisterToken = async (
  contractMain: any,
  walletState: any,
  tokenId: string
) => {
  try {
    const args = {
      account_id: walletState.getAccountId(),
      registration_only: true,
    };
    let $ONE_OCTO_STRING =
      tokenId === ENV_ID_TOKEN_wNEAR
        ? "1250000000000000000000"
        : ONE_OCTO_STRING;
    await contractMain.account
      .functionCall(tokenId, "storage_deposit", args, GAS, $ONE_OCTO_STRING)
      .then((res: any) => res);
  } catch (err) {
    console.error(err);
  }
};

export const handleDepositFirstTime = async function (
  contract: any,
  wallet: any
) {
  // const accountId = wallet.getAccountId();
  await contract.storage_deposit({}, GAS, ONE_OCTO_STRING);
};

export const handleDeposit = async function (
  token: any,
  amountToken: number,
  contract: any
) {
  try {
    const tokenID = token.tokenId || token.token_id;
    const tokenConfig = tokenFormat[tokenID];
    const amount = amountToken * 10 ** tokenConfig.contract_decimals;

    let amountToString = Number(amount).toLocaleString("fullwide", {
      useGrouping: false,
    });

    const real_number = handleLastDecimalReplaceByZero(
      amountToString,
      tokenConfig.contract_decimals,
      7
    );

    const contractID = contract.contractId;

    const msg = "";
    const args = {
      receiver_id: contractID,
      amount: real_number,
      msg,
    };

    return await contract.account.functionCall(
      tokenID,
      "ft_transfer_call",
      args,
      GAS,
      ONE_OCTO
    );
  } catch (error) {
    console.log(error);
  }
};

export const handleBorrow = async function (
  token: any,
  amountToken: number,
  contract: any
) {
  try {
    const tokenID = token.tokenId || token.token_id;
    const tokenConfig = tokenFormat[tokenID];
    const tokenUsdPrice = tokenFormat[tokenID].usd;
    if (amountToken * tokenUsdPrice < MINIMUM_DOLLAR_IS_ALLOW) return;
    const amount =
      Number(amountToken) *
      10 ** (tokenConfig.extra_decimals + tokenConfig.contract_decimals);
    let amountToString = amount.toLocaleString("fullwide", {
      useGrouping: false,
    });

    const real_number = handleLastDecimalReplaceByZero(
      amountToString,
      tokenConfig.extra_decimals + tokenConfig.contract_decimals
    );

    const contractID = contract.contractId;
    const args = {
      receiver_id: contractID,
      asset_ids,
      msg: `{"Execute": {"actions": [{"Borrow": {"token_id": "${tokenID}", "amount": "${real_number}"}},{"Withdraw": {"token_id": "${tokenID}","max_amount":"${real_number}"}}]}}`,
    };

    return await contract.account.functionCall(
      ENV_ID_ORACLE_CONTRACT,
      "oracle_call",
      args,
      GAS,
      ONE_OCTO
    );
  } catch (error) {
    console.error(error);
  }
};

export const handleWithdraw = async function (
  token: any,
  amountToken: number,
  contract: any
) {
  try {
    const tokenID = token.tokenId || token.token_id;
    const tokenConfig = tokenFormat[tokenID];
    const amount =
      amountToken *
      10 ** (tokenConfig.extra_decimals + tokenConfig.contract_decimals);
    let amountToString = amount.toLocaleString("fullwide", {
      useGrouping: false,
    });

    const contractID = contract.contractId;

    const real_number = handleLastDecimalReplaceByZero(
      amountToString,
      tokenConfig.extra_decimals + tokenConfig.contract_decimals
    );

    const args = {
      receiver_id: contractID,
      asset_ids,
      // msg: `{"Execute": {"actions": [{"DecreaseCollateral": {"token_id": "${tokenID}", "max_amount": "${amountToString}"}},{"Withdraw": {"token_id": "${tokenID}", "max_amount": "${amountToString}"}}]}}`,
      msg: `{"Execute": {"actions": [{"Withdraw": {"token_id": "${tokenID}", "max_amount": "${real_number}"}}]}}`,
    };

    return await contract.account.functionCall(
      ENV_ID_ORACLE_CONTRACT,
      "oracle_call",
      args,
      GAS,
      ONE_OCTO
    );
  } catch (error) {
    console.error(error);
  }
};

export const handleWithdrawNft = async function (
  contract: any,
  nft_contract_id: string,
  nft_token_id: string
) {
  try {
    const contractID = contract.contractId;
    const args = {
      receiver_id: contractID,
      asset_ids,
      msg: `{"Execute": {"actions": [{"WithdrawNFT": {"nft_contract_id": "${nft_contract_id}", "token_id": "${nft_token_id}"}}]}}`,
    };

    return await contract.account.functionCall(
      ENV_ID_ORACLE_CONTRACT,
      "oracle_call",
      args,
      GAS,
      ONE_OCTO
    );
  } catch (error) {
    console.error(error);
  }
};

export const handleDecreaseCollateral = async function (
  token: any,
  amountToken: number,
  contract: any
) {
  try {
    const tokenID = token.tokenId || token.token_id;
    const tokenConfig = tokenFormat[tokenID];
    const amount =
      amountToken *
      10 ** (tokenConfig.extra_decimals + tokenConfig.contract_decimals);
    let amountToString = amount.toLocaleString("fullwide", {
      useGrouping: false,
    });

    const args = {
      receiver_id: ENV_ID_MAIN_CONTRACT,
      asset_ids,
      msg: `{"Execute": {"actions": [{"DecreaseCollateral": {"token_id": "${tokenID}", "amount": "${amountToString}"}}]}}`,
    };

    return await contract.account.functionCall(
      ENV_ID_ORACLE_CONTRACT,
      "oracle_call",
      args,
      GAS,
      ONE_OCTO
    );
  } catch (error) {
    console.error(error);
  }
};

export const handleRepay = async function (
  token: any,
  amountToken: number,
  contract: any
) {
  try {
    const tokenID = token.tokenId || token.token_id;
    const tokenConfig = tokenFormat[tokenID];
    const decimal = tokenConfig.contract_decimals;
    const extraDecimal = tokenConfig.extra_decimals;
    const amount = amountToken * 10 ** decimal;

    let amountToString = amount.toLocaleString("fullwide", {
      useGrouping: false,
    });

    const real_amount = removeAfterPoint(amountToString);

    const real_max_mount = real_amount + generateZeroDecimal(extraDecimal);

    const args = {
      receiver_id: ENV_ID_MAIN_CONTRACT,
      amount: real_amount,
      msg: `{"Execute": {"actions": [{"Repay": {"token_id": "${tokenID}", "max_amount": "${real_max_mount}"}}]}}`,
    };

    return await contract.account.functionCall(
      tokenID,
      "ft_transfer_call",
      args,
      GAS,
      ONE_OCTO
    );
  } catch (error) {
    console.error(error);
  }
};

export const handleIncreaseCollateral = async function (
  token: any,
  amountToken: number,
  contract: any
) {
  try {
    const tokenID = token.tokenId || token.token_id;
    const tokenConfig = tokenFormat[tokenID];
    const amount = amountToken * 10 ** tokenConfig.contract_decimals;
    let amountToString = amount.toLocaleString("fullwide", {
      useGrouping: false,
    });
    const contractID = contract.contractId;
    const args = {
      actions: [
        {
          IncreaseCollateral: {
            token_id: tokenID,
            amount: amountToString,
          },
        },
      ],
    };

    return await contract.account.functionCall(
      contractID,
      "execute",
      args,
      GAS,
      ONE_OCTO
    );
  } catch (error) {
    console.error(error);
  }
};

export const handleGetUserTokenBalance = async (
  tokenId: string,
  tokenDecimals: number,
  contractMain: any,
  walletState: any
): Promise<number> => {
  try {
    const account_id =
      contractMain?.account.accountId || walletState.getAccountId();
    const balance = await contractMain?.account.viewFunction(
      tokenId,
      "ft_balance_of",
      {
        account_id: account_id,
      }
    );

    const real_number = handleLastDecimalReplaceByZero(balance, tokenDecimals);
    return new Big(real_number).div(new Big(10).pow(tokenDecimals)).toNumber();
  } catch (err) {
    console.error(err);
    return 0;
  }
};

export const handleClaimAllRewards = async (contract_main: any) => {
  try {
    const args = {};

    const res = await contract_main.account.functionCall(
      contract_main.contractId,
      "account_farm_claim_all",
      args
    );

    return res;
  } catch (err) {
    console.error(err);
    return 0;
  }
};

export const handleGetListContractFt = async (
  contractMain: any,
  walletState: any
) => {
  const account_id =
    contractMain?.account.accountId || walletState.getAccountId();
  const data = await fetch(
    `${INDEXER_SERVICE_URL}/account/${account_id}/likelyTokens`
  )
    // We get the API response and receive data in JSON format
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => console.error(error));
  let rs = [];
  let index = 0;
  for (let i = 0; i < data.length; i++) {
    if (tokenFormat[data[i].toString()]) {
      rs[index] = data[i];
      index++;
    }
  }
  return rs;
};

export const handleGetConfigToken = async (
  contractMain: any,
  walletState: any,
  contractIds: any
) => {
  try {
    const config = contractMain?.account
      .viewFunction(contractMain?.contractId, "get_assets", {
        token_ids: contractIds,
      })
      .then((res: any) => {
        return res[0].config;
      })
      .catch((err: any) => console.log(err));
    return config;
  } catch (err) {
    console.error(err);
    return 0;
  }
};

export const handleGetUserAssetToken = async (
  contractMain: any,
  walletState: any,
  contractId: any
) => {
  try {
    const account_id =
      contractMain?.account.accountId || walletState.getAccountId();
    const tokens = await contractMain?.account.viewFunction(
      contractId,
      "ft_balance_of",
      {
        account_id,
      }
    );

    return tokens;
  } catch (err) {
    console.error(err);
    return 0;
  }
};

export const handleGetUserAssetNfts = async (
  contractMain: any,
  walletState: any,
  contractId: any
) => {
  try {
    if (!contractMain || !walletState) return;
    const account_id =
      contractMain?.account?.accountId || walletState?.getAccountId();
    const nfts = await contractMain?.account.viewFunction(
      contractId,
      "nft_tokens_for_owner",
      {
        account_id,
        limit: 10,
      }
    );

    return nfts;
  } catch (err) {
    console.error(err);
    return 0;
  }
};

export const handleDepositNft = async (
  contract_main: any,
  contract_nft_id: any,
  nft_item: any
) => {
  try {
    const { token_id } = nft_item;
    const args = {
      token_id,
      receiver_id: contract_main.contractId,
      approval_id: 0,
      memo: "memo",
      msg: "",
    };
    return await contract_main.account.functionCall(
      contract_nft_id,
      "nft_transfer_call",
      args,
      GAS,
      ONE_OCTO
    );
  } catch (err) {
    console.error(err);
    return 0;
  }
};

export const handleGetMetadataNft = async (
  contract_main: any,
  contract_nft_id: any,
  nft_token_id: string
) => {
  try {
    const nfts = await contract_main?.account.viewFunction(
      contract_nft_id,
      "nft_token",
      {
        token_id: nft_token_id,
      }
    );

    return nfts;
  } catch (err) {
    console.error(err);
    return 0;
  }
};

export const handleGetNearBalance = async (accountId: string) => {
  const near = await _near();
  const account = await near.account(accountId);
  const balance = await account.getAccountBalance();
  return Number(balance.available) / 10 ** 24;
};

export const handleGetUserNFT = async (contract_main, wallet) => {
  const ASSET_NFT_CONTRACTS = [ENV_ID_NFT_LANGBIANG];
  try {
    let nfts: any = [];
    if (!contract_main || !wallet) return;
    for (let i = 0; i < ASSET_NFT_CONTRACTS.length; i++) {
      const nftI = await handleGetUserAssetNfts(
        contract_main,
        wallet,
        ASSET_NFT_CONTRACTS[i]
      );
      nftI.forEach((nft: any) => {
        if (tokenFormat?.[ASSET_NFT_CONTRACTS[i]]) {
          nft["contractName"] = ASSET_NFT_CONTRACTS[i];
          nfts.push(nft);
        }
      });
    }
    return nfts;
  } catch (err) {
    console.error(err);
  }
};
/*
 @Example Code
 @##### DO NOT DELETE
 @##### DO NOT DELETE
 @##### DO NOT DELETE
*/
// 1 - deposit to pool for first time User login
// await contract.storage_deposit(
//   {
//     account_id: accountId,
//     registration_only: true,
//   },
//   GAS,
//   "100000000000000000000000",
// );

// 2 - deposit to pool for first time User login
// await contract.account.functionCall(
//   contract.contractId,
//   "storage_deposit",
//   {
//     account_id: accountId,
//     registration_only: true,
//   },
//   GAS,
//   "100000000000000000000000"
// );
