import { AppContext } from "Contexts/AppContext";
import { useContext } from "react";
import useFunctionContract from 'hooks/useFunctionContract';

type Props = {
  setTurnOff: Function;
  tokenId?: string;
  textTitle?: string;
  token?: any;
};

const RegisterFirstTime = ({
  textTitle = "Please register general liquidity pool!",
  setTurnOff,
}: Props) => {
  const { handleDepositFirstTime } = useFunctionContract();

  const _handleTurnOff = () => {
    setTurnOff();
  };
  const _handleDeposit = () => {
    handleDepositFirstTime();
  };

  return (
    <div className="wrap-popup">
      <div className="popup notification">
        <h4 className="text-notification">{textTitle}</h4>
        {/* <p className="text-notification">
          Fee: <strong>0.2 NEAR</strong>
        </p> */}
        <div className="wrap-button">
          <button
            className="button-notification button-gray"
            onClick={_handleTurnOff}
          >
            Close
          </button>
          <button className="button-notification" onClick={_handleDeposit}>
            Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterFirstTime;
