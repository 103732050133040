import { useCallback, useEffect, useMemo, useState } from "react";
import logo_nel_transparent from "images/new_nel.png";

interface IProps {
  url: string;
}

const useImageExist = ({ url }: IProps) => {
  const [isImageExist, setIsImageExist] = useState(false);

  const exists = useCallback(async (url) => {
    try {
      const result = await fetch(url, { method: "HEAD" });
      setIsImageExist(result.ok);
    } catch (e) {
      return null;
    }
  }, []);

  useEffect(() => {
    exists(url);
  }, [exists, url]);

  const image = useMemo(() => {
    if (!url) return logo_nel_transparent;
    return isImageExist ? url : logo_nel_transparent;
  }, [isImageExist, url]);

  return { image };
};

export default useImageExist;
