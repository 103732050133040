/* eslint-disable @typescript-eslint/no-explicit-any */
import { ENV_ID_MAIN_CONTRACT, ENV_ID_NETWORK } from 'utils/constant';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { map, distinctUntilChanged } from 'rxjs';
import { NetworkId, setupWalletSelector } from '@near-wallet-selector/core';
import type { WalletSelector, AccountState } from '@near-wallet-selector/core';
import { setupModal } from '@near-wallet-selector/modal-ui';
import type { WalletSelectorModal } from '@near-wallet-selector/modal-ui';
import { setupMyNearWallet } from '@near-wallet-selector/my-near-wallet';
import { setupOKXWallet } from '@near-wallet-selector/okx-wallet';
import { setupSender } from '@near-wallet-selector/sender';
import { setupHereWallet } from '@near-wallet-selector/here-wallet';
import { setupMeteorWallet } from '@near-wallet-selector/meteor-wallet';
import { setupMintbaseWallet } from '@near-wallet-selector/mintbase-wallet';
import { setupLedger } from '@near-wallet-selector/ledger';

declare global {
  interface Window {
    selector: WalletSelector;
    modal: WalletSelectorModal;
  }
}

interface WalletSelectorContextValue {
  selector: WalletSelector | any;
  modal: WalletSelectorModal | any;
  accounts: Array<AccountState> | any;
  accountId: string | null;
}

const _initData = {
  selector: {},
  modal: {},
  accounts: [],
  accountId: '',
};

const WalletSelectorContext = React.createContext<WalletSelectorContextValue>(_initData);

export const WalletSelectorContextProvider = ({ children }: { children: any }) => {
  const [selector, setSelector] = useState<WalletSelector | null>(null);
  const [modal, setModal] = useState<WalletSelectorModal | null>(null);
  const [accounts, setAccounts] = useState<Array<AccountState>>([]);

  const init = useCallback(async () => {
    const _selector = await setupWalletSelector({
      network: ENV_ID_NETWORK as NetworkId,
      debug: true,
      modules: [
        setupMyNearWallet(),
        setupOKXWallet(),
        setupSender(),
        setupHereWallet(),
        setupMeteorWallet(),
        setupMintbaseWallet(),
        setupLedger()
      ],
    });
    // console.log('selector: ', _selector);
    const _modal = setupModal(_selector, { contractId: ENV_ID_MAIN_CONTRACT as unknown as string });
    const state = _selector.store.getState();
    // console.log('state: ', state);
    setAccounts(state.accounts);

    window.selector = _selector;
    window.modal = _modal;

    setSelector(_selector);
    setModal(_modal);
  }, []);

  useEffect(() => {
    console.log('init wallet selector');
    init().catch((err) => {
      console.error(err);
        // alert('Failed to initialise wallet selector');
    });
  }, [init]);

  useEffect(() => {
    if (!selector) {
      return;
    }

    // console.log('Before sub, selector:', selector);
    const subscription = selector.store.observable
      .pipe(
        map((state: any) => state.accounts),
        distinctUntilChanged()
      )
      .subscribe((nextAccounts: any) => {
        // console.log('Accounts Update', nextAccounts);
        if (localStorage.getItem("switch")) {
          localStorage.removeItem("switch");
          window?.location?.reload();
        }
        setAccounts(nextAccounts);
      });

    return () => subscription.unsubscribe();
  }, [selector]);

  if (!selector || !modal) {
    return null;
  }

  // console.log('accounts: ', accounts);
  const accountId = accounts.find((account) => account.active)?.accountId || null;

  return (
    <WalletSelectorContext.Provider
      value={{
        selector,
        modal,
        accounts,
        accountId,
      }}
    >
      {children}
    </WalletSelectorContext.Provider>
  );
};

export function useWalletSelector() {
  const context = useContext(WalletSelectorContext);

  if (!context) {
    console.log('Failed wallet connect !');
  }

  return context;
}
