/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
// Your enum definitions here

export enum EAction {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  ON_LOADING = 'ON_LOADING',
  OFF_LOADING = 'OFF_LOADING',
  TOGGLE_MODAL = 'TOGGLE_MODAL',
  INIT_CONTRACT = 'INIT_CONTRACT',
  INIT_CONTRACT_ORACLE = 'INIT_CONTRACT_ORACLE',
  INIT_WALLET = 'INIT_WALLET',
  INIT_NEAR = 'INIT_NEAR',
  INIT_USER_BALANCE = 'INIT_USER_BALANCE',
  OPEN_MODAL = 'OPEN_MODAL',
}

export enum EModal {
  NONE = 'NONE'
}

enum ProblemEnum {
  // Check this enum for any duplicate values
  // or malformed definitions
}