import TokenItem from "./TokenItem"
function TokenList({ranks, page, pageSize}) {
  return (
    <div className="list-item">
      <div className="header-item">
        <div>Rank</div>
        <div>Name</div>
        <div>Tier</div>
        <div>Shards</div>
      </div>
      {ranks.map((item, idx) => (
        <TokenItem key={idx} item={item} idx={idx} rank={idx+1+(page-1)*pageSize} />
      ))}
      
    </div>
  )
}

export default TokenList