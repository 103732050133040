import "./style.scss";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Icos, WrappSvg } from "components/footer";
import { useNavigate } from "react-router-dom";
import { AppContext } from "Contexts/AppContext";
import { useWalletSelector } from 'Contexts/WalletContext';

import { Rewards } from "components/liquidity/components/Reward";
import { RewardOtherToken } from "components/liquidity/components/RewardOtherToken";
import { RequireLogin } from "components/popup";

import { SUPPORTED_TOKENS, ENV_ID_TOKEN_NEL, tokenFormat } from "utils/constant";

import useFunctionContract from 'hooks/useFunctionContract';
import useCurrentToken from "hooks/useCurrentToken";
import { notification } from "antd";
import LoginRequest from "components/common/LoginRequest";
import SocialFooter from "components/common/SocialFooter";
import { formatAccountName } from "utils/common";
import rewardImg from "images/reward.svg";
import reportbug_img from "images/reportbug-btn.png";
import testnet_img from "images/testnet.png";
import beta_img from "images/betanet.png";
import { Tooltip } from "antd";

export const Liquidity = () => {
  const { handleRegisterToken, isUserRegisterToken, claimAll } = useFunctionContract();
  const navigate = useNavigate();
  const intervalRef = useRef<any>();
  const {
    contract,
    setUserProfile,
    setIsLoading,
    isLogin,
    profile: {
      userAssetFarms: { borrowed, supplied },
    },
  } = useContext(AppContext);

  const { accountId } = useWalletSelector() || {};

  const [tokenRegister, setTokenRegister] = useState("");
  const [isShowRegister, setIsShowRegister] = useState(false);
  const { tokenExtraDecimals, tokenContractDecimals } =
  useCurrentToken({
    token_id: ENV_ID_TOKEN_NEL,
  });

  const _handleToggleRegister = () => {
    setIsShowRegister(!isShowRegister);
  };

  const _openNotification = (hash: string) => {
    notification.success({
      style: { top: 80, background: '#041205', color: '#ffffff' },
      message: "Claim successfully ! 🚀",
      description: (
        <div>
          View on Nearblocks: <a href={`https://testnet.nearblocks.io/txns/${hash}`} target='_blank'>{formatAccountName(hash)}</a>
        </div>
      )
    });
  };

  const _getUserBalance = useCallback(async () => {
    if (!contract || !accountId) return;
    try {
      const user = await contract.get_account({
        account_id: accountId,
      });

      if (!user) return;

      const {
        account_id,
        borrowed,
        has_non_farmed_assets,
        nft_supplied,
        supplied,
      } = user || {};

      const farmSupplyAssets = user.farms
        .filter((item) => item.farm_id.Supplied)
        .map((item) => ({ ...item, farm_id: item.farm_id.Supplied }));

      const farmBorrowAssets = user.farms
        .filter((item) => item.farm_id.Borrowed)
        .map((item) => ({ ...item, farm_id: item.farm_id.Borrowed }));

      setUserProfile((prev) => ({
        ...prev,
        profile: {
          ...prev.profile,
          userBalance: {
            account_id,
            borrowed,
            has_non_farmed_assets,
            nft_supplied,
            supplied,
          },
          userAssetFarms: {
            ...prev.profile.userAssetFarms,
            supplied: farmSupplyAssets,
            borrowed: farmBorrowAssets,
          },
        },
      }));
    } catch (error) {
      console.log(error);
    }
  }, [accountId, contract, setUserProfile]);

  const _actionClaimAll = async () => {
    try {
      setIsLoading(true);
      const res = await claimAll(contract);
      if (res.status.hasOwnProperty("SuccessValue")) {
        _getUserBalance();
        setIsLoading(false);
        _openNotification(res.transaction.hash);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const _checkValidBeforeClaimAll = async () => {
    const all_supplied = [...supplied, ...borrowed];
    const nel_rewards = all_supplied
      .map((item) => {
        return [...item.rewards];
      })
      .flat()
      .filter((reward) => reward.reward_token_id === ENV_ID_TOKEN_NEL);
    
    const pow_decimals = Math.pow(
        10,
        tokenExtraDecimals + tokenContractDecimals
      );
    const nel_unclaimed_amount =
      nel_rewards.reduce((acc, curr) => {
        return acc + Number(curr.unclaimed_amount);
      }, 0) / pow_decimals;
    if (nel_unclaimed_amount <= 0) {
      notification.error({
        style: { top: 80, background: '#041205', color: '#ffffff' },
        message: "Insufficient available $NELD"
      })
      return
    }

    const list_check_token = SUPPORTED_TOKENS;

    let token_need_to_register = "";
    for (const token_id of list_check_token) {
      const check_token = await isUserRegisterToken(token_id)
        .then((res) => {
          if (!res) return token_id;
          return "";
        })
        .catch((e) => {
          return "";
        });

      if (check_token) {
        token_need_to_register = check_token;
        break;
      }
    }

    if (token_need_to_register) {
      setTokenRegister(token_need_to_register);
      _handleToggleRegister();
    } else {
      await _actionClaimAll();
    }
    return token_need_to_register;
  };

  const _handleClaimAll = async () => {
    try {
      await _checkValidBeforeClaimAll();
    } catch (e) {
      setIsLoading(false);
    }
  };

  const _goToDeposit = () => navigate("/app");

  const getRewardRealtime = useCallback(async () => {
    intervalRef.current = setInterval(_getUserBalance, 2000);
  }, [_getUserBalance]);

  const _handleRegisterToken = async () => {
    if (!tokenRegister) return;
    await handleRegisterToken(tokenRegister);
  };

  useEffect(() => {
    getRewardRealtime();
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [getRewardRealtime, _getUserBalance]);

  return (
    <div className="liquidation-container">
      <div className={`container ${isLogin ? '' : 'no-login'}`}>
      {isLogin ? (
        <>
        <div className="content">
          <h4 className="claim-title">Claimable Rewards</h4>
          <div className="reward-info">
            <Rewards />
          </div>
          <div className="btn-groups">
            {borrowed.length || supplied.length ? (
              <button
                className={`claim-all-button common-button btn`}
                onClick={_handleClaimAll}
              >
                Claim All
              </button>
            ) : (
              <button
                className={`claim-all-button common-button btn`}
                onClick={_goToDeposit}
              >
                Go to Deposit
              </button>
            )}
            {isShowRegister && (
              <RequireLogin
                textTitle={`Please register "${
                  tokenFormat[tokenRegister]?.symbol || tokenRegister
                } Token" before claiming!`}
                textConfirm={"Accept"}
                textCancel={"Cancel"}
                togglePopup={_handleToggleRegister}
                handleConfirm={_handleRegisterToken}
              />
            )}
          </div>
        </div>
        <img src={rewardImg} />
        </>
        ) : (
          <LoginRequest />
        )}
      </div>
      <div className="icons-bottom" style={{position: "absolute", bottom: 0, maxWidth: "1200px", margin: "0 auto"}}>
      <div className="test-net-button">
          <a
            href="#"
          >
            <Tooltip
              zIndex={109999}
              arrowPointAtCenter
              color="#0000006b"
              title="We are in Beta Mode. It means that platform is live and we are constantly fixing minor bugs, improving the performance and adding new features. We really appreciate your support and feedback. Thank you!"
            >
              <img
                alt="Beta"
                src={beta_img}
                width={133}
                height={40}
              />
            </Tooltip>
          </a>
          <a
            target={"_blank"}
            href="https://docs.google.com/forms/d/e/1FAIpQLSe0FXd1GHjEaP0-KkIrGE5iVYvIhS9itprCiyHzuks1Nwx1GQ/viewform"
            rel="noreferrer"
          >
            <img
              alt="Report bug to Nearlend DAO"
              src={reportbug_img}
              width={133}
              height={40}
            />
          </a>
        </div>
        <div className="icons-wrapper">
          {Icos.map((item, idx) => (
            <WrappSvg
              key={idx}
              href={item.path}
              target="_blank"
              rel="noreferrer"
              className="icon-item"
            >
              {item.src}
            </WrappSvg>
          ))}
        </div>
      </div>
    </div>
  );
};
