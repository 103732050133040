import { AppContext } from "Contexts/AppContext";
import React, { useContext } from "react";
import useFunctionContract from 'hooks/useFunctionContract';
// type TProps = {
//   mess?: string;
// };

const LoginRequest = () => {
  const { wallet } = useContext(AppContext);

  const { handleNearLogin } = useFunctionContract();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        cursor: "pointer",
        fontSize: 20,
        height: "calc(100vh - 224px)",
        zIndex: 3
      }}
    >
      <p onClick={handleNearLogin}>
        Please <span className="n-highlight">connect</span> wallet!
      </p>
    </div>
  );
};

export default LoginRequest;
