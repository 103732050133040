import React, { useState, useContext, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
// import type { PaginationProps } from "antd";
import { useQueryClient } from "react-query";
import { AppContext } from "Contexts/AppContext";

import { countMinimumDollarOfTokenList } from "utils/common";
import { QUERY_KEY } from "utils/constant";
import { TTokenFormat } from "types/token";
// import NearPagination from "components/common/Pagination";

import BalanceBorrowItem from "components/myBalance/balance-borrow/BalanceBorrowItem";
import { COLORs } from "utils/colors";

const BalanceBorrow = ({ borrowed }: any) => {
  const queryClient = useQueryClient();
  const { profile, poolTokenList } = useContext(AppContext);

  const [isShowBorrowList, setIsShowBorrowList] = useState(true);
  const [data, setData] = useState([]);

  // const [paginAssetSetting, setPaginAssetSetting] = useState({
  //   total: 12,
  //   current: 1,
  // });

  // const handlePaginAssetOnchange: PaginationProps["onChange"] = (page) => {
  //   setPaginAssetSetting((prev) => ({ ...prev, current: page }));
  // };

  const handleShowBorrowList = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      QUERY_KEY.GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;

    const lengthLowerMinimumDollar = countMinimumDollarOfTokenList(
      "borrowed",
      profile?.userBalance?.borrowed,
      getNewFormatToken,
      poolTokenList
    );

    if (profile?.userBalance?.borrowed.length === lengthLowerMinimumDollar) {
      setIsShowBorrowList(false);
      setData([]);
      return;
    }

    const rs = profile?.userBalance?.borrowed;
    if (!rs || !rs.length) {
      setIsShowBorrowList(false);
    } else {
      setIsShowBorrowList(true);
    }
    setData(rs);
  }, [poolTokenList, profile?.userBalance?.borrowed, queryClient]);

  useEffect(() => {
    handleShowBorrowList();
  }, [handleShowBorrowList]);

  return (
    <div className="borrow-tab" style={{ width: "49%" }}>
      <h3 className="big-title">
        <span style={{ color: COLORs.YELLOW }}>Borrowed</span> assets
      </h3>
      <div className="token-list">
        <div className="side">
          <div className="detail">
            <div className="label label__title">
              <p className="title">Asset</p>
              {/* <p className="title">NELD Emission</p> */}
              <p className="title" style={{textAlign: "left"}}>Borrowed</p>
              <p className="title" style={{textAlign: "center", width: "100px"}}>APY</p>
              {/* <p className="title">Borrowed Interest</p> */}
              <p className="title" style={{textAlign: "center"}}>Actions</p>
            </div>
            {isShowBorrowList ? (
              data?.map(
                (item: any, idx: number) =>
                  item && <BalanceBorrowItem key={idx} currentToken={item} />
              )
            ) : (
              <div className="empty-account-line">
                <p>
                Not Available. Please head to <Link to="/app">Market</Link>!
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <NearPagination
      current={paginAssetSetting?.current}
      total={paginAssetSetting?.total}
      paginOnchange={handlePaginAssetOnchange}
    /> */}
    </div>
  );
};

export default BalanceBorrow;
