import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { COLORs } from "utils/colors";

import { Logo } from "components/common";
import { Container } from "components/common/Container";

import { ReactComponent as DiscordIco } from "images/discord.svg";
import { ReactComponent as MediumIco } from "images/medium.svg";
import { ReactComponent as TeleIco } from "images/telegram.svg";
import { ReactComponent as TwiterIco } from "images/twiter.svg";
import { ReactComponent as XIco } from "images/x.svg";
import { ReactComponent as ZIco } from "images/z.svg";
import { exceptHeaderPath, ROUTE_COLLECTION } from "utils/constant";

function Footer() {
  const { pathname, search } = useLocation();

  const noneFooter = exceptHeaderPath.includes(pathname);

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return noneFooter ? null : (
    <FooterWrapper>
      <CustomContainer className="mobile-footer-only">
        <div className="footer-wrapper-composition">
          <WrapperCenterCol
            style={{
              flex: "1 1",
              display: "flex",
              justifyContent: "start",
            }}
          >
            <FlexStyle
              style={{ flexDirection: "column", alignItems: "start", gap: 10 }}
              className="mobile-flex-only"
            >
              <TitleFooter>About us</TitleFooter>
              <CustomFlexComp
                style={{ alignItems: "flex-start" }}
                className="about-us-mobile-only"
              >
                <div className="menu-footer">
                  <a
                    href='https://www.nft.nearlenddao.com/'
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Text>Lang Biang</Text>
                  </a>
                  <a
                    target="_blank"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSeYvGsMBsg9vjKyuGrSRmTjbmKsGGZ5xu2JNvZS1aqLUzUQiQ/viewform"
                    rel="noreferrer"
                  >
                    <Text>Private Sale</Text>
                  </a>
                </div>
                <div className="menu-footer">
                  <a
                    target="_blank"
                    href="https://nearlend-dao.gitbook.io/nearlend-dao/"
                    rel="noreferrer"
                  >
                    <Text>Gitbook</Text>
                  </a>
                  <a
                    target="_blank"
                    href="https://github.com/nearlend-dao"
                    rel="noreferrer"
                  >
                    <Text>Github</Text>
                  </a>
                </div>
                <div className="menu-footer">
                  <a
                    target="_blank"
                    href="mailto:support@nearlenddao.com"
                    rel="noreferrer"
                  >
                    <Text>Email</Text>
                  </a>
                  <a
                    target="_blank"
                    href="https://t.me/nearlendlabs"
                    rel="noreferrer"
                  >
                    <Text>Telegram</Text>
                  </a>
                </div>
              </CustomFlexComp>
            </FlexStyle>
          </WrapperCenterCol>
          <div style={{ flex: "1 1" }} onClick={topFunction}>
            <Logo gap="10" isCustom />
          </div>
          <ColLast>
            <FlexStyle
              style={{
                flexDirection: "column",
                alignItems: "start",
                gap: 20,
                width: "auto",
              }}
              className="mobile-flex-only"
            >
              <TitleFooter>community</TitleFooter>
              <div style={{ alignItems: "center" }}>
                <FlexStyle style={{ gap: 15, width: "auto" }}>
                  {Icos.map((item, idx) => (
                    <WrappSvg
                      key={idx}
                      href={item.path}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.src}
                    </WrappSvg>
                  ))}
                </FlexStyle>
              </div>
              <Des>Inspired by the ideas of Defi, Web3 & DAO</Des>
            </FlexStyle>
          </ColLast>
        </div>
      </CustomContainer>
    </FooterWrapper>
  );
}

export default Footer;

const ColLast = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const CustomContainer = styled(Container)`
  padding: 40px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const Des = styled.h3`
  color: #ffffff;
  opacity: 0.3;
  font-size: 14px;
`;

const TitleFooter = styled.h3`
  color: ${COLORs.GREEN_ROW};
  text-transform: capitalize;
  font-weight: 700;
  font-size: 16px;
`;

export const Icos = [
  {
    src: <XIco style={{ width: 20, height: 20 }} />,
    path: "https://twitter.com/NearlendDao",
    label: "Twitter",
    local: null,
  },
  {
    src: <TeleIco style={{ width: 20, height: 20 }} />,
    path: "https://t.me/nearlenddao",
    label: "Telegram",
    local: null,
  },
  {
    src: <DiscordIco style={{ width: 20, height: 20 }} />,
    path: "https://discord.com/invite/Nvny8hUjwG",
    label: "Discord",
    local: null,
  },
  {
    src: <MediumIco style={{ width: 20, height: 20 }} />,
    srcSocial: true,
    path: "https://mirror.xyz/0x45c6B7f94F476Ba89A8C9E9E318B7402922B93Be",
    label: "Medium",
    local: null,
  },
];

const WrapperCenterCol = styled.div`
  position: relative;
`;

export const WrappSvg = styled.a`
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  //path {
  //  fill: white;
  //  opacity: 0.9;
  //}
  &:hover {
    path {
      opacity: 1;
    }
  }
`;

const Text = styled.p`
  margin-bottom: 18px;
  white-space: nowrap;
`;

const FooterWrapper = styled.div`
  color: white;
`;

const FlexStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const CustomFlexComp = styled(FlexStyle)`
  .menu-footer {
    > a {
      display: flex;
      flex-direction: column;
      p {
        color: rgba(255, 255, 255, 0.6);
        transition: all 0.3s ease-in-out;
        position: relative;
        display: inline-block;
        &:before {
          content: "";
          position: absolute;
          bottom: 2px;
          width: 0;
          height: 1px;
          // border-bottom: 1px solid ${COLORs.GREEN_LIGHT};
          transition: all 0.3s ease-in-out;
        }
      }
      &:hover {
        p {
          color: ${COLORs.GREEN_LIGHT};
          font-weight: bold;
          &:before {
            width: 100%;
          }
        }
      }
    }
  }
`;
