import { AppContext } from "Contexts/AppContext";
import { useCallback, useContext } from "react";
import { handleGetNearBalance } from "utils/connect/contract";
import { useWalletSelector } from "Contexts/WalletContext";
import { ERROR, NEAR_APPROVE_TRANSACTION } from "utils/constant";

const useError = () => {
  const { contract } = useContext(AppContext);
  const { accountId } = useWalletSelector() || {};

  const errorApproveTransaction = useCallback(async () => {
    if (!contract) return "";

    const near_user_balance = await handleGetNearBalance(accountId);
    if (near_user_balance < NEAR_APPROVE_TRANSACTION)
      return ERROR.APPROVE_TRANSACTION;

    return "";
  }, [contract]);

  return { errorApproveTransaction };
};

export default useError;
