import BannerUI from "components/homepage/Banner";
import InvesterUI from "components/homepage/Invester";
import SolutionUI from "components/homepage/Solution";
import TokenomicUI from "components/homepage/Tokenomic";
import Image from "components/common/Images";
import { MainWrapper, TopWrapper } from "./styles";

import AOS from "aos";
import { useEffect } from "react";
import styled from "styled-components";
import { useWindowDimensions } from "hooks/useWindowSize";
import { mobileBreakPoint } from "utils/constant";
import bgOverView2 from "images/bgOverView2.svg";

const OpeningPage = () => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    AOS.init({
      duration: 1500,
      disable: width < mobileBreakPoint,
    });
    return () => AOS.refresh();
  }, [width]);

  return (
    <MainWrapper className="mobile-homepage-only">
      <TopWrapperCustomer>
        {/* <Image width={40} src={bgOverView2} /> */}
        <BannerUI />
        <SolutionUI />
      </TopWrapperCustomer>
      <Wrapper>
        {/*<TokenomicUI />*/}
        <InvesterUI />
      </Wrapper>
    </MainWrapper>
  );
};

export default OpeningPage;

const Wrapper = styled.div`
  position: relative;
`;
const TopWrapperCustomer = styled(TopWrapper)`
  &::after {
    background-image: url(${bgOverView2});
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
