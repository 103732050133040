import { useContext } from "react";
import { AppContext } from "Contexts/AppContext";
import useFunctionContract from 'hooks/useFunctionContract';

type Props = {
  tokenId?: string;
  token?: any;
  textTitle?: string;
  textConfirm?: string;
  textCancel?: string;
  togglePopup?: any;
  handleConfirm?: any;
};

const RequireLogin = ({
  togglePopup,
  textTitle,
  textConfirm,
  textCancel,
  handleConfirm,
}: Props) => {
  const { wallet }: any = useContext(AppContext);

  const { handleNearLogin } = useFunctionContract();
  const _handleTogglePopup = () => {
    togglePopup();
  };

  return (
    <div className="wrap-popup">
      <div className="popup notification">
        <h4 className="text-notification">
          {`${textTitle ?? "Please connect wallet!"} `}
        </h4>
        <div className="wrap-button">
          <button
            className="button-notification button-gray btn-login-popup"
            onClick={_handleTogglePopup}
          >
            {`${textCancel ?? "Close"} `}
          </button>
          <button
            className="button-notification btn-login-popup"
            onClick={handleConfirm ?? handleNearLogin}
          >
            {`${textConfirm ?? "Connect Wallet"} `}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequireLogin;
