import { createRoot } from 'react-dom/client';
import App from "./App";
import "./styles/index.css?v=4";
import "aos/dist/aos.css";
import '@near-wallet-selector/modal-ui/styles.css';

import reportWebVitals from "./reportWebVitals";
import buffer from "buffer";
global.Buffer = buffer.Buffer;

const rootElement = document.getElementById("root");

const root = createRoot(rootElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
