import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { formatBalanceWithDecimal, formatCashToView, getNumberExactly } from "utils/common";
import {
  ENV_ID_TOKEN_USDT_E,
  ENV_ID_TOKEN_wNEAR,
  MINIMUM_DOLLAR_IS_ALLOW,
  QUERY_KEY,
  tokenFormat,
} from "utils/constant";
import { TTokenFormat } from "types/token";
import useCurrentToken from "hooks/useCurrentToken";

import logo_nel_transparent from "images/new_nel.png";
import { get_asset_farms_all } from "utils/connect/contract";
import { AppContext } from "Contexts/AppContext";
import { ENV_ID_TOKEN_ETH} from "utils/constant";

import Big from "big.js";

type TArrayReward = { [key: string]: number }[];

function TokenItem({
  item,
  actions: { openPopupDeposit, openPopupBorrow, handleTogglePopupRequire },
}: any) {
  const initInterval = useRef<any>();
  const queryClient = useQueryClient();
  const { tokenId } = item;

  const { contract } = useContext(AppContext);
  const { tokenExtraDecimals, tokenContractDecimals, supply_apr, borrow_apr } =
    useCurrentToken(item);

  const icon = tokenFormat[tokenId.toString()]?.icon;
  const tokenSymbol = tokenFormat[tokenId.toString()]?.symbol;
  const tokenDecimals: number = tokenExtraDecimals + tokenContractDecimals;
  const [tokenUsd, setTokenUsd] = useState(0);
  const [netTotalApr, setApr] = useState([]);
  const [rewards, setRewards] = useState([]);
  const supplied: any = getNumberExactly(item, "supplied");
    // formatBalanceWithDecimal(item?.supplied?.balance, tokenDecimals) || 0;

  const reserved: any = getNumberExactly(item, "reserved")
  // formatBalanceWithDecimal(item?.reserved, tokenDecimals);

  const totalSupplied: any = Number(supplied.add(reserved).toString());

  const borrowed: any = getNumberExactly(item, "borrowed")
  // const borrowed: any = formatBalanceWithDecimal(
  //   item?.borrowed.balance,
  //   tokenDecimals
  // );

  const _calculate = useCallback(() => {
    const getNewFormatToken = queryClient.getQueryData(
      QUERY_KEY.GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;

    if (!getNewFormatToken) return;
    setTokenUsd(getNewFormatToken[tokenId].usd);
  }, [queryClient, tokenId]);

  const _initCalculate = useCallback(() => {
    initInterval.current = setInterval(_calculate, 400);
    return () => {
      clearInterval(initInterval.current);
    };
  }, [_calculate]);

  useEffect(() => {
    _initCalculate();
    return () => {
      clearInterval(initInterval.current);
    };
  }, [_initCalculate]);

  const _getAssetFarm = useCallback(async () => {
    try {
      const rs = await get_asset_farms_all(contract);
      const getNewFormatToken = queryClient.getQueryData(
        QUERY_KEY.GET_FORMAT_TOKEN
      ) as unknown as TTokenFormat;

      const reward_by_token_id = rs?.filter(
        (item) => item[0].Supplied === tokenId || item[0].Borrowed === tokenId
      );

      const _reduce_reward = (acc, curr) => {
        const obj_reward = curr[1].rewards;
        for (const property in obj_reward) {
          acc.push({
            [property]: Big(obj_reward[property].reward_per_day)
              .div(
                Big(10).pow(
                  getNewFormatToken[property].contract_decimals +
                    getNewFormatToken[property].extra_decimals
                )
              )
              .toNumber(),
          });
        }
        return acc;
      };

      if (reward_by_token_id) {
        const get_token_reward_by_supply: TArrayReward = reward_by_token_id
        .filter((item) => item[0].Supplied)
        .reduce(_reduce_reward, []);

        const get_token_reward_by_borrow: TArrayReward = reward_by_token_id
          .filter((item) => item[0].Borrowed)
          .reduce(_reduce_reward, []);
        const merged_token_reward: TArrayReward = [
          ...get_token_reward_by_borrow,
          ...get_token_reward_by_supply,
          // { "dai.fakes.testnet": 20000 },
          // { "dai.fakes.testnet": 40000 },
          // { "weth.fakes.testnet": 40000000 },
          // { "ft.nearlend-official.testnet": 9000 },
        ];
        const output: { [key: string]: number } = merged_token_reward.reduce(
          (acc, curr) => {
            const current_id = Object.keys(curr)[0];
            if (acc[current_id]) {
              acc[current_id] = acc[current_id] + curr[current_id];
            } else {
              acc[Object.keys(curr)[0]] = curr[current_id];
            }
            return acc;
          },
          {}
        );
  
        const final_reward = [];
        for (const [key, value] of Object.entries(output)) {
          if (value * getNewFormatToken[key].usd > MINIMUM_DOLLAR_IS_ALLOW) {
            final_reward.push({
              symbol_reward: getNewFormatToken[key].symbol || "N/A",
              icon_reward: getNewFormatToken[key].icon || logo_nel_transparent,
              id_reward: key,
              reward_per_day: value,
              reward_per_day_usdt: value * getNewFormatToken[key].usd
            });
            // console.log(key, value, getNewFormatToken[key].usd)
          }
        }
  
        setRewards(final_reward);
      } else {
        setRewards([]);
      }
    } catch (e) {
      setRewards([]);
      console.log(e);
    }
  }, [contract, queryClient, tokenId]);

  useEffect(() => {
    _getAssetFarm();
  }, [_getAssetFarm]);

  useEffect(() => {
    if (totalSupplied > 0 && tokenUsd > 0) {
      let aprArr = []
      for (let i = 0; i < rewards.length; i++) {
        aprArr[i] = rewards[i].reward_per_day_usdt / (totalSupplied * tokenUsd) * 100 * 365 + supply_apr
      }
      setApr(aprArr)
    }
  }, [rewards, supply_apr, totalSupplied, tokenUsd])

  return (
    <div data-cy={`${tokenId}`} className="wrap-pool">
      {/* <button
        className="button-claim button-claim-outside"
        onClick={() => handleTogglePopupRequire(item)}
      >
        {tokenId === ENV_ID_TOKEN_wNEAR ? "Swap" : "Claim"}
      </button> */}
      <div className="mini asset market-flex">
        <img className={`icon ${(tokenId == ENV_ID_TOKEN_USDT_E ? 'no-bg' : '')}`} src={icon} width={32} height={32} alt="Logo" />
        <div className="coin-des">
          <p className="top coin color-white fwb">{tokenSymbol}</p>
          <p className="color-space-gray">
            ${tokenUsd.toFixed(4).slice(0, -2)}
          </p>
        </div>
      </div>
      {/* <div className="mini btn-claim hover-reward mini-custom">
        <img
          src={logo_nel_transparent}
          className="logo_nel_transparent"
          width={20}
          height={20}
          alt="logo_nel_transparent"
        />
      </div> */}
      <div className="mini deposit">
        <p className="top color-white fwb text-right">
          {formatCashToView(totalSupplied, 8, true)}
        </p>
        <p className="color-space-gray text-right">
          $
          {formatCashToView(Number(supplied.add(reserved).mul(tokenUsd)), 8, true)}
        </p>
      </div>

      <div className="mini deposit on-desktop">
        <p className="top color-white fwb text-right">
          {/* {Number(formatCash(borrowed as unknown as number)).toFixed(2)} */}
          {formatCashToView(Number(borrowed), 8, true)}
        </p>
        <p className="color-space-gray text-right">
          $
          {formatCashToView(Number(borrowed.mul(tokenUsd)), 8, true)}
          {/* {formatCashToView(
            Number((borrowed * tokenUsd).toFixed(4).slice(0, -2)),
            8
          )} */}
        </p>
      </div>
      <div
        data-cy="cy-button-deposit"
        className="action color-white on-desktop update-v2"
      >
        <div className="market-flex apy">
          {netTotalApr.length > 0 ? (
            netTotalApr.map((apr, idx) => (
              <p key={idx}>{apr.toFixed(8).slice(0, -6)}%</p>
            ))
          ) : (
            <p>{supply_apr}%</p>
          )}
          <div className="board-reward">
          <div className="wrap-reward">
            <div className="reward">
              <div className="reward-name">
                <p>Base APY</p>
              </div>
              <div className="reward-per-day">
                <p>{supply_apr}%</p>
              </div>
            </div>
            {rewards.length ? rewards.map((item, idx) => (
              <>
                <div key={`reward-${idx}`} className="reward">
                  <div className="reward-name">
                    <p>NELD Emissions</p>
                  </div>
                  <div className="reward-per-day">
                    {netTotalApr.length > 0 ? (
                      netTotalApr.map((apr, idx) => (
                        <p key={idx}>{(apr-supply_apr).toFixed(8).slice(0, -6)}%</p>
                      ))
                    ) : (
                      <p>0%</p>
                    )}
                  </div>
                </div>
              </>
            )) : 
              <div className="reward">
                <div className="reward-name">
                  <p>NELD Emissions</p>
                </div>
                <div className="reward-per-day">
                  <p>0%</p>
                </div>
              </div>
            }
            <div className="reward">
              <div className="reward-name">
                <p>Total APY</p>
              </div>
              <div className="reward-per-day">
                {netTotalApr.length > 0 ? (
                  netTotalApr.map((apr, idx) => (
                    <p key={idx}>{apr.toFixed(8).slice(0, -6)}%</p>
                  ))
                ) : (
                  <p>{supply_apr}%</p>
                )}
              </div>
            </div>
          </div>
          </div>
        </div>
        <button className="btn-base button-basic-deposit" onClick={(e) => openPopupDeposit(e, item)}>Deposit</button>
      </div>
      <div
        onClick={(e) => openPopupBorrow(e, item)}
        className="action color-white on-desktop update-v2"
      >
        <div className="market-flex apy">
          <p>{borrow_apr}%</p>
        </div>
        <button className="btn-base button-basic-borrow">Borrow</button>
      </div>
    </div>
  );
}

export default TokenItem;
