import useImageExist from "hooks/useImageExist";
import { langbiangLabelSplitting } from "utils/common";
interface CollectProps {
  imgUrl: string;
  label: string;
  id: number;
}

export const CollectionComponent = (props: CollectProps) => {
  const { imgUrl, label, id } = props;
  const { image } = useImageExist({ url: imgUrl });

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
        color: "whitesmoke",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: 40,
          height: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
        }}
      >
        <img
          style={{
            maxWidth: "100%",
            width: "100%",
            height: "100%",
            borderRadius: "100%",
          }}
          src={image}
          alt="nft_url"
        />
      </div>
      <div>
        <div
          className="font-weight-600"
          style={{ textTransform: "capitalize" }}
        >
          {langbiangLabelSplitting(label).tokenLabel}
        </div>
        <div className="hight-2-light-tokenID font-weight-600">
          #{id}
        </div>
        {/* <div>
          <span className="hightlight-label">ID#:</span>{" "}
          <span className="sub-value">{id}</span>
        </div> */}
      </div>
    </div>
  );
};
