import { useEffect, useState } from "react";
import { formatCashToView, formatAccountName, strimInt } from "utils/common";
function TokenItem({ item, idx, rank }) {
  const [tier, setTier] = useState('')
  useEffect(() => {
    if (rank > 0) {
      let ti = Math.floor(rank / 100)
      if (rank % 100) {
        ti += 1
      }
      setTier(`Tier ${ti}`)
    }
  }, [rank])
  return (
    <div className={`item top-${rank} ${(rank > 3 && rank % 2) ? 'light': ''}`}>
      <div>{rank}</div>
      <div>
        <a href={`${process.env.REACT_APP_EXPLORER_URL}/address/${item.accountId}`} target="_blank">{formatAccountName(item.accountId)}</a>
      </div>
      <div>{tier}</div>
      <div>
        {strimInt(formatCashToView(item.rewardPoints, 8, true).toString())}
      </div>
    </div>
  )
}

export default TokenItem