/* eslint-disable @typescript-eslint/no-explicit-any */
import Big from "big.js";
import { AppContext } from "Contexts/AppContext";
import { useWalletSelector } from '../Contexts/WalletContext';
import { EAction, EModal } from 'define/enum';
import { providers } from 'near-api-js';
import { CodeResult } from 'near-api-js/lib/providers/provider';
import { useCallback, useContext, useEffect } from 'react';
import {
  injectHtmlToWallet,
  handleLastDecimalReplaceByZero,
  floorNumber,
  removeAfterPoint,
  generateZeroDecimal
} from 'utils/common';
import {
  ENV_ID_MAIN_CONTRACT,
  ENV_ID_ORACLE_CONTRACT,
  GAS,
  ONE_OCTO,
  ONE_OCTO_STRING,
  ENV_ID_TOKEN_wNEAR,
  tokenFormat,
  ENV_ID_NFT_LANGBIANG,
  SUPPORTED_TOKENS,
  MINIMUM_DOLLAR_IS_ALLOW,
  SUPPORTED_NFT
} from 'utils/constant';

const INDEXER_SERVICE_URL =  'https://api.kitwallet.app'; //process.env.REACT_APP_INDEXER_SERVICE_URL;
const useFunctionContract = () => {
  // const { wallet, isLogin, dispatch } = useContext(StateContext);
  const { isLogin, wallet, dispatch } = useContext(AppContext);
  const { selector, modal, accountId } = useWalletSelector();

  const asset_ids = [...SUPPORTED_TOKENS, ...SUPPORTED_NFT];

  const _viewMethod = useCallback(
    ({ contractId, method, args = {} }) => {
      if (!selector) return;
      const { network } = selector.options;
      const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });

      return provider
        .query<CodeResult>({
          request_type: 'call_function',
          account_id: contractId,
          method_name: method,
          args_base64: Buffer.from(JSON.stringify(args)).toString('base64'),
          finality: 'optimistic',
        })
        .then((res) => JSON.parse(Buffer.from(res.result).toString()))
        .catch((e) => {} /*console.info('eeeee > > ', e, { contractId, method, args })*/);
    },
    [selector]
  );
  
  const _callMethod = useCallback(
    async ({ contractId, method, args = {}, gas = GAS.toString(), deposit = ONE_OCTO_STRING, isReload = true }) => {
      const wallet = await selector.wallet();
      dispatch({ type: EAction.TOGGLE_MODAL, payload: EModal.NONE });
      setTimeout(() => {
        dispatch({ type: EAction.ON_LOADING });
      }, 500);
      try {
        const outcome = (await wallet.signAndSendTransaction({
          signerId: accountId as string,
          receiverId: contractId,
          actions: [
            {
              type: 'FunctionCall',
              params: {
                methodName: method,
                args,
                gas,
                deposit,
              },
            },
          ],
        })) as unknown as providers.FinalExecutionOutcome;

        const _isSuccess =
          outcome.transaction_outcome.outcome.status.hasOwnProperty('SuccessReceiptId') ||
          outcome.transaction_outcome.outcome.status.hasOwnProperty('SuccessValue');

        if (_isSuccess) {
          dispatch({ type: EAction.OFF_LOADING });
          if (isReload) {
            window.location.replace(window.location.origin + window.location.pathname);
          } else {
            return outcome;
          }
        } else {
          dispatch({ type: EAction.OFF_LOADING });
        }

        return providers.getTransactionLastResult(outcome);
      } catch (e) {
        dispatch({ type: EAction.OFF_LOADING });
      }
    },
    [accountId, dispatch, selector]
  );
  
  const _callDoubleMethod = useCallback(
    async ({ contractId, method, args = {}, gas = GAS.toString(), deposit = ONE_OCTO_STRING, isReload = true }) => {
      const wallet = await selector.wallet();
      dispatch({ type: EAction.TOGGLE_MODAL, payload: EModal.NONE });
      setTimeout(() => {
        dispatch({ type: EAction.ON_LOADING });
      }, 500);
      try {
        const outcome = (await wallet.signAndSendTransactions({
          transactions: [
            {
              signerId: accountId as string,
              receiverId: contractId,
              actions: [
                {
                  type: 'FunctionCall',
                  params: {
                    methodName: method,
                    args,
                    gas,
                    deposit,
                  },
                },
              ],
            },
            {
              signerId: accountId as string,
              receiverId: contractId,
              actions: [
                {
                  type: 'FunctionCall',
                  params: {
                    methodName: method,
                    args,
                    gas,
                    deposit,
                  },
                },
              ],
            }
          ],
        })) as unknown as providers.FinalExecutionOutcome;

        const _isSuccess =
          outcome.transaction_outcome.outcome.status.hasOwnProperty('SuccessReceiptId') ||
          outcome.transaction_outcome.outcome.status.hasOwnProperty('SuccessValue');

        if (_isSuccess) {
          dispatch({ type: EAction.OFF_LOADING });
          if (isReload) {
            window.location.replace(window.location.origin + window.location.pathname);
          } else {
            return outcome;
          }
        } else {
          dispatch({ type: EAction.OFF_LOADING });
        }

        return providers.getTransactionLastResult(outcome);
      } catch (e) {
        dispatch({ type: EAction.OFF_LOADING });
      }
    },
    [accountId, dispatch, selector]
  );

  const handleNearLogOut = useCallback(async () => {
    try {
      if (!selector) return;
      const wallet = await selector.wallet();
      await wallet.signOut();

      const disclaimer = localStorage.getItem("disclaimer")
      localStorage.clear();
      if (disclaimer) {
        localStorage.setItem("disclaimer", "shown")
      }
      if (window.location.pathname.includes("asset")) {
        window.location.replace('/app');
      } else {
        window.location.replace(window.location.origin + window.location.pathname);
      }
    } catch (e) {
      console.log(e);
    }
  }, [selector]);

  const handleNearSwitch = useCallback(async () => {
    try {
      modal.show();
      injectHtmlToWallet();
      localStorage.setItem("switch", "on")
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleNearLogin = useCallback(async () => {
    try {
      if (!wallet || isLogin) {
        return;
      }

      modal.show();
      injectHtmlToWallet();
    } catch (e) {
      console.log('near login > > ', e);
    }
  }, [isLogin, modal, wallet]);

  const handleGetAccountId = useCallback(() => {
    if (!wallet) {
      return '';
    }
    return wallet?.getAccountId() || '';
  }, [wallet]);

  const _validIsUserLogin = useCallback(() => {
    if (!wallet || !selector?.isSignedIn()) {
      return dispatch({ type: EAction.LOGOUT });
    }
    return dispatch({ type: EAction.LOGIN });
  }, [dispatch, selector, wallet]);

  //functionCall
  const handleRegisterToken = useCallback(async (tokenId: string) => {
    try {
      const args = {
        account_id: accountId,
        registration_only: true,
      };
      let $ONE_OCTO_STRING =
        tokenId === ENV_ID_TOKEN_wNEAR
          ? "1250000000000000000000"
          : ONE_OCTO_STRING;
      return await _callMethod({
        contractId: tokenId,
        method: "storage_deposit",
        args,
        gas: GAS as unknown as string,
        deposit: $ONE_OCTO_STRING
      })
    } catch (err) {
      console.error(err);
    }
  }, [_callMethod, accountId]);

  const handleWithdraw = useCallback(async (
    token: any,
    amountToken: number,
    contract: any
  ) => {
    try {
      const tokenID = token.tokenId || token.token_id;
      const tokenConfig = tokenFormat[tokenID];
      const amountTokenFloor = floorNumber(amountToken, tokenID);
      const amount =
        amountTokenFloor *
        10 ** (tokenConfig.extra_decimals + tokenConfig.contract_decimals);
      let amountToString = amount.toLocaleString("fullwide", {
        useGrouping: false,
      });
  
      const contractID = contract.contractId;
  
      const real_number = handleLastDecimalReplaceByZero(
        amountToString,
        tokenConfig.extra_decimals + tokenConfig.contract_decimals
      );
  
      const args = {
        receiver_id: contractID,
        asset_ids,
        msg: `{"Execute": {"actions": [{"Withdraw": {"token_id": "${tokenID}", "max_amount": "${real_number}"}}]}}`,
      };
  
      return _callMethod({
        contractId: ENV_ID_ORACLE_CONTRACT,
        method: "oracle_call",
        args,
        gas: GAS as unknown as string,
        deposit: ONE_OCTO as unknown as string
      });
    } catch (error) {
      console.error(error);
    }
  }, [_callMethod, accountId]);

  const handleWithdrawNft = useCallback(async (
    contract: any,
    nftContractId: string,
    nftTokenId: string
  ) => {
    try {
      const contractID = contract.contractId;
      const args = {
        receiver_id: contractID,
        asset_ids,
        msg: `{"Execute": {"actions": [{"WithdrawNFT": {"nft_contract_id": "${nftContractId}", "token_id": "${nftTokenId}"}}]}}`,
      };
  
      return await _callMethod({
        contractId: ENV_ID_ORACLE_CONTRACT,
        method: "oracle_call",
        args,
        gas: GAS as unknown as string,
        deposit: ONE_OCTO as unknown as string
      })
    } catch (error) {
      console.error(error);
    }
  }, [_callMethod, accountId]);
  
  const handleDepositFirstTime = useCallback(async () => {
    try {
      return await _callDoubleMethod({
        contractId: ENV_ID_MAIN_CONTRACT as string,
        method: 'storage_deposit',
        deposit: ONE_OCTO_STRING
      })
    } catch (err) {
      console.error(err);
    }
  }, [_callMethod, accountId]);
  
  const handleDeposit = useCallback(async (
    token: any,
    amountToken: number,
    contract: any
  ) => {
    try {
      const tokenID = token.tokenId || token.token_id;
      const tokenConfig = tokenFormat[tokenID];
      const amount = amountToken * 10 ** tokenConfig.contract_decimals;
  
      let amountToString = Number(amount).toLocaleString("fullwide", {
        useGrouping: false,
      });
  
      const real_number = handleLastDecimalReplaceByZero(
        amountToString,
        tokenConfig.contract_decimals,
        7
      );
  
  
      const msg = "";
      const args = {
        receiver_id: contract.contractId,
        amount: real_number,
        msg,
      };
  
      return _callMethod({
        contractId: tokenID,
        method: "ft_transfer_call",
        args,
        gas: GAS as unknown as string,
        deposit: ONE_OCTO as unknown as string
      })
    } catch (error) {
      console.log(error);
    }
  }, [_callMethod, accountId]);

  const handleDepositNft = useCallback(async (
    contract: any,
    contractNftId: any,
    nftItem: any
  ) => {
    try {
      const { token_id } = nftItem;
      const args = {
        token_id,
        receiver_id: contract.contractId,
        approval_id: 0,
        memo: "memo",
        msg: "",
      };
      return _callMethod({
        contractId: contractNftId,
        method: "nft_transfer_call",
        args,
        gas: GAS as unknown as string,
        deposit: ONE_OCTO as unknown as string
      })
    } catch (err) {
      console.error(err);
      return 0;
    }
  }, [_callMethod, accountId]);

  const handleBorrow = useCallback(async (
    token: any,
    amountToken: number,
    contract: any
  ) => {
    try {
      const tokenID = token.tokenId || token.token_id;
      const tokenConfig = tokenFormat[tokenID];
      const tokenUsdPrice = tokenFormat[tokenID].usd;
      if (amountToken * tokenUsdPrice < MINIMUM_DOLLAR_IS_ALLOW) return;
      const amount =
        Number(amountToken) *
        10 ** (tokenConfig.extra_decimals + tokenConfig.contract_decimals);
      let amountToString = amount.toLocaleString("fullwide", {
        useGrouping: false,
      });
  
      const real_number = handleLastDecimalReplaceByZero(
        amountToString,
        tokenConfig.extra_decimals + tokenConfig.contract_decimals
      );
  
      const contractID = contract.contractId;
      const args = {
        receiver_id: contractID,
        asset_ids,
        msg: `{"Execute": {"actions": [{"Borrow": {"token_id": "${tokenID}", "amount": "${real_number}"}},{"Withdraw": {"token_id": "${tokenID}","max_amount":"${real_number}"}}]}}`,
      };
  
      return _callMethod({
        contractId: ENV_ID_ORACLE_CONTRACT,
        method: "oracle_call",
        args,
        gas: GAS as unknown as string,
        deposit: ONE_OCTO as unknown as string
      });
    } catch (error) {
      console.error(error);
    }
  }, [_callMethod, accountId]);

  const handleRepay = useCallback(async (
    token: any,
    amountToken: number,
    contract: any
  ) => {
    try {
      const tokenID = token.tokenId || token.token_id;
      const tokenConfig = tokenFormat[tokenID];
      const decimal = tokenConfig.contract_decimals;
      const extraDecimal = tokenConfig.extra_decimals;
      const amount = amountToken * 10 ** decimal;
  
      let amountToString = amount.toLocaleString("fullwide", {
        useGrouping: false,
      });
  
      const real_amount = removeAfterPoint(amountToString);
  
      const real_max_mount = real_amount + generateZeroDecimal(extraDecimal);
  
      const args = {
        receiver_id: ENV_ID_MAIN_CONTRACT,
        amount: real_amount,
        msg: `{"Execute": {"actions": [{"Repay": {"token_id": "${tokenID}", "max_amount": "${real_max_mount}"}}]}}`,
      };
  
      return _callMethod({
        contractId: tokenID,
        method: "ft_transfer_call",
        args,
        gas: GAS as unknown as string,
        deposit: ONE_OCTO as unknown as string
      });
    } catch (error) {
      console.error(error);
    }
  }, [_callMethod, accountId]);
  
  const claimAll = useCallback(async (contract: any) => {
    try {
      return await _callMethod({
        contractId: contract.contractId,
        method: "account_farm_claim_all",
        args: {},
        gas: GAS.toString(),
        deposit: '0',
        isReload: false
      });
    } catch (err) {
      console.error(err);
    }
  }, [_callMethod, accountId]);
  
  const isUserRegisterToken =  useCallback(async (
    tokenId: string
  ) => {
    try { 
      return await _viewMethod({
        contractId: tokenId,
        method: 'storage_balance_of',
        args: {
          account_id: accountId,
        }
      });
    } catch (err) {
      console.error(err);
    }
  }, [_viewMethod, accountId]);

  const getAccount = useCallback(async () => {
    try {
      const args = {
        account_id: accountId,
      };
          
      return await _viewMethod({
        contractId: ENV_ID_MAIN_CONTRACT as string,
        method: 'get_account',
        args
      });
    } catch (err) {
      console.error(err);
    }
  }, [_viewMethod, accountId]);
  
  const handleGetUserTokenBalance =  useCallback(async (
    contractMain: any,
    tokenId: string,
    tokenDecimals: number,
  ) => {
    try {
      const balance = await _viewMethod({
        contractId: tokenId as string,
        method: "ft_balance_of",
        args: {
          account_id: accountId,
        }
      })
  
      const real_number = handleLastDecimalReplaceByZero(balance, tokenDecimals);
      return new Big(real_number).div(new Big(10).pow(tokenDecimals)).toNumber();
    } catch (err) {
      console.error(err);
      return 0;
    }
  }, [_viewMethod, accountId]);

  const handleGetListContractFt =  useCallback(async () => {
    try {
      const data = await fetch(
        `${INDEXER_SERVICE_URL}/account/${accountId}/likelyTokens`
      )
        // We get the API response and receive data in JSON format
        .then((response) => response.json())
        .then((data) => {
          return data;
        })
        .catch((error) => console.error(error));
      let rs = [];
      let index = 0;
      for (let i = 0; i < data.length; i++) {
        if (tokenFormat[data[i].toString()]) {
          rs[index] = data[i];
          index++;
        }
      }
      return rs;
    } catch (err) {
      console.error(err);
    }
  }, [_callMethod, accountId]);
  
  const handleGetUserAssetNfts =  useCallback(async (contractId: any) => {
    try {
      const account_id = accountId;
      const nfts = await _viewMethod({
        contractId,
        method: "nft_tokens_for_owner",
        args: {
          account_id,
          limit: 10,
        }
      })

      return nfts;
    } catch (err) {
      console.error(err);
    }
  }, [_viewMethod, accountId]);

  const handleGetUserNFT = useCallback(async (contractMain: any) => {
    const ASSET_NFT_CONTRACTS = [ENV_ID_NFT_LANGBIANG];
    try {
      let nfts: any = [];
      if (!contractMain) return;
      for (let i = 0; i < ASSET_NFT_CONTRACTS.length; i++) {
        const nftI = await handleGetUserAssetNfts(
          ASSET_NFT_CONTRACTS[i]
        );
        if (nftI && nftI.length) {
          nftI.forEach((nft: any) => {
            if (tokenFormat?.[ASSET_NFT_CONTRACTS[i]]) {
              nft["contractName"] = ASSET_NFT_CONTRACTS[i];
              nfts.push(nft);
            }
          });
        }
      }
      return nfts;
    } catch (err) {
      console.error(err);
    }
  }, [accountId]);

  const handleGetUserAssetToken = useCallback(async (contractMain: any, contractId: any) => {
    try {
      const account_id = accountId;
      const tokens = await _viewMethod({
        contractId,
        method: 'ft_balance_of',
        args: {
          account_id
        }
      })
  
      return tokens;
    } catch (err) {
      console.error(err);
      return 0;
    }
  }, [accountId]);

  //viewFunction
  const handleCheckIsRegister = useCallback(async () => {
    try {
      const is_register = await _viewMethod({
        contractId: ENV_ID_MAIN_CONTRACT as string,
        method: 'storage_balance_of',
        args: { account_id: accountId }
      });
      if (is_register === '0' || is_register === null) {
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  }, [_viewMethod, accountId]);

  useEffect(() => {
    _validIsUserLogin();
  }, [_validIsUserLogin, accountId]);

  return {
    handleNearLogOut,
    handleNearLogin,
    handleNearSwitch,
    handleRegisterToken,
    handleCheckIsRegister,
    isUserRegisterToken,
    handleGetListContractFt,
    handleGetUserAssetNfts,
    handleGetUserNFT,
    handleGetUserAssetToken,
    handleGetAccountId,
    handleGetUserTokenBalance,
    claimAll,
    handleDepositFirstTime,
    handleDeposit,
    getAccount,
    handleWithdraw,
    handleBorrow,
    handleWithdrawNft,
    handleDepositNft,
    handleRepay
  };
};

export default useFunctionContract;
