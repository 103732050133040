import AssetTokenItem from "components/asset/AssetTokenItem";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "Contexts/AppContext";
import useFunctionContract from 'hooks/useFunctionContract';
import { ENV_ID_TOKEN_wNEAR, tokenFormat } from "../../utils/constant";

function AssetTokenList() {
  const { handleGetListContractFt } = useFunctionContract();
  const { contract, wallet } = useContext(AppContext);
  const [lstContracts, setLstContracts] = useState([]);
  // const [];
  const _init = useCallback(async () => {
    const rs = await handleGetListContractFt();
    setLstContracts(rs);
  }, [contract, wallet]);
  useEffect(() => {
    _init();
  }, [_init]);

  return (
    <>
      {lstContracts && lstContracts.length > 0 ? (
        <>
          <AssetTokenItem item={ENV_ID_TOKEN_wNEAR} only_near={true} />
          {lstContracts.map((item, idx: number) => {
            if (!item || !tokenFormat[item.toString()]) return null;
            return <AssetTokenItem key={item} item={item} />;
          })}
        </>
      ) : (
        <tr className="empty-line">
          <td colSpan={2}>No Data</td>
        </tr>
      )}
    </>
  );
}

export default AssetTokenList;
