import React, { useState, createContext, useReducer, useEffect } from "react";
import { ChildrenProps, IAppContext } from "utils/interfaces";
import { TPoolTokenList } from "utils/types";
import {
  Auth,
  UserBalance,
  ContractMain,
  Wallet,
  PoolTokenList,
  PoolNftList,
} from "Contexts/init";
import { Spin } from "antd";
import nel_reward from "images/new_logo/header.svg";
import loading from "images/loading.svg";
import { routeManager } from "routes";
import { EAction, EModal } from 'define/enum';
import { IAction, IContext, IState } from 'define/interface';

const {
  TOGGLE_MODAL,
  ON_LOADING,
  OFF_LOADING,
  LOGIN,
  LOGOUT
} = EAction;

function appReducer(state: IState, action: IAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ON_LOADING: {
      return { ...state, isLoading: true };
    }
    case OFF_LOADING: {
      return { ...state, isLoading: false };
    }
    case LOGIN: {
      return { ...state, isLogin: true };
    }
    case LOGOUT: {
      return { ...state, isLogin: false, userNearBalance: initState.userNearBalance };
    }
    case TOGGLE_MODAL: {
      return {
        ...state,
        nameOpenModal: payload as unknown as EModal,
      };
    }
    default:
      return state;
  }
}

const initState: IContext = {
  nameOpenModal: EModal.NONE,
  isLoading: true,
  isLogin: false,
  dispatch: () => {},
  isModalOpen: false,
  currentLotteryId: 1,
  userNearBalance: {
    available: '0',
    staked: '0',
    stateStaked: '0',
    total: '0',
  }
};

export const AppContext = createContext<IAppContext | any>({
  ...Auth,
  ...UserBalance,
  ...ContractMain,
  ...Wallet,
  ...PoolTokenList,
  ...PoolNftList,
  ...initState
});

const AppContextProvider = ({ children }: ChildrenProps) => {
  const [authParams, setAuthParams] = useState(Auth);
  const [profile, setUserProfile] = useState(UserBalance);
  const [contract, setContract] = useState(ContractMain);
  const [wallet, setWallet] = useState(Wallet);
  const [poolTokenList, setPoolTokenList] = useState<TPoolTokenList[]>();
  const [poolNftList, setPoolNftList] = useState<TPoolTokenList[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [mainMenu, setMainMenu] = useState(routeManager);
  const [state, dispatch] = useReducer(appReducer, initState);

  useEffect(() => {
    Promise.all(
      Array.from(document.images)
        .filter((img) => !img.complete)
        .map(
          (img) =>
            new Promise((resolve) => {
              img.onload = img.onerror = resolve;
            })
        )
    ).then(() => {
      console.log('all images finished loading');
      dispatch({ type: EAction.OFF_LOADING });
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        ...authParams,
        ...profile,
        ...state,
        contract,
        wallet,
        poolTokenList,
        poolNftList,
        mainMenu,
        setAuthParams,
        setUserProfile,
        setContract,
        setWallet,
        setPoolTokenList,
        setPoolNftList,
        setIsLoading,
        setMainMenu,
        dispatch,
      }}
    >
      <Spin
        size="small"
        spinning={isLoading}
        indicator={
          <div>
            <img
              className="loading-spin-icon"
              src={loading}
              alt="loading"
              width={125}
              height={125}
            />
            <p className="loading-text">Loading...</p>
          </div>
        }
        wrapperClassName="loading-spin-wrapper"
      >
        {children}
      </Spin>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
