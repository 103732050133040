import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Modal } from "antd";
import useFunctionContract from 'hooks/useFunctionContract';
import { AppContext } from "Contexts/AppContext";
import { useQueryClient } from "react-query";

import { ENV_ID_TOKEN_wNEAR, QUERY_KEY, SUPPORTED_NFT } from "utils/constant";
import { TTokenFormat } from "utils/types";
import { formatCashToView, totalMarketNft } from "utils/common";
// import { notification } from "antd";
import { Container } from "components/common/Container";
import "./style/nearModal.scss";
import { BodyModal, TitleModal } from "components/vaults/ModalVaults";
import { RegisterFirstTime, RequireLogin } from "components/popup";
import useNearDev from "hooks/useNearDev";

const TopBox = () => {
  const {
    contract,
    wallet,
    poolNftList,
    poolTokenList,
    setIsLoading,
    isLogin,
    profile,
  } = useContext(AppContext);
  const queryClient = useQueryClient();
  const { nearAccounts } = useNearDev();
  const { handleGetUserAssetNfts, handleDepositNft } = useFunctionContract();

  const [visible, setVisible] = useState(false);
  const [visibleRequirePopup, setVisibleRequirePopup] = useState(false);
  const [visibleRequireLoginPopup, setVisibleRequireLoginPopup] =
    useState(false);
  const [nftsData, setNftsData] = useState([]);
  const [amountNftDeposited, setAmountNftDeposited] = useState(0);
  const [totalLocked, setTotalLocked] = useState({
    dollar_amount: 0,
    near_amount: 0,
  });

  const getNewFormatToken = queryClient.getQueryData(
    QUERY_KEY.GET_FORMAT_TOKEN
  ) as unknown as TTokenFormat;

  const _initTotal = useCallback(() => {
    if (!poolNftList || !getNewFormatToken) return;
    const total = poolNftList.reduce((_, curr) => {
      let amount = 0;
      let cal = curr.nft_supplied.length;
      amount += cal;
      return amount as unknown as number;
    }, 0);

    setAmountNftDeposited(total || 0);
  }, [getNewFormatToken, poolNftList]);

  const _initTotalLockValue = useCallback(() => {
    if (!poolNftList || !getNewFormatToken) return;

    const deposited_nft = totalMarketNft(poolNftList, getNewFormatToken, false);

    const dollar_amount = deposited_nft;
    const near_amount =
      Number(dollar_amount) / getNewFormatToken[ENV_ID_TOKEN_wNEAR].usd;
    setTotalLocked((prev) => ({
      ...prev,
      dollar_amount,
      near_amount,
    }));
  }, [getNewFormatToken, poolNftList]);

  const showModal = () => {
    if (!isLogin) return _handleToggleRequireLoginPopup();
    if (profile?.userBalance === null) return _handleTogglePopupRegister();
    setVisible(true);
  };

  const _handleTogglePopupRegister = () => {
    setVisibleRequirePopup((prevState) => !prevState);
  };

  const _handleToggleRequireLoginPopup = () => {
    setVisibleRequireLoginPopup(!visibleRequireLoginPopup);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const handleTesting = (item: any) => {
    const contract_nft_id = item.contract_id;
    handleDepositNft(contract, contract_nft_id, item);
  };

  const _handleGetUserAssetNfts = useCallback(async () => {
    try {
      if (!contract) return;
      SUPPORTED_NFT.forEach(async (contractId, i) => {
        const nftI = await handleGetUserAssetNfts(contractId);
        if (!nftI) return setNftsData([]);
        const mod_nftI = nftI.map((nft: any) => {
          nft["contract_id"] = SUPPORTED_NFT[i];
          return nft;
        });
        setNftsData(mod_nftI);
      });
    } catch (err) {
      console.error(err);
    }
  }, [contract, wallet]);

  // const _getSeriesId = useCallback(async () => {
  //   return await fetch(api_series_id)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       return data;
  //     })
  //     .catch((error) => console.error(error));
  // }, []);

  // const _putSeriesId = useCallback(async (next_current_id) => {
  //   const requestOptions = {
  //     method: "PUT",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({ current_series_id: next_current_id }),
  //   };
  //   return await fetch(api_series_id, requestOptions);
  // }, []);

  // const _openNotiError = () => {
  //   notification.error({
  //     style: { top: 80 },
  //     message: "Claim fail ! 🙁",
  //     description: "Please ! Try again later ! ",
  //   });
  // };

  // const _openNotiSuccess = () => {
  //   notification.success({
  //     style: { top: 80 },
  //     message: "Claim successfully ! 🚀",
  //     description: "Please ! Check the assets at your wallet !",
  //   });
  // };

  // const _claimNFT = useCallback(async () => {
  //   setIsLoading(true);
  //   const series_id = await _getSeriesId();
  //   const next_series_id = series_id.current_series_id + 1;
  //   const args = {
  //     token_series_id: `${next_series_id}`,
  //     receiver_id: wallet?.getAccountId(),
  //   };

  //   return await nearAccounts
  //     .functionCall(SUPPORTED_NFT[0], "nft_mint", args, GAS, ONE_OCTO_STRING)
  //     .then(() => {
  //       setIsLoading(false);
  //       _openNotiSuccess();
  //       _putSeriesId(next_series_id);
  //     })
  //     .catch(() => {
  //       setIsLoading(false);
  //       _openNotiError();
  //     });
  // }, [_getSeriesId, _putSeriesId, nearAccounts, setIsLoading, wallet]);

  const checkClaim = useMemo(() => {
    if (nftsData.length >= 3 || profile?.userBalance?.nft_supplied.length >= 3)
      return false;
    return (
      isLogin &&
      nftsData.length + profile?.userBalance?.nft_supplied.length < 3
    );
  }, [isLogin, nftsData.length, profile?.userBalance?.nft_supplied.length]);

  useEffect(() => {
    _handleGetUserAssetNfts();
  }, [_handleGetUserAssetNfts]);

  useEffect(() => {
    _initTotal();
  }, [_initTotal]);

  useEffect(() => {
    _initTotalLockValue();
  }, [_initTotalLockValue]);

  return (
    <Container>
      {/* {checkClaim && (
        <p className="claim-nft">
          <a
            rel="noreferrer"
            href="https://marketplace-v2-testnet.paras.id/collection/nft.nearlend-official.testnet"
            target={"_blank"}
          >
            Claim NFT
          </a>
        </p>
      )} */}
      <div className="wrapper-top-box">
        <div onClick={showModal} className="top-box">
          My NFTs
        </div>
        <div className="top-box box-1">
          <div className="box-title">total value locked</div>
          <div className="box-content">
            <span className="main-value">
              ${formatCashToView(totalLocked.dollar_amount, 9)}
            </span>
          </div>
        </div>
        <div className="top-box box-2">
          <div className="box-title">Total NFT Deposited</div>
          <div>
            <span className="main-value">{amountNftDeposited} NFTs</span>
          </div>
        </div>
      </div>
      {visible && (
        <Modal
          className="near-modal"
          title={<TitleModal nftsData={nftsData} />}
          visible={visible}
          onCancel={handleCancel}
          footer={false}
          centered
        >
          <BodyModal onSubmitDeposit={handleTesting} nftsData={nftsData} />
        </Modal>
      )}
      {visibleRequirePopup && (
        <RegisterFirstTime setTurnOff={_handleTogglePopupRegister} />
      )}

      {visibleRequireLoginPopup && (
        <RequireLogin
          textTitle={"Please connect your wallet!"}
          togglePopup={_handleToggleRequireLoginPopup}
        />
      )}
    </Container>
  );
};

export default React.memo(TopBox);

const api_series_id =
  "https://6342885fba4478d4783d3ec3.mockapi.io/nftcounting/1";
