import * as nearAPI from 'near-api-js';
import {
  ENV_ID_NFT_LANGBIANG,
  ENV_ID_TOKEN_ETH,
  ENV_ID_TOKEN_NEL,
  ENV_ID_TOKEN_USDT,
  ENV_ID_TOKEN_wETH,
  ERROR,
  errorAvailable,
  FORMAT_CASH_DECIMAL,
  LAST_DECIMAL_REPLACE,
  MINIMUM_DOLLAR_IS_ALLOW,
  tokenFormat,
} from "utils/constant";
import { IToken, TTokenFormat } from "types/token";
import Big from "big.js";

export const markSlider = {
  0: "0%",
  25: "25%",
  50: "50%",
  75: "75%",
  100: "100%",
};
const { utils } = nearAPI;
const get_volatility_ratio = (pool, curr_token_id) => {
  if (!pool || !curr_token_id) return 1;
  const found = pool
    ? pool?.find((ele: any) => ele.tokenId === curr_token_id)
    : undefined;
  return found ? found.config.volatility_ratio / 10000 : 1;
};

export const shortName = (str: string) => {
  return str.slice(0, 6).toString() + ".." || "";
};

export const getUsdOfToken = (tokenId: string, usdTokens?: any): number => {
  const tokenName = tokenFormat[tokenId]?.name ?? "";
  const { usd } = usdTokens[tokenName] ?? 23;
  return usd;
};

export const totalBalance = (arrayOject: any, usdTokens?: any): number => {
  var result = arrayOject?.reduce((acc: any, item: any) => {
    const tokeDecimals =
      tokenFormat[item.token_id]?.contract_decimals +
      tokenFormat[item.token_id]?.extra_decimals;
    const tokenNameUsd = tokenFormat[item.token_id]?.nameUsd ?? "";
    const { usd } = usdTokens?.[tokenNameUsd] || { usd: 23 };

    if (!tokeDecimals) return acc;
    const bal = (item.balance / 10 ** tokeDecimals) * usd;
    return acc + bal;
  }, 0);
  return result ?? 0.0;
};

export const getTotalBalanceTokenPriceUSD = (
  type: "borrowed" | "collateral",
  arrayOject: {
    apy: string;
    balance: string;
    shares: string;
    token_id: string;
  }[],
  usdTokens?: TTokenFormat,
  pool?: any,
  is_ratio = true,
  by_token_id?: string
): number => {
  if (!usdTokens) return 0.0;
  let result = arrayOject?.reduce((acc: number, curr) => {
    if (by_token_id && curr.token_id !== by_token_id) return acc;
    const volatility_ratio = is_ratio
      ? get_volatility_ratio(pool, curr.token_id)
      : 1;
    if (!usdTokens?.[curr.token_id]) {
      return acc;
    }
    const tokeDecimals =
      usdTokens?.[curr.token_id]?.contract_decimals +
      usdTokens?.[curr.token_id]?.extra_decimals;

    const dollarPriceOfToken = usdTokens?.[curr.token_id]?.usd ?? 1;

    const balance = Big(curr.balance)
      .div(Big(10).pow(tokeDecimals))
      .mul(Big(dollarPriceOfToken));

    let bal = 0;
    type === "borrowed"
      ? (bal = balance.div(Big(volatility_ratio)).toNumber())
      : (bal = balance.mul(Big(volatility_ratio)).toNumber());

    const check_bal = bal < MINIMUM_DOLLAR_IS_ALLOW ? 0 : bal;
    return acc + check_bal;
  }, 0);

  return result ?? 0.0;
};

export const getTotalInterestPriceUSD = (
  type: "borrowed" | "collateral",
  arrayOject: {
    apy: string;
    balance: string;
    shares: string;
    token_id: string;
  }[],
  usdTokens?: TTokenFormat,
  apy?: any
): number => {
  if (!usdTokens) return 0.0;
  let result = arrayOject?.reduce((acc: number, curr) => {
    const { supply_apr, borrow_apr } = apy?.find(
      (a: any) => a.token_id === curr.token_id
    ) || { supply_apr: 1, borrow_apr: 1 };

    if (!usdTokens?.[curr.token_id]) {
      return acc;
    }

    const tokeDecimals =
      usdTokens?.[curr.token_id]?.contract_decimals +
      usdTokens?.[curr.token_id]?.extra_decimals;
    const dollarPriceOfToken = usdTokens?.[curr.token_id]?.usd || 1;

    let bal = 0;

    const to_dollar = Big(curr.balance)
      .div(Big(10).pow(tokeDecimals))
      .mul(Big(dollarPriceOfToken))
      .toNumber();

    const minimum_dollar_allow = to_dollar > MINIMUM_DOLLAR_IS_ALLOW;

    const check_type_apr = type === "borrowed" ? borrow_apr : supply_apr;

    minimum_dollar_allow
      ? (bal = Big(to_dollar)
          .mul(Big(check_type_apr / 100))
          .toNumber())
      : (bal = 0);

    return Big(acc).add(bal);
  }, 0);

  return Big(result).toNumber() ?? 0.0;
};

export const getTotalBalanceNftPriceUSD = (
  nftsDeposited,
  usdTokens?: TTokenFormat,
  pool?: any,
  isRatio = true
): number => {
  if (!usdTokens) return 0.0;
  let result = nftsDeposited?.reduce((acc: number, curr) => {
    const number_ratio = isRatio
      ? get_volatility_ratio(pool, curr.nft_contract_id)
      : 1;
    const dollarPriceOfToken = Big(usdTokens[curr.nft_contract_id].usd).mul(
      Big(number_ratio)
    );
    return Big(acc).add(dollarPriceOfToken).toNumber();
  }, 0);
  return result ?? 0.0;
};

export const getAvailableLiquidityToken = (
  currentToken: any,
  usdTokens: TTokenFormat
): number => {
  if (!usdTokens) return 0.0;
  const tokeDecimals =
    usdTokens?.[currentToken.token_id]?.contract_decimals +
    usdTokens?.[currentToken.token_id]?.extra_decimals;
  // const available =
  //   Number(currentToken.supplied.balance) +
  //   Number(currentToken.reserved) -
  //   Number(currentToken.borrowed.balance);

  const available = Big(currentToken.supplied.balance)
    .add(Big(currentToken.reserved))
    .sub(Big(currentToken.borrowed.balance))
    .toNumber();

  if (!available) return 0.0;
  let result = Big(available).div(Big(10).pow(tokeDecimals)).toNumber();
  return result ?? 0.0;
};

//

export const totalUsdCanBorrow = (
  listTypeBalance: {
    apr: string;
    balance: string;
    shares: string;
    token_id: string;
  }[],
  usdTokensState?: any,
  pool?: any,
  isNotVolatilityRatio?: boolean
): number => {
  const result = listTypeBalance?.reduce((acc: any, item: any) => {
    const tokenId: string = item.token_id || item.tokenId;
    const found = pool?.find((ele: any) => ele.tokenId === tokenId);

    if (!found) return "0.00";
    const balance: number = item.balance as unknown as number;
    const tokeDecimals = Big(tokenFormat[tokenId]?.extra_decimals)
      .add(Big(tokenFormat[tokenId]?.contract_decimals))
      .toNumber();
    // const tokeDecimals: number = ((tokenFormat[tokenId]
    //   ?.extra_decimals as unknown as number) +
    //   tokenFormat[tokenId]?.contract_decimals) as unknown as number;

    const tokenNameUsd: string = tokenFormat[tokenId]?.nameUsd ?? "";
    const { usd } = usdTokensState?.[tokenNameUsd] || { usd: 23 };

    if (!tokenNameUsd || !tokeDecimals) return acc;
    // const bal: number = (balance / 10 ** tokeDecimals) * usd * isRatio;
    const bal = Big(balance)
      .div(Big(10).pow(tokeDecimals))
      .mul(Big(usd))
      .mul(Big(get_volatility_ratio(pool, tokenId)));

    return Big(acc).add(bal).toNumber();
    // return (acc + bal) as unknown as number;
  }, 0);
  return result ?? 0.0;
};

export const totalMarket = (
  listToken: any,
  typeBalance: string,
  tokenFormat?: TTokenFormat
): number => {
  const result = listToken
    ?.filter((item_token) => item_token.token_id !== ENV_ID_TOKEN_NEL)
    ?.reduce((acc: any, item: any) => {
      if (!listToken || !tokenFormat) return 0;
      const tokenId: string = item.token_id || item.tokenId;
      const balance: number =
        typeBalance === "reserved"
          ? (item[typeBalance] as unknown as number)
          : (item[typeBalance].balance as unknown as number);
      const tokenDecimals = Big(tokenFormat[tokenId]?.extra_decimals)
        .add(Big(tokenFormat[tokenId]?.contract_decimals))
        .toNumber();
      const { usd } = tokenFormat?.[tokenId] || { usd: 0 };

      if (!tokenDecimals) return acc;

      let bal = Big(balance).div(Big(10).pow(tokenDecimals)).mul(usd);
      if (typeBalance === "reserved" && item.config.can_borrow === false) {
        bal = Big(0);
      }

      return Big(acc).add(bal).toNumber();
    }, 0);
  return result ?? 0.0;
};

export const getNumberExactly = (item: any, typeBalance: string) => {
  try {
    const tokenId: string = item.token_id || item.tokenId;
    const balance: number =
    typeBalance === "reserved"
      ? (item.reserved as unknown as number)
      : (
        typeBalance === "borrowed" ?
        item.borrowed.balance as unknown as number :
        item.supplied.balance as unknown as number
      );
    const tokenDecimals = Big(tokenFormat[tokenId]?.extra_decimals)
      .add(Big(tokenFormat[tokenId]?.contract_decimals))
      .toNumber();
    if (!tokenDecimals) return Big(0);
    return Big(balance).div(Big(10).pow(tokenDecimals));
  } catch(e) {
    return Big(0);
  }
}

export const totalDailyRewardFt = (
  listToken: any,
  tokenFormat?: TTokenFormat
) => {
  const result = listToken
    ?.filter((item_token) => item_token.token_id !== ENV_ID_TOKEN_NEL)
    ?.reduce((acc: any, item: any) => {
      if (!listToken || !tokenFormat) return 0;
      const tokenId: string = item.token_id || item.tokenId;
      if (!item.farms || item.farms.length <= 0 || !item.farms[0].rewards) {
        return 0;
      }
      let total = Big(0);
      for (let key in item.farms[0].rewards) {
        let tokenDecimals = Big(tokenFormat[key]?.extra_decimals)
          .add(Big(tokenFormat[key]?.contract_decimals))
          .toNumber();
  
        if (!tokenDecimals) return acc;
        let reward = Big(item.farms[0].rewards[key].reward_per_day).div(Big(10).pow(tokenDecimals));
        if (tokenId !== ENV_ID_TOKEN_USDT) {
          const { usd } = tokenFormat?.[key] || { usd: 0 };
          reward = reward.mul(usd);
        }
        total = total.add(reward);
      }
      
      return Big(acc).add(total).toNumber();
    }, 0);
  return result ?? 0.0;
}

export const intNumber = (value) => {
  let split = value.split('.');
  if (split.length < 2) {
    return value
  }
  return split[0];
}

export const totalDailyRewardNft = (
  listToken: any,
  tokenFormat?: TTokenFormat
) => {
  const result = listToken
    ?.filter((item_token) => item_token[0].SuppliedNFT == ENV_ID_NFT_LANGBIANG)
    ?.reduce((acc: any, item: any) => {
      if (item.length < 2 || !item[1].rewards) {
        return 0;
      }
      let total = Big(0);
      for (let key in item[1].rewards) {
        let tokenDecimals = Big(tokenFormat[key]?.extra_decimals)
          .add(Big(tokenFormat[key]?.contract_decimals))
          .toNumber();
        
        if (!tokenDecimals) return acc;
        const { usd } = tokenFormat?.[key] || { usd: 0 };
        let reward = Big(item[1].rewards[key].reward_per_day).div(Big(10).pow(tokenDecimals)).mul(usd);

        total = total.add(reward);
      }
      
      return Big(acc).add(total).toNumber();
    }, 0);
  return result ?? 0.0;
}

export const totalMarketNft = (
  list_pool_nft: any,
  tokenFormat?: TTokenFormat,
  isRatio?: boolean
): number => {
  const result = list_pool_nft?.reduce((acc: any, item: any) => {
    if (!list_pool_nft || !tokenFormat) return 0;
    const total_list = totalContractNft(
      list_pool_nft,
      item.token_id,
      tokenFormat,
      isRatio
    );
    return Number(acc) + Number(total_list);
  }, 0);

  return result ?? 0.0;
};

export const totalContractNft = (
  list_pool_nft: any,
  contract_id_nft: string,
  tokenFormat?: TTokenFormat,
  is_ratio = true
): number => {
  const found = list_pool_nft.find((item) => item.token_id === contract_id_nft);
  const ratio = is_ratio
    ? get_volatility_ratio(list_pool_nft, contract_id_nft)
    : 1;
  if (!tokenFormat) return;
  const result = found.nft_supplied.reduce((acc, curr) => {
    const total = Big(tokenFormat?.[contract_id_nft]?.usd).mul(Big(ratio));
    return Big(acc).add(Big(total)).toNumber();
  }, 0);

  return result ?? 0.0;
};

export const totalUserNftSupply = (
  userBalance: any,
  contract_id_nft: string,
  pool_market: any,
  tokenFormat?: TTokenFormat,
  is_ratio = true
): number => {
  // const found = list_nft.find((item) => item.token_id === contract_id_nft);
  const ratio = is_ratio
    ? get_volatility_ratio(pool_market, contract_id_nft)
    : 1;
  if (!tokenFormat) return;
  const result = userBalance.nft_supplied.reduce((acc, curr) => {
    const total = Big(tokenFormat?.[contract_id_nft]?.usd).mul(Big(ratio));
    return Big(acc).add(Big(total)).toNumber();
  }, 0);

  return result ?? 0.0;
};

export const formatBalanceWithDecimal = (
  balance: string,
  decimal: number
): any => {
  // const result = Big(Number(balance) || 0)
  //   .div(Big(10))
  //   .pow(decimal)
  //   .toNumber();
  const result: number = (balance as unknown as number) / 10 ** decimal;

  if (!result || (result as unknown as number) <= 1e-13) {
    return 0.0;
  }
  return result;
};

export const shortBalance = (balance: number): number => {
  if (!balance || balance < 1e-13) {
    return 0.0;
  }
  const bl = balance.toFixed(10).toString();
  const dotIndex = bl.indexOf(",");
  const integer = Big(Number(bl.slice(0, dotIndex)) || 0).toNumber();
  const real = Big(Number(bl.slice(dotIndex).slice(0, 4)) || 0).toNumber();
  const rs = integer + real;
  return Number(rs.toFixed(LAST_DECIMAL_REPLACE)) ?? 0.0;
};

// should debounce
export function formatSlider(value: any) {
  return `${value.toString()}%`;
}

export const getBorrowAndSupplyAPY = (token: IToken) => {
  const borrowAPY = Number(token?.borrow_apr || 0);
  const supplyAPY = Number(token?.supply_apr || 0);

  return {
    borrowAPY,
    supplyAPY,
  };
};

export const getBorrowMaxAmount = (
  collateral_sum: number,
  borrowed_sum: number,
  nft_to_usd: number,
  token_volatility_ratio: number,
  token_price_usd: number
) => {
  // MAX_RATIO = 100%
  // maxBorrowAmount = (collateral_sum - borrowed_sum) * volatility_ratio / MAX_RATIO / price * 95%
  const MAX_RATIO = Big(1);
  const MAX_PERCENT = Big(1);
  const maxBorrowAmount = Big(collateral_sum)
    .add(Big(nft_to_usd))
    .sub(Big(borrowed_sum))
    .mul(Big(token_volatility_ratio))
    .div(MAX_RATIO)
    .div(Big(token_price_usd))
    .mul(MAX_PERCENT)
    .toNumber();

  // const maxBorrowAmount =
  //   (((collateral_sum + nft_to_usd - borrowed_sum) * token_volatility_ratio) /
  //     MAX_RATIO /
  //     token_price_usd) *
  //   MAX_PERCENT;
  return Number(maxBorrowAmount.toFixed(LAST_DECIMAL_REPLACE));
};

export const getTokenBalance = (
  userBalanceState: any,
  currentToken: any,
  tokenDecimals: number
) => {
  const { token_id } = currentToken;
  const token_amount_r = userBalanceState?.supplied.find(
    (ele: any) => ele.token_id === token_id
  )?.balance;
  const token_blance =
    Big(token_amount_r).div(Big(10).pow(tokenDecimals)).toNumber() || 0;
  return token_blance;
};

export const getWithdrawMaxAmountNew = (
  collateral_sum: number,
  borrowed_sum: number,
  nft_to_usd: number,
  token_collateral_sum: number,
  token_amount: number,
  token_volatility_ratio: number,
  token_price_usd: number
) => {
  const MIN_PERCENT = 1.01; // 101%
  let max_withdraw_amount =
    token_amount -
    (MIN_PERCENT * borrowed_sum -
      (collateral_sum + nft_to_usd - token_collateral_sum)) /
      (token_price_usd * token_volatility_ratio);
  return max_withdraw_amount;
};

export const getWithdrawMaxAmount = (
  userBalanceState: any,
  currentToken: any,
  tokenDecimals: number
) => {
  const { token_id } = currentToken;
  const supply_balance = userBalanceState?.supplied.find(
    (ele: any) => ele.token_id === token_id
  )?.balance;
  const suppliedBalance =
    Big(supply_balance).div(Big(10).pow(tokenDecimals)).toNumber() || 0;

  return suppliedBalance;
};

export const percentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  maximumFractionDigits: 3,
});

export const removeAfterPoint = (big_balance: string) => {
  let balance = big_balance.split('.');
  return balance[0];
}

export const generateZeroDecimal = (decimal) => {
  let str = '';
  for (let i = 0; i < decimal; i++) {
    str += '0';
  }
  return str;
}

export const handleLastDecimalReplaceByZero = (
  big_balance: string,
  tokenDecimals: number,
  number_replace = LAST_DECIMAL_REPLACE
) => {
  const number_side = big_balance.toString().slice(0, -tokenDecimals);
  const decimal_side = big_balance
    .toString()
    .slice(number_side.length)
    .split("")
    ?.map((item, index) => {
      const e = "0";

      if (index > number_replace) {
        item = e;
      }
      return item;
    })
    .join("")
    .toString()
    .replace(".", "")
    .replace(",", "");
  return number_side.concat(decimal_side);
};

export const floorNumber = (
  number: number,
  tokenId: string
) => {
  let decimal = 3;
  switch (tokenId) {
    case ENV_ID_TOKEN_ETH:
    case ENV_ID_TOKEN_wETH:
      decimal = 4;
      break;
  }
  const powDec = Math.pow(10, decimal);
  return Math.floor(number * powDec) / powDec;
}

export const countMinimumDollarOfTokenList = (
  type: "borrowed" | "collateral",
  arrayOject: {
    apy: string;
    balance: string;
    shares: string;
    token_id: string;
  }[],
  usdTokens?: TTokenFormat,
  pool?: any
): number => {
  if (!usdTokens) return 0.0;
  let result = arrayOject?.reduce((acc: number, curr) => {
    const isRatio = get_volatility_ratio(pool, curr.token_id);
    
    if (!usdTokens?.[curr.token_id]) {
      return acc;
    }
    
    const tokeDecimals =
      usdTokens?.[curr.token_id]?.contract_decimals +
      usdTokens?.[curr.token_id]?.extra_decimals;

    const dollarPriceOfToken = usdTokens?.[curr.token_id]?.usd ?? 1;

    let bal = 0;

    let balance = new Big(curr.balance)
      .div(new Big(10).pow(tokeDecimals))
      .mul(new Big(dollarPriceOfToken));
    type === "borrowed"
      ? (bal = balance.div(new Big(isRatio)).toNumber())
      : (bal = balance.mul(new Big(isRatio)).toNumber());

    if (bal < MINIMUM_DOLLAR_IS_ALLOW) {
      acc += 1;
    }
    return acc;
  }, 0);

  return result ?? 0;
};

export const formatCash = (n: number, decimals = FORMAT_CASH_DECIMAL) => {
  if (!n || n <= 0) return "0.0";

  if (n < 1e3 || n < 1e6) {
    return n?.toFixed(decimals).slice(0, -6);
  }

  if (n < 1e3) {
    return new Intl.NumberFormat("en-IN", {
      maximumSignificantDigits: 6,
    })
      .format(n)
      .slice(0, 3);
  }
  if (n <= 1e6) return n?.toFixed(decimals).slice(0, -3);
  if (n >= 1e6 && n < 1e9) {
    return +n.toFixed(decimals).toString().slice(0, -6);
  }
  if (n >= 1e9 && n < 1e12) {
    return +(n / 1e9).toFixed(decimals).slice(0, -6) + " B";
  }
  if (n >= 1e12) {
    return +(n / 1e12).toFixed(decimals).toString().slice(0, -6) + " T";
  }
};

export const formatCashToView = (value: number, decimal = 11, fixDecimal = false) => {
  if (!value) return "0.0";
  const numberCheck = Number(formatCash(value, 13));

  const check_min_number = numberCheck < 0.0000001;

  if (check_min_number) return "0.0";

  //format for Thousand number with comma
  if (numberCheck <= 1e6 && numberCheck > 1e3) {
    const result = formatCash(value, decimal);
    const dotIndex = result.toString().indexOf(".") - 3;

    const split_result_1 =
      result.toString().slice(0, dotIndex).toString() + ",";
    const split_result_2 = result.toString().slice(dotIndex);
    const final_result = split_result_1.concat(split_result_2);

    return final_result;
  }
  //format for Million number with comma
  if (numberCheck > 1e3 && numberCheck <= 1e9) {
    const dot_index = value.toString().indexOf(".");
    const get_number = dot_index > 0 ? value.toString().slice(0, dot_index) : value.toString();

    if (fixDecimal) {
      let dot = value.toString().split(get_number.toString());
      let final = dot[1].length < 3 ? dot[1] : value.toString().slice(dot_index, dot_index + 3);
      if  (dot.length > 1) {
        return get_number.replace(/(.)(?=(\d{3})+$)/g, "$1,") + final;
      }
    }
    return get_number.replace(/(.)(?=(\d{3})+$)/g, "$1,");
  }

  return formatCash(value, decimal) || "0.0";
};

export const trimZero = (value) => {
  const split = value.toString().split('.');
  if (split.length < 2) {
    return value;
  }
  const after = parseInt(split[1]);
  if (after == 0) {
    return split[0];
  }
  let str = ''
  let idx = split[1].length - 1
  for(let i = idx; i>= 0; i--) {
    if (split[1][i] != '0') {
      idx = i
      break
    }
  }
  for (let i = 0; i <= idx; i++) {
    str += split[1][i]
  }
  return `${split[0]}.${str}`
}

export const strimInt = (value: string) => {
  let split = value.split('.');
  if (split.length > 0 && parseInt(split[1]) == 0) {
    return split[0];
  }
  return value;
}

export const checkNegativeNumber = (a: number, b: number, delta = 0.001) => {
  const result = a - b;
  return Math.abs(result) > delta ? result : 0;
};

export const formatAccountName = (accountName: string) => {
  if (accountName?.length < 15) return accountName;
  const first = accountName.slice(0, 4).toString();
  const last = accountName.slice(-4).toString();
  const combine = `${first}...${last}`;
  return combine;
};

export const errorPipeline =
  (...validatedFns: any[]) =>
  (value) => {
    return validatedFns.reduce((acc, fn) => {
      if (acc) return acc;
      return fn(value);
    }, "");
  };

export const validatePopup = (action_type, amountToken, available) => {
  const _errorHandle = errorPipeline(() => {
    if (amountToken === null) {
      return ERROR.AMOUNT_TOKEN_INVALID;
    }
    if (amountToken > available) {
      return errorAvailable(action_type);
    }
    if (amountToken < 0) {
      return ERROR.ACTION_INVALID;
    }
    return "";
  });
  return _errorHandle;
};

export const langbiangLabelSplitting = (label: string) => {
  if (!label.length) {
    return {
      tokenLabel: "",
      tokenId: "",
    };
  }
  return {
    tokenLabel: label.slice(0, 10),
    tokenId: label.slice(10, -2).replace(/-/g, "#").replace(/ /g, ""),
  };
};

export const formatNearAmount = (amount: string) => {
  if (amount !== 'null') {
    return utils?.format?.formatNearAmount(amount);
  }
  return '0';
};

export const injectHtmlToWallet = () => {
  setTimeout(() => {
    const getWalletModalId = document.getElementById('near-wallet-selector-modal');
    if (getWalletModalId) {
      const parent = document.getElementsByClassName('wallet-info-wrapper');
      const getWindownSize = window.innerWidth;

      if (parent && getWindownSize > 576) {
        const elInsert = document.createElement('div');
        elInsert.classList.add('wallet-what', 'custom-wallet-what');
        const walletWhat = parent[0].childNodes;
        for (let i = 0; i < walletWhat.length; i++) {
          if (i === walletWhat.length - 3) {
            const element = walletWhat[i];
            if (element) {
              insertAfter(elInsert, element);
            }
          }
        }
      } else {
        const mobileOnly = document.getElementsByClassName('wallet-home-wrapper');
        if (mobileOnly && mobileOnly[0]) {
          const node = document.createElement('div');

          mobileOnly[0].appendChild(node);
        }
      }
    }
  }, 200);
};
function insertAfter(newNode: any, existingNode: any) {
  existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
}