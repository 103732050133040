import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import useFunctionContract from 'hooks/useFunctionContract';
import useCurrentToken from "hooks/useCurrentToken";
import { ACTION, QUERY_KEY } from "utils/constant";
import { TTokenFormat } from "types/token";
import ActionPopupView from "components/popup/component/ViewActionPopup";
import { AppContext } from "Contexts/AppContext";
import {
  errorPipeline,
  getTotalBalanceNftPriceUSD,
  getTotalBalanceTokenPriceUSD,
  validatePopup,
  floorNumber
} from "utils/common";
import useError from "hooks/useError";

type Props = {
  togglePopup: () => void;
  currentToken: any;
};
const Repay = ({ togglePopup, currentToken }: Props) => {
  const { handleGetUserTokenBalance, handleRepay }= useFunctionContract();
  const initInterval = useRef<any>(null);
  const queryClient = useQueryClient();
  const {
    tokenName,
    tokenContractDecimals,
    tokenExtraDecimals,
    tokenId,
    tokenIcon,
    tokenSymbol,
    tokenRatio,
    borrow_apr,
  } = useCurrentToken(currentToken);
  const { errorApproveTransaction } = useError();

  const { wallet, contract, profile, poolTokenList, poolNftList } =
    useContext(AppContext);

  const [amountToken, setAmountToken] = useState(0);
  const [available, setAvailable] = useState(0);
  const [tokenUsdPrice, setTokenUsdPrice] = useState(0);
  const [error, setError] = useState("");

  const [borrowedState, setBorrowedState] = useState(0);
  const [collateralState, setCollateralState] = useState(0);

  const _validateError = useCallback(() => {
    const _error = errorPipeline(
      validatePopup(ACTION.REPAY.toLowerCase(), amountToken, available)
    );
    setError(_error);
  }, [amountToken, available]);

  const _handleRepay = useCallback(async () => {
    _validateError();
    const errorApprove = await errorApproveTransaction();
    if (errorApprove) return setError(errorApprove);
    return handleRepay(currentToken, amountToken, contract);
  }, [
    _validateError,
    amountToken,
    contract,
    currentToken,
    errorApproveTransaction,
  ]);

  const _onChange = useCallback((e: any) => {
    setAmountToken(e);
  }, []);

  const _onChangeSlider = useCallback((e: any) => {
    setAmountToken(e);
  }, []);

  const _calculate = useCallback(async () => {
    const getNewFormatToken = queryClient.getQueryData(
      QUERY_KEY.GET_FORMAT_TOKEN
    ) as unknown as TTokenFormat;
    if (!getNewFormatToken) return;

    const usd: number | undefined = getNewFormatToken?.[tokenId]?.usd;

    const currentTokenBorrow: number =
      profile?.userBalance?.borrowed?.find(
        (item: any) => item.token_id === tokenId
      )?.balance /
        10 ** (tokenContractDecimals + tokenExtraDecimals) ?? 0;

    const balance = await handleGetUserTokenBalance(
      contract,
      tokenId,
      tokenContractDecimals
    );

    let available = floorNumber(Math.min(currentTokenBorrow, balance), tokenId);

    setTokenUsdPrice(usd);
    setAvailable(available);

    const collateral_to_usd = getTotalBalanceTokenPriceUSD(
      "collateral",
      profile?.userBalance?.supplied,
      getNewFormatToken,
      poolTokenList
    );

    const borrow_to_usd = getTotalBalanceTokenPriceUSD(
      "borrowed",
      profile?.userBalance?.borrowed,
      getNewFormatToken,
      poolTokenList
    );

    const nft_to_usd = getTotalBalanceNftPriceUSD(
      profile?.userBalance?.nft_supplied,
      getNewFormatToken,
      poolNftList
    );

    setBorrowedState(borrow_to_usd);
    setCollateralState(collateral_to_usd + nft_to_usd);
  }, [
    contract,
    poolNftList,
    poolTokenList,
    profile?.userBalance?.borrowed,
    profile?.userBalance?.nft_supplied,
    profile?.userBalance?.supplied,
    queryClient,
    tokenContractDecimals,
    tokenExtraDecimals,
    tokenId,
    wallet,
  ]);

  useEffect(() => {
    initInterval.current = setInterval(_calculate, 600);
    return () => {
      clearInterval(initInterval.current);
    };
  }, [_calculate]);

  useEffect(() => {
    _validateError();
  }, [_validateError]);

  return (
    <ActionPopupView
      textTitle={ACTION.REPAY}
      togglePopup={togglePopup}
      onChange={_onChange}
      onChangeSlider={_onChangeSlider}
      confirmPopUp={_handleRepay}
      valAPY={borrow_apr}
      borrowed={borrowedState}
      collateral={collateralState}
      error={error}
      currentToken={{
        available,
        tokenName,
        tokenSymbol,
        tokenUsdPrice,
        tokenIcon,
      }}
      tokenRatio={tokenRatio}
    />
  );
};

export default Repay;
