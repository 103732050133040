import React, { useCallback, useContext, useEffect, useState } from "react";
import useFunctionContract from 'hooks/useFunctionContract';
import AssetNftItem from "components/asset/AssetNftItem";
import { AppContext } from "Contexts/AppContext";

function AssetNftList() {
  const { handleGetUserNFT } = useFunctionContract();
  const [nftsData, setNftsData] = useState([]);
  const { contract, wallet } = useContext(AppContext);

  const _handleGetUserAssetNfts = useCallback(async () => {
    try {
      const nfts = await handleGetUserNFT(contract);
      setNftsData(nfts);
    } catch (err) {
      console.error(err);
    }
  }, [contract, wallet]);

  useEffect(() => {
    _handleGetUserAssetNfts();
  }, [_handleGetUserAssetNfts]);

  return (
    <>
      {nftsData && nftsData.length > 0 ? (
        nftsData.map((item, idx: number) => {
          if (item.length <= 0) return null;
          return <AssetNftItem key={item.token_id} item={item} />;
        })
      ) : (
        <tr className="empty-line">
          <td colSpan={2}>No Data</td>
        </tr>
      )}
    </>
  );
}

export default AssetNftList;
