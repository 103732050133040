import { useLocation } from "react-router-dom";
import { ChildrenProps } from "utils/interfaces";
import { exceptHeaderPath, pathCollection } from "utils/constant";
import { FlagDebuggerProvider } from "components/debug";
import Header from "components/header/header";
import Footer from "components/footer";

export default function Main({ children }: ChildrenProps) {
  const { pathname } = useLocation();
  return (
    <FlagDebuggerProvider>
      <section
        className="App"
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        {!exceptHeaderPath.includes(pathname) && <Header />}
        {children}
        {!pathCollection.includes(pathname) && !pathCollection.includes(`${pathname}/`) && <Footer />}
      </section>
    </FlagDebuggerProvider>
  );
}
