import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Caption,
  JoinBtn,
  LauchBtn,
  Position,
  TagNft,
  WrapperBtn,
} from "./styles";

import nearBanner from "images/new_logo/banner.svg";
import styled from "styled-components";
import "./style/banner.scss";

import { BuildOn } from "components/common";
import { Container } from "components/common/Container";
import Image from "components/common/Images";
import Lines from "components/common/Lines";
import { DebuggerContext } from "components/debug/FlagDebugger";

import gridBanner from "images/banner/grid-banner.svg";
import BIT from "images/3DCoin/BIT.svg";
import NEAR from "images/3DCoin/NEAR.svg";

const BtnWrapp = styled.div``;

const exceptNavigatePath = ["localhost", "testnet"];

function BannerUI() {
  const { isUrlDebugger, queryString } = useContext(DebuggerContext);

  const history = useNavigate();

  const handleLauchBtn = useCallback(() => {
    if (
      !exceptNavigatePath.includes("testnet") ||
      !exceptNavigatePath.includes("localhost")
    ) {
      window.location.replace("https://www.testnet.nearlenddao.com/app");
      return;
    }
    history(`/app${queryString}`);
  }, [history, queryString]);

  return (
    <BannerUIWrapper>
      <Container style={{ position: "relative" }}>
        <WrapperBtn
          style={{
            padding: "40px 0",
            justifyContent: "space-between",
            gap: 5,
          }}
          className="mobile-responsive-banner-top"
        >
          <div data-aos="fade-right" className="mobile-responsive-hero-banner">
            <BuildOn />
            <Caption className="mobile-responsive">
              <p>native near</p>
              <p className="open-source">open-source</p>
              <p>capital market</p>
            </Caption>
            <WrapperBtn className="mobile-responsive">
              <BtnWrapp
                style={{ opacity: !isUrlDebugger ? ".5" : "1" }}
                onClick={isUrlDebugger ? () => handleLauchBtn() : null}
              >
                <LauchBtn>
                  <span>launch app</span>
                </LauchBtn>
              </BtnWrapp>
              <a
                href="https://discord.com/invite/Nvny8hUjwG"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", marginLeft: 25 }}
              >
                <JoinBtn>
                  <span>join discord</span>
                </JoinBtn>
              </a>
            </WrapperBtn>
          </div>
          <div data-aos="fade-left" data-aos-duration="1000">
            <RightSideWrapper
              style={{
                position: "relative",
                maxWidth: "500px",
                width: "100%",
                padding: 10,
                zIndex: 2,
                margin: "0 auto",
              }}
              className="mobile-right-banner-only"
            >
              <Image src={nearBanner} zindex="2">
                <Position style={{ bottom: "35%", left: "-10%" }} className="line-wrapper">
                  <Lines height={35} />
                  <TagsWrapper>NFT Fi</TagsWrapper>
                </Position>
                <Position style={{ right: "-10%", bottom: "57%" }} className="line">
                  <Lines height={35} />
                  <TagsWrapper>Defi</TagsWrapper>
                </Position>
              </Image>
            </RightSideWrapper>
          </div>
        </WrapperBtn>
        <BitCoinBasePosition data-aos="fade-right">
          <BCoinBase />
        </BitCoinBasePosition>
        <NearBasePosition data-aos="fade-left">
          <NCoinBase />
        </NearBasePosition>
      </Container>
    </BannerUIWrapper>
  );
}

export default BannerUI;

const NearBasePosition = styled.div`
  position: absolute;
  bottom: -7%;
  z-index: 2;
`;
const BitCoinBasePosition = styled.div`
  position: absolute;
  bottom: -20%;
  z-index: 2;
  right: -10%;
`;

const NCoinBase = styled.div`
  background-image: url(${NEAR});
  background-repeat: no-repeat;
  background-size: contain;
  width: 70px;
  height: 70px;

  @media screen and (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
`;
const BCoinBase = styled.div`
  background-image: url(${BIT});
  background-repeat: no-repeat;
  background-size: contain;
  width: 100px;
  height: 100px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #54a943 0%,
      rgba(106, 169, 67, 0) 100%
    );
    width: 200px;
    height: 200px;
    opacity: 0.2;
    z-index: 1;
    margin: auto;
    top: -43px;
    right: -15px;
  }
`;

const BannerUIWrapper = styled.div`
  /*background: radial-gradient(
      34.24% 60.88% at 0% 0%,
      rgba(43, 255, 0, 0.1) 0%,
      rgba(73, 228, 84, 0.01) 100%
    ),
    linear-gradient(360deg, #010707 0%, rgba(1, 7, 7, 0) 61.98%), #030e01;*/
  /* height: calc(100vh - 75px); */
  width: 100%;
  padding-top: 100px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    // background-image: url(${gridBanner});
    // background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const TagsWrapper = styled(TagNft)`
  position: relative;
  z-index: 2;
`;

const RightSideWrapper = styled.div``;
