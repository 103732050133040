import { Card, Col, Row } from "antd";
import styled from "styled-components";
import "./style/solution.scss";

import { Container } from "components/common/Container";
import Image from "components/common/Images";
import {
  MiddleSpace,
  RelativeWrapper,
  WrapperBtn,
} from "components/homepage/styles";

import daoIco from "images/solutions/dao.png";
import flexibleIco from "images/solutions/flexible.png";
import lendingIco from "images/solutions/lending.png";
import tripleIco from "images/solutions/triple.png";

function SolutionUI() {
  return (
    <SolutionWrapper className="mobile-solution-only-wrapper">
      <Container>
        <MiddleSpace style={{ height: 100 }} />
      </Container>
      <RelativeWrapper>
        <Container className="mobile-solution-only">
          <Row
            style={{ alignItems: "stretch", rowGap: 10 }}
            gutter={16}
            data-aos="fade-left"
          >
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Card
                className="card-wrapper"
                style={{ height: "100%", zIndex: 2 }}
                title={
                  <CardTitle>
                    <WrapperBtn
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Image src={lendingIco} style={{ width: 85 }} />
                      <span className="title">Hybrid Lending</span>
                    </WrapperBtn>
                  </CardTitle>
                }
                bordered={false}
              >
                <span className="des">
                  Beside major cryptocurrency as ETH, BTC, NEAR,{" "}
                  <span className="hightLight">
                    NFTs are supported as collaterals{" "}
                  </span>
                  for borrowing others cryptocurrency & token.
                </span>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Card
                className="card-wrapper"
                style={{ height: "100%", zIndex: 2 }}
                title={
                  <CardTitle>
                    <WrapperBtn
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Image src={flexibleIco} style={{ width: 85 }} />
                      <span className="title">Variable APY</span>
                    </WrapperBtn>
                  </CardTitle>
                }
                bordered={false}
              >
                <span className="des">
                  Nearlend appies{" "}
                  <span className="hightLight">variable APY</span>, to meet the
                  need of user in the short & long term.
                </span>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Card
                className="card-wrapper"
                style={{ height: "100%", zIndex: 2 }}
                title={
                  <CardTitle>
                    <WrapperBtn
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Image src={tripleIco} style={{ width: 85 }} />
                      <span className="title">Triple Liquidation</span>
                    </WrapperBtn>
                  </CardTitle>
                }
                bordered={false}
              >
                <span className="des">
                  Mechanism ensures{" "}
                  <span className="hightLight">high safety </span>for liquidity
                  pool and{" "}
                  <span className="hightLight">attracts users/collectors </span>
                  to participate in the asset liquidation process.
                </span>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Card
                className="card-wrapper"
                style={{ height: "100%", zIndex: 2 }}
                title={
                  <CardTitle>
                    <WrapperBtn
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Image src={daoIco} style={{ width: 85 }} />
                      <span className="title">DAO Governance</span>
                    </WrapperBtn>
                  </CardTitle>
                }
                bordered={false}
              >
                <span className="des">
                  <span className="hightLight">Nearlend </span>over time, will
                  transition to <span className="hightLight">DAO</span>. DAO's
                  members will vote on high-level decisions of the protocol.
                </span>
              </Card>
            </Col>
          </Row>
        </Container>
      </RelativeWrapper>
    </SolutionWrapper>
  );
}

export default SolutionUI;

const SolutionWrapper = styled(RelativeWrapper)`
  padding-bottom: 80px;
  position: relative;
`;

const CardTitle = styled.span`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 22px;
  color: white;
  .title {
    font-weight: 600;
    font-size: 18px;
  }
`;